import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import TableHead from "../../component/table-head";

export default function CenterConsultations() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [isShowingViewAlert, setShowingViewAlert] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [problemImages, setProblemImages] = useState([]);
  // const [selectedImage, setProblemImages] = useState([])

  const modules = {
    toolbar: [],
  };

  const formats = [[]];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patients Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Doctors Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => convertFromISODate(row.cons_date),
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => row.start_time,
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => row.end_time,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.cons_app_status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Pending</span>{" "}
          </h5>
        ) : row.cons_app_status == 2 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-danger ">Resheduled</span>{" "}
          </h5>
        ) : row.cons_app_status == 3 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-success ">Confirmed</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Completed</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "150px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            disabled={row.cons_app_status !== 3 ? true : false}
            onClick={() => {
              startLiveConsultation(row.cons_pid, row.pat_pid);
            }}
          >
            <i className="fe-play"></i>
          </button>

          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-info btn-icon waves-effect waves-light"
            onClick={() => {
              viewConsultation(row);
            }}
          >
            <i className="fe-eye"></i>
          </button>
        </div>
      ),
    },
  ];

  const startLiveConsultation = (cons_pid, pat_pid) => {
    if (isAndroid()) {
      // Handle action for Android browser
      console.log("starting android app");
      window.location.href = `docroa://open/main?cons_pid=${cons_pid}&org_pid=${
        getUser()["org_pid"]
      }&user_pid=${getUser()["user_pid"]}&user_role=${
        getUser()["user_role"]
      }&pat_pid=${pat_pid}&user=patient`;
    } else if (isDesktop()) {
      // Handle action for desktop browser
      console.log("starting windows live app");
      window.open(
        `${process.env.REACT_APP_LIVE}?cons_pid=${cons_pid}&org_pid=${
          getUser()["org_pid"]
        }&user_pid=${getUser()["user_pid"]}&user_role=${
          getUser()["user_role"]
        }&pat_pid=${pat_pid}&user=patient`
      );
    } else {
      // Handle other platforms (iOS, etc.)
      window.open(
        `${process.env.REACT_APP_LIVE}?cons_pid=${cons_pid}&org_pid=${
          getUser()["org_pid"]
        }&user_pid=${getUser()["user_pid"]}&user_role=${
          getUser()["user_role"]
        }&pat_pid=${pat_pid}&user=patient`
      );
    }
  };

  const performTasks = async () => {
    await fetchAllConsultations(1);
  };
  const fetchAllConsultations = async (page, searchterm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }cons/all_cons_center/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllConsultations(page);
  };
  const buildImagesList = (data) => {
    let imagesList = [];
    if (!data.problem_image) {
      setProblemImages([]);
      return;
    }
    for (let index = 0; index < data.problem_image.length; index++) {
      const element = data.problem_image[index];
      imagesList.push({ mainUrl: element });
    }
    setProblemImages(imagesList);
    console.log(data);
    console.log(problemImages);
  };

  useEffect(() => {
    changeTitle("Consultations | DOC-ROA");
    performTasks();
  }, []);

  const viewConsultation = (row) => {
    setSelectedRow(row);
    setShowingViewAlert(true);
    buildImagesList(row);
  };

  const onClose = () => {
    setShowingViewAlert(false);
  };

  // Utility functions
  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };

  const isDesktop = () => {
    return (
      !/Android/i.test(navigator.userAgent) &&
      !/iPhone|iPad|iPod/i.test(navigator.userAgent)
    );
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Consultations</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${
                      process.env.REACT_APP_MAIN
                    }cons/all_cons_center/?org_pid=${getUser()["org_pid"]}`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllConsultations(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingViewAlert ? (
        <SweetAlert
          title="View Consultation!"
          style={{ minWidth: "700px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="dropdown float-end">
                <a
                  href="#"
                  className="dropdown-toggle arrow-none card-drop"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </a>
              </div>
              <div className="d-flex mb-3">
                <img
                  className="flex-shrink-0 me-3 rounded-circle avatar-md"
                  alt="64x64"
                  src="assets/images/favicon.png"
                />
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{selectedRow.pat_name}</h4>
                  {selectedRow.cons_app_status == 1 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-info ">Pending</span>{" "}
                    </h5>
                  ) : selectedRow.cons_app_status == 2 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-danger ">
                        Resheduled
                      </span>{" "}
                    </h5>
                  ) : selectedRow.cons_app_status == 3 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-success ">
                        Confirmed
                      </span>{" "}
                    </h5>
                  ) : (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-primary ">
                        Completed
                      </span>{" "}
                    </h5>
                  )}
                </div>
              </div>

              <h4>Patients Problem</h4>

              <div className="consDiv">
                <ReactQuill
                  toolbar={false}
                  theme="snow"
                  readOnly
                  modules={modules}
                  value={selectedRow.problem}
                />
              </div>

              <div className="row task-dates mb-0 mt-2">
                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">Consultation Date</h5>
                  <p>
                    {convertToHumanReadableDate(
                      convertFromISODate(selectedRow.cons_date)
                    )}
                  </p>
                </div>

                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">Start Time</h5>
                  <p className="text-muted">{selectedRow.start_time}</p>
                </div>
                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">End Time</h5>
                  <p className="text-muted">{selectedRow.end_time}</p>
                </div>
              </div>
              <div className="clearfix"></div>

              <div className="attached-files mt-3">
                <h5>Attached Files </h5>
                <ul className="list-inline files-list">
                  {selectedRow.problem_image == null
                    ? []
                    : selectedRow.problem_image.map((img, i) => (
                        <li className="list-inline-item file-box">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              new ImageViewer({
                                images: problemImages,
                                currentSelected: i,
                              });
                            }}
                          >
                            <img
                              src={img}
                              className="img-fluid img-thumbnail"
                              alt="attached-img"
                              width="80"
                            />
                          </div>
                          <p className="font-13 mb-1 text-muted">
                            <small>
                              {img.substring(img.lastIndexOf("/") + 1)}
                            </small>
                          </p>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
