import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import { useNavigate } from "react-router-dom";
import TableHead from "../../component/table-head";

export default function OrganizationManagePatients() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingBlockAlert, setShowingBlockAlert] = useState(false);
  const [isShowingUnBlockAlert, setShowingUnBlockAlert] = useState(false);
  const [updateNote, setUpdateNote] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [patientData, setPatientData] = useState({});

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "",

      selector: (row) => (
        <div>
          {row.photo === "" ? (
            <div
              className="d-flex"
              style={{
                margin: "5px",
                width: "50px",
                height: "50px",
                backgroundColor: "#e0e6ea",
                textAlign: "center",
                borderRadius: "60px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fe-user"
                style={{ fontSize: "30px", color: "grey" }}
              ></i>
            </div>
          ) : (
            <img
              src={row.photo}
              style={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
                padding: "5px",
                borderRadius: "60px",
              }}
            />
          )}
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",

      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Phone",

      selector: (row) => row.pat_phone,
      sortable: true,
    },
    {
      name: "Gender",

      selector: (row) => row.pat_gender,
      sortable: true,
    },
    {
      name: "Email",

      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.acc_status == 0 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-success ">Active</span>{" "}
          </h5>
        ) : row.acc_status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-danger ">Inactive</span>{" "}
          </h5>
        ) : row.acc_status == 2 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Blocked</span>{" "}
          </h5>
        ) : row.acc_status == 4 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-warning ">Expired</span>{" "}
          </h5>
        ) : row.acc_status == 5 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Signature Required</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-Danger ">Deleted</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "180px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-warning btn-icon waves-effect waves-light"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setSelectedRow(row);
              navigate("/organization-update-patient/?pat_pid=" + row.pat_pid);
            }}
          >
            <i className="fe-edit"></i>
          </button>
          {row.acc_status == 2 ? (
            <button
              type="button"
              className="btn btn-info btn-icon waves-effect waves-light"
              style={{ marginRight: "5px" }}
              onClick={() => {
                console.log(row);
                setSelectedRow(row);
                setShowingUnBlockAlert(true);
              }}
            >
              <i className="mdi mdi-account-key"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-dark btn-icon waves-effect waves-light"
              style={{ marginRight: "5px" }}
              onClick={() => {
                console.log(row);
                setSelectedRow(row);
                setShowingBlockAlert(true);
              }}
            >
              <i className="mdi mdi-account-lock-outline"></i>
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingDeleteAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllPatients(1);
  };
  const fetchAllPatients = async (page) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/all/`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setFilteredData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllPatients(page);
  };

  useEffect(() => {
    changeTitle("Manage Doctors| DOC-ROA");
    performTasks();
  }, []);

  const deletePatient = async () => {
    setLoading(true);
    let req = {
      pat_pid: selectedRow.pat_pid,
      org_pid: getUser()["org_pid"],
      del_type: 2,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}patients/delete/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingDeleteAlert(false);
      successToast("Patient Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingDeleteAlert(false);
    }
  };

  const blockPatient = async () => {
    setLoading(true);
    let req = {
      pat_pid: selectedRow.pat_pid,
      org_pid: getUser()["org_pid"],
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}patients/block/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingBlockAlert(false);
      successToast("Patient Blocked Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingBlockAlert(false);
    }
  };

  const unblockPatient = async () => {
    setLoading(true);
    let req = {
      pat_pid: selectedRow.pat_pid,
      org_pid: getUser()["org_pid"],
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}patients/unblock/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingUnBlockAlert(false);
      successToast("Patient UnBlocked Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingUnBlockAlert(false);
    }
  };

  const onClose = () => {
    // setShowingViewPatientAlert(false)
    setShowingUnBlockAlert(false);
    setShowingBlockAlert(false);
    setShowingDeleteAlert(false);
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Manage Patients</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={false}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    fields={["pat_name", "email", "pat_phone"]}
                    onSearch={(newList) => {
                      setFilteredData(newList);
                    }}
                  />
                  <DataTable
                    // title="Users"
                    columns={columns}
                    data={filteredData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}

                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                    // actions={actionsMemo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deletePatient}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingBlockAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, Block User!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={blockPatient}
          focusCancelBtn
        ></SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingUnBlockAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, UnBlock User!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={unblockPatient}
          focusCancelBtn
        ></SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
