import React, { useEffect, useRef, useState } from "react";
import {
  base64ToFile,
  changeTitle,
  convertFromISODate,
  errorToast,
  getUser,
  infoToast,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "react-loading-screen";
import TagInput from "react-taggables-input";
import Webcam from "react-webcam";
import Notification from "../../component/notification";
import { useLocation } from "react-router-dom";

export default function OrganizationUpdatePatient() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isCapturing, setIsCapturing] = useState(false);
  const [hasCaptured, setHasCaptured] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [pat_name, setpatientName] = useState("");
  const [email, setpatientEmail] = useState("");
  const [pat_phone, setpatientPhone] = useState("");
  const [pat_address, setpatientAddress] = useState("");
  const [pat_gender, setpatientGender] = useState("");
  const [pat_marital_status, setpatientMaritalStatus] = useState("");
  const [pat_state, setpatientState] = useState("");
  const [pat_pob, setpatientPOB] = useState("");
  const [pat_other_info, setpatientOtherInfo] = useState("");
  const [pat_bg, setpatientBG] = useState("");
  const [pat_occupation, setpatientOccupation] = useState("");
  const [pat_religion, setpatientReligion] = useState("");
  const [pat_dob, setpatientDOB] = useState("");
  const [pat_nok_name, setpatientNOKName] = useState("");
  const [pat_nok_phone, setpatientNOKPhone] = useState("");
  const [pat_nok_rel, setpatientNOKRel] = useState("");
  const [pat_nok_address, setpatientNOKAddress] = useState("");
  const [pat_family_history, setpatHistory] = useState([]);
  const [pat_allergies, setpatAllergies] = useState([]);
  const [pat_medical_condition, setpatMedConditions] = useState([]);
  const [centers, setCenters] = useState([]);
  const [centerPID, setCenterPID] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      setSelectedImageFile(reader.result);
      setCapturedImage(reader.result);
      setHasCaptured(true);
    };
  };

  const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user",
  };

  const getPatient = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const searchParams = new URLSearchParams(location.search);
      const patPID = searchParams.get("pat_pid");
      const data = {
        org_pid: getUser()["org_pid"],
        pat_pid: parseInt(patPID),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}patients/one/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);

      setHasCaptured(response.data.data.photo == "" ? false : true);
      setCapturedImage(response.data.data.photo);
      setpatientName(response.data.data.pat_name);
      setpatientEmail(response.data.data.email);
      setpatientPhone(response.data.data.pat_phone);
      setpatientAddress(response.data.data.pat_address);
      setpatientGender(response.data.data.pat_gender);
      setpatientMaritalStatus(response.data.data.pat_marital_status);
      setpatientState(response.data.data.pat_state);
      setpatientPOB(response.data.data.pat_pob);
      setpatientOtherInfo(response.data.data.pat_other_info);
      setpatientBG(response.data.data.pat_bg);
      setpatientOccupation(response.data.data.pat_occupation);
      setpatientReligion(response.data.data.pat_religion);
      setpatientDOB(response.data.data.pat_dob);
      setpatientNOKName(response.data.data.pat_nok_name);
      setpatientNOKPhone(response.data.data.pat_nok_phone);
      setpatientNOKRel(response.data.data.pat_nok_rel);
      setpatientNOKAddress(response.data.data.pat_nok_address);
      setpatHistory(response.data.data.pat_family_history);
      setpatAllergies(response.data.data.pat_allergies);
      setpatMedConditions(response.data.data.pat_medical_condition);
      setCenterPID(response.data.data.center_pid);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        errorToast("Error loading patient");
      }, 1000);
      setLoading(false);
    }
  };

  const fetchAllCenters = async (page) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}centers/all/`,
        {
          headers: headers,
        }
      );
      setCenters(response.data.data ?? []);
    } catch (error) {
      console.log(error);
    }
  };
  const updatePatient = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const searchParams = new URLSearchParams(location.search);
    const patPID = searchParams.get("pat_pid");
    const data = {
      org_pid: getUser()["org_pid"],
      pat_pid: parseInt(patPID),
      pat_name,
      email,
      pat_phone,
      pat_address,
      pat_gender,
      pat_bg,
      pat_pob,
      pat_marital_status,
      pat_occupation,
      pat_religion,
      pat_dob,
      pat_family_history,
      pat_allergies,
      pat_medical_condition,
      pat_other_info,
      pat_nok_name,
      pat_nok_phone,
      pat_nok_rel,
      pat_nok_address,
      pat_state,
      center_pid: parseInt(centerPID),
    };

    console.log(data);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}patients/update/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Patient Updated Successfully");
      setLoading(false);
      // if (capturedImage == null || capturedImage == "") {
      //   setLoading(false);
      //   return;
      // }
      // uploadPhoto(parseInt(patPID));
      // setLoading(false);
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  function onChangeHistory(tags) {
    setpatHistory(tags);
  }
  function onChangeAllergies(tags) {
    setpatAllergies(tags);
  }
  function onChangeMedConditions(tags) {
    setpatMedConditions(tags);
  }

  function validateTag(tag) {
    return true;
  }

  const resetForm = () => {
    setIsCapturing(false);
    setHasCaptured(false);
    setCapturedImage(null);
    setpatientName("");
    setpatientEmail("");
    setpatientPhone("");
    setpatientAddress("");
    setpatientGender("");
    setpatientMaritalStatus("");
    setpatientState("");
    setpatientPOB("");
    setpatientOtherInfo("");
    setpatientBG("");
    setpatientOccupation("");
    setpatientReligion("");
    setpatientDOB("");
    setpatientNOKName("");
    setpatientNOKPhone("");
    setpatientNOKRel("");
    setpatientNOKAddress("");
    setpatHistory([]);
    setpatAllergies([]);
    setpatMedConditions([]);
    setCenterPID(null);
  };

  const uploadPhoto = async (pat_pid) => {
    infoToast("Uploading Photo");

    const fileName = pat_pid.toString(); // Or 'image.png' for PNG format
    const imageFile = base64ToFile(selectedImageFile, fileName);

    const formData = new FormData();
    console.log(imageFile);
    formData.append("photo", imageFile);
    formData.append("pat_pid", pat_pid);
    formData.append("org_pid", getUser()["org_pid"]);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}patients/update_photo/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for multipart/form-data
            Authorization: "Bearer " + getUser()["token"],
          },
        }
      );
      successToast("Patient Updated & Photo Uploaded Successfully");
      setLoading(false);
      resetForm();
    } catch (error) {
      console.error("Failed to upload image:", error);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  const peformtask = async () => {
    await getPatient();
    fetchAllCenters();
  };

  useEffect(() => {
    changeTitle("Edit Patient | DOC-ROA");
    peformtask();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="/assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div class="card-body">
                  <div className="col-12 mb-4">
                    <button
                      type="button"
                      class="btn btn-light waves-effect waves-light"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <span class="btn-label">
                        <i class="fe-arrow-left"></i>
                      </span>
                      Go Back
                    </button>
                  </div>
                  <h4 class="header-title">Add Patient</h4>
                  <p class="text-muted font-13">
                    Add Patient to your organization
                  </p>

                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        <h5 className="mb-2 mt-4">User Photo</h5>
                        {isCapturing ? (
                          <Webcam
                            audio={false}
                            height={300}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                          >
                            {({ getScreenshot }) => (
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {/* <button
                                  type="button"
                                  class="btn btn-success waves-effect waves-light"
                                  onClick={() => {
                                    const imageSrc = getScreenshot();
                                    setHasCaptured(true);
                                    setIsCapturing(false);
                                    setCapturedImage(imageSrc);
                                    setSelectedImageFile(imageSrc);
                                  }}
                                >
                                  <span class="btn-label">
                                    <i class="fe-camera"></i>
                                  </span>
                                  Capture photo
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-danger waves-effect waves-light"
                                  onClick={() => {
                                    setIsCapturing(false);
                                  }}
                                >
                                  <i class="mdi mdi-close"></i>
                                </button> */}
                              </div>
                            )}
                          </Webcam>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#dedfdf",
                              width: "100%",
                              height: "300px",
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {hasCaptured && capturedImage != "" ? (
                              <img
                                src={capturedImage}
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              <i
                                class="mdi mdi-face-recognition"
                                style={{
                                  textAlign: "center",
                                  fontSize: "150px",
                                  color: "white",
                                }}
                              ></i>
                            )}
                          </div>
                        )}
                        {/* {!isCapturing ? (
                          <div
                            className="d-flex mt-2"
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <button
                              type="file"
                              class="btn btn-info waves-effect waves-light btn-xs"
                              onClick={handleButtonClick}
                            >
                              Upload Photo
                            </button>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileSelect}
                            />
                            <p>or</p>
                            <button
                              type="button"
                              class={`btn btn-${
                                isCapturing ? "danger" : "dark"
                              } waves-effect waves-light btn-xs`}
                              onClick={() => {
                                setIsCapturing(isCapturing ? false : true);
                              }}
                            >
                              {isCapturing ? "Stop Capture" : "Camera Capture"}
                            </button>
                          </div>
                        ) : (
                          <div></div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="mt-4">
                        <div className="row">
                          <div className="col-md-12 card card-body bg-primary">
                            <label for="inputState" class="form-label">
                              Assign Clinic
                            </label>
                            <select
                              id="inputState"
                              class="form-select"
                              value={centerPID}
                              onChange={(e) => {
                                setCenterPID(e.target.value);
                              }}
                            >
                              <option value="">Select Center</option>
                              {centers.map((center) => (
                                <option value={center.center_pid}>
                                  {center.center_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mb-3">
                            <label for="name" class="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Patients Name"
                              value={pat_name}
                              onChange={(e) => {
                                setpatientName(e.target.value);
                              }}
                            />
                          </div>
                          <div class="col-md-4 mb-3">
                            <label for="pass" class="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="pass"
                              placeholder="Enter Patients Email"
                              value={email}
                              onChange={(e) => {
                                setpatientEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3 col-md-3">
                            <label for="phoneno" class="form-label">
                              Phone
                            </label>
                            <input
                              type="phone"
                              class="form-control"
                              value={pat_phone}
                              id="phoneno"
                              onChange={(e) => {
                                setpatientPhone(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-md-4">
                            <label for="gender" class="form-label">
                              Marital Status
                            </label>
                            <select
                              id="gender"
                              class="form-select"
                              value={pat_marital_status}
                              onChange={(e) => {
                                setpatientMaritalStatus(e.target.value);
                              }}
                            >
                              <option value="">Select Marital Status</option>
                              <option value="Male">Single</option>
                              <option value="Female">Married</option>
                            </select>
                          </div>
                          <div class="mb-3 col-md-4">
                            <label for="gender" class="form-label">
                              Gender
                            </label>
                            <select
                              id="gender"
                              class="form-select"
                              value={pat_gender}
                              onChange={(e) => {
                                setpatientGender(e.target.value);
                              }}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                          <div class="mb-3 col-md-4">
                            <label for="inputState" class="form-label">
                              State
                            </label>
                            <select
                              id="inputState"
                              class="form-select"
                              value={pat_state}
                              onChange={(e) => {
                                setpatientState(e.target.value);
                              }}
                            >
                              <option value="">Select State</option>
                              <option value="Abia">Abia</option>
                              <option value="Adamawa">Adamawa</option>
                              <option value="Akwa Ibom">Akwa Ibom</option>
                              <option value="Anambra">Anambra</option>
                              <option value="Bauchi">Bauchi</option>
                              <option value="Bayelsa">Bayelsa</option>
                              <option value="Benue">Benue</option>
                              <option value="Borno">Borno</option>
                              <option value="Cross River">Cross River</option>
                              <option value="Delta">Delta</option>
                              <option value="Ebonyi">Ebonyi</option>
                              <option value="Edo">Edo</option>
                              <option value="Ekiti">Ekiti</option>
                              <option value="Enugu">Enugu</option>
                              <option value="FCT">FCT</option>
                              <option value="Gombe">Gombe</option>
                              <option value="Imo">Imo</option>
                              <option value="Jigawa">Jigawa</option>
                              <option value="Kaduna">Kaduna</option>
                              <option value="Kano">Kano</option>
                              <option value="Katsina">Katsina</option>
                              <option value="Kebbi">Kebbi</option>
                              <option value="Kogi">Kogi</option>
                              <option value="Kwara">Kwara</option>
                              <option value="Lagos">Lagos</option>
                              <option value="Nasarawa">Nasarawa</option>
                              <option value="Niger">Niger</option>
                              <option value="Ogun">Ogun</option>
                              <option value="Ondo">Ondo</option>
                              <option value="Osun">Osun</option>
                              <option value="Oyo">Oyo</option>
                              <option value="Plateau">Plateau</option>
                              <option value="Rivers">Rivers</option>
                              <option value="Sokoto">Sokoto</option>
                              <option value="Taraba">Taraba</option>
                              <option value="Yobe">Yobe</option>
                              <option value="Zamfara">Zamfara</option>
                            </select>
                          </div>
                          <div class="mb-3 col-md-8">
                            <label for="inputAddress" class="form-label">
                              Current Address
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputAddress"
                              placeholder="1234 Main St"
                              value={pat_address}
                              onChange={(e) => {
                                setpatientAddress(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3 col-md-4">
                            <label for="dob" class="form-label">
                              Date of Birth
                            </label>
                            <input
                              class="form-control"
                              id="dob"
                              type="date"
                              name="date"
                              value={pat_dob}
                              onChange={(e) => {
                                setpatientDOB(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div class="mb-3 col-md-6">
                            <label for="pob" class="form-label">
                              Place of Birth
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="pob"
                              placeholder="place of birth"
                              value={pat_pob}
                              onChange={(e) => {
                                setpatientPOB(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label for="other" class="form-label">
                              Other Info
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="other"
                              placeholder="Enter other information"
                              value={pat_other_info}
                              onChange={(e) => {
                                setpatientOtherInfo(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3 col-md-3">
                            <label for="bloodgroup" class="form-label">
                              Blood Group
                            </label>
                            <select
                              id="bloodgroup"
                              class="form-select"
                              value={pat_bg}
                              onChange={(e) => {
                                setpatientBG(e.target.value);
                              }}
                            >
                              <option value="">Blood Group</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="AB+">AB+</option>
                              <option value="AB-">AB-</option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                            </select>
                          </div>
                          <div class="mb-3 col-md-5">
                            <label for="occupation" class="form-label">
                              Occupation
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="occupation"
                              placeholder="Enter Occupation"
                              value={pat_occupation}
                              onChange={(e) => {
                                setpatientOccupation(e.target.value);
                              }}
                            />
                          </div>
                          <div class="mb-3 col-md-4">
                            <label for="bloodgroup" class="form-label">
                              Religion
                            </label>
                            <select
                              id="bloodgroup"
                              class="form-select"
                              value={pat_religion}
                              onChange={(e) => {
                                setpatientReligion(e.target.value);
                              }}
                            >
                              <option value="">Select Religion</option>
                              <option value="Christianity">Christianity</option>
                              <option value="Islam">Islam</option>
                              <option value="Judaism">Judaism</option>
                              <option value="Hinduism">Hinduism</option>
                              <option value="Buddhism">Buddhism</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="famhistory" class="form-label">
                              Family Medical History
                            </label>
                            <TagInput
                              placeholder="Enter History"
                              value={pat_family_history}
                              onChange={onChangeHistory}
                              onEnter={validateTag}
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label for="allergies" class="form-label">
                              Patients Allergies
                            </label>
                            <TagInput
                              placeholder="Enter Allergies"
                              value={pat_allergies}
                              onChange={onChangeAllergies}
                              onEnter={validateTag}
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label for="medcondition" class="form-label">
                              Patients Medical Conditions
                            </label>
                            <TagInput
                              placeholder="Enter History"
                              value={pat_medical_condition}
                              onChange={onChangeMedConditions}
                              onEnter={validateTag}
                            />
                          </div>

                          <h4 className="mt-2 mb-4"> Next of KIN</h4>

                          <div class="col-md-4 mb-3">
                            <label for="nokname" class="form-label">
                              NOK Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="nokname"
                              placeholder="Enter Next of KINs Name"
                              value={pat_nok_name}
                              onChange={(e) => {
                                setpatientNOKName(e.target.value);
                              }}
                            />
                          </div>
                          <div class="col-md-4 ">
                            <label for="nokphone" class="form-label">
                              NOK Phone
                            </label>
                            <input
                              type="phone"
                              class="form-control"
                              id="nokphone"
                              placeholder="Enter NOK phone"
                              value={pat_nok_phone}
                              onChange={(e) => {
                                setpatientNOKPhone(e.target.value);
                              }}
                            />
                          </div>
                          <div class=" col-md-4">
                            <label for="nokrelation" class="form-label">
                              NOK Relationship
                            </label>
                            <select
                              id="nokrelation"
                              class="form-select"
                              value={pat_nok_rel}
                              onChange={(e) => {
                                setpatientNOKRel(e.target.value);
                              }}
                            >
                              <option value="">Select a relationship</option>
                              <option value="spouse">Spouse</option>
                              <option value="parent">Parent</option>
                              <option value="child">Child</option>
                              <option value="sibling">Sibling</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                          <div class="mb-3 col-md-12">
                            <label for="nokaddress" class="form-label">
                              NOK Address
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="nokaddress"
                              placeholder="1234 Main St"
                              value={pat_nok_address}
                              onChange={(e) => {
                                setpatientNOKAddress(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            updatePatient();
                          }}
                        >
                          Update Patient
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
