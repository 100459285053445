import React, { useRef } from 'react';

function CopyToClipboardButton({ text }) {
  const textareaRef = useRef(null);

  const copyToClipboard = () => {
    if (textareaRef.current) {
      navigator.clipboard.writeText(textareaRef.current.value)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((error) => {
          console.error('Error copying text to clipboard:', error);
        });
    }
  };

  return (
    <div>
        <div class="input-group">
            <input type="text" ref={textareaRef} class="form-control" value={text} placeholder="Link will be generated here" aria-label="Generated Link"/>
            <button class="btn input-group-text btn-dark waves-effect waves-light" type="button" onClick={copyToClipboard}><i class="fe-copy"></i></button>
        </div>
      {/* <textarea
        ref={textareaRef}
        defaultValue={text}
        readOnly
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <button onClick={copyToClipboard}>Copy to Clipboard</button> */}
    </div>
  );
}

export default CopyToClipboardButton;
