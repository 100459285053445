import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import TableHead from "../../component/table-head";

export default function OrganizationTestResults() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingUpdateAlert, setShowingUpdateAlert] = useState(false);
  const [isShowingViewDoctorAlert, setShowingViewDoctorAlert] = useState(false);
  const [isShowingViewPatientAlert, setShowingViewPatientAlert] =
    useState(false);
  const [isShowingTestsAlert, setShowingViewTestAlert] = useState(false);
  const [updateNote, setUpdateNote] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [patientData, setPatientData] = useState({});

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patient Name",
      // width: "200px",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Laboratory",
      // width: "200px",
      selector: (row) => row.lab_name,
      sortable: true,
    },
    // {
    //   name: "Patient",
    //   width: "200px",
    //   selector: (row) => (
    //     <div>
    //       <button
    //         type="button"
    //         class="btn btn-warning waves-effect waves-light"
    //         onClick={() => {
    //           getPatientProfile(row.pat_pid, row.org_pid);
    //         }}
    //       >
    //         <span class="btn-label">
    //           <i class="mdi mdi-eye"></i>
    //         </span>
    //         View Patient
    //       </button>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Tests",
      // width:'130px',
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingViewTestAlert(true);
            }}
          >
            <span class="btn-label">
              <i class="mdi mdi-eye"></i>
            </span>
            View Test Results
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      width: "130px",
      selector: (row) => convertFromISODate(row.lab_date),
      sortable: true,
    },
    {
      name: "Status",
      width: "130px",
      selector: (row) =>
        row.status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-primary ">Seen</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Not Seen</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Files",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          {row.result.map((result) => (
            <a
              className="text-danger btn-icon waves-effect waves-light"
              onClick={() => {
                window.open(result);
              }}
            >
              <i
                className="mdi mdi-file-pdf-outline"
                style={{ fontSize: "20px" }}
              ></i>
            </a>
          ))}
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllTests(1);
  };
  const fetchAllTests = async (page, searchterm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }tests/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllTests(page);
  };

  useEffect(() => {
    changeTitle("Test Results | DOC-ROA");
    performTasks();
  }, []);

  const updateStoreForward = async () => {
    setLoading(true);
    let req = {
      sf_pid: selectedRow.sf_pid,
      org_pid: getUser()["org_pid"],
      doc_pid: getUser()["user_pid"],
      updated_note: updateNote,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}storef/update/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingUpdateAlert(false);
      successToast("Store Ref Updated Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingUpdateAlert(false);
    }
  };

  const deleteStoreReferal = async () => {
    //     setLoading(true)
    //     let req = {
    //         "cons_pid": selectedRow.cons_pid,
    //         "org_pid": getUser()['org_pid'],
    //         "del_type": 2
    //     }
    //     try {
    //         const headers = {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer "+getUser()["token"],
    //         };
    //         const response = await axios.patch(
    //         `${process.env.REACT_APP_MAIN}doctors/refs/delete/`,
    //         req,
    //         {
    //             headers: headers,
    //         }
    //         );
    //         console.log(response.data)
    //         setLoading(false)
    //         setShowingDeleteAlert(false)
    //         successToast("Referal Deleted Successfully")
    //    } catch (error) {
    //         console.log(error)
    //         errorToast(error.message)
    //         setLoading(false)
    //         setShowingDeleteAlert(false)
    //    }
  };

  const onClose = () => {
    setShowingViewDoctorAlert(false);
    setShowingViewPatientAlert(false);
    setShowingViewTestAlert(false);
    setShowingDeleteAlert(false);
    setShowingUpdateAlert(false);
  };

  const getDoctorProfile = async (doc_pid, org_pid) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/any_org_one/${doc_pid}/${org_pid}`,
        {
          headers: headers,
        }
      );
      setDoctorData(response.data.data ?? []);
      setShowingViewDoctorAlert(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getPatientProfile = async (pat_pid, org_pid) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/any_org_one/${pat_pid}/${org_pid}`,
        {
          headers: headers,
        }
      );
      setPatientData(response.data.data ?? []);
      setShowingViewPatientAlert(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getTextFromEditor = (content, delta, source, editor) => {
    setUpdateNote(content);
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Test Results</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${process.env.REACT_APP_MAIN}tests/all/?&org_pid=${
                      getUser()["org_pid"]
                    }`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllTests(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />

                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[10]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowingTestsAlert ? (
        <SweetAlert
          title="_"
          style={{ minWidth: "800px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <h4 className="mt-0 header-title">Test Results</h4>

              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>Test</th>
                      <th>Diagnosis</th>
                      <th>Instruction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedRow.tests.map((data) => (
                      <tr>
                        <th scope="row">{data.test}</th>
                        <td>{data.pdiagnosis}</td>
                        <td>{data.instructions}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingUpdateAlert ? (
        <SweetAlert
          title="_"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <h4>Update Note</h4>

              <div className="consDiv">
                <ReactQuill
                  toolbar={false}
                  theme="snow"
                  value={updateNote}
                  onChange={getTextFromEditor}
                />
              </div>
              <button
                type="button"
                className="btn btn-dark waves-effect waves-light mt-1"
                onClick={() => {
                  updateStoreForward();
                }}
              >
                Update Referal
              </button>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewDoctorAlert ? (
        <SweetAlert
          title=" _"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <img
                  className="flex-shrink-0 me-3 rounded-circle avatar-md"
                  alt="64x64"
                  src="assets/images/users/user-2.jpg"
                />
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{doctorData.doc_name}</h4>
                  <h5 className="fs-14 my-1 fw-normal">
                    <span className="badge badge-soft-primary ">
                      {doctorData.email}
                    </span>{" "}
                  </h5>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Email</h5>
              <p> {doctorData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {doctorData.doc_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {doctorData.doc_state}</p>

              <div className="clearfix"></div>

              <div className="attached-files mt-3">
                <h5>Signature </h5>
                <img
                  src={doctorData.signature}
                  className="img-fluid img-thumbnail"
                  alt="attached-img"
                  width="80"
                />
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewPatientAlert ? (
        <SweetAlert
          title="Patient Detail"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{patientData.pat_name}</h4>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Gender</h5>
              <p> {patientData.pat_gender}</p>
              <h5 class="font-600 m-b-5">Email</h5>
              <p> {patientData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {patientData.pat_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {patientData.pat_state}</p>

              <div className="clearfix"></div>
              <h5 class="font-600 m-b-5">Patients Allergies</h5>
              <div class="button-list">
                {patientData.pat_allergies.map((allergy) => (
                  <button
                    type="button"
                    class="btn btn-outline-primary waves-effect waves-light"
                  >
                    {allergy}
                  </button>
                ))}
              </div>
              <h5 class="font-600 m-b-5 mt-2">Patients Medical Conditions</h5>
              <div class="button-list">
                {patientData.pat_medical_condition.map((condition) => (
                  <button
                    type="button"
                    class="btn btn-outline-danger waves-effect waves-light"
                  >
                    {condition}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteStoreReferal}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
