import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import Select from "react-select";
import TableHead from "../../component/table-head";

export default function CenterStoreForward() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingUpdateAlert, setShowingUpdateAlert] = useState(false);
  const [isShowingViewDoctorAlert, setShowingViewDoctorAlert] = useState(false);
  const [isShowingViewPatientAlert, setShowingViewPatientAlert] =
    useState(false);
  const [updateNote, setUpdateNote] = useState("");
  const [createNote, setCreateNote] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [options, setOptions] = useState([]);
  const [docoptions, setDocOptions] = useState([]);
  const [pat_pid, setPatPid] = useState("");
  const [doc_pid, setDocPid] = useState("");
  const [doc_org_pid, setDocOrgPid] = useState("");
  const [files, setFiles] = useState([]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patients Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Doctors Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },
    {
      name: "Date",
      width: "130px",
      selector: (row) => convertFromISODate(row.store_date),
      sortable: true,
    },
    {
      name: "Report",

      selector: (row) => row.report,
      sortable: true,
    },

    {
      name: "Status",
      width: "130px",
      selector: (row) =>
        row.status == 2 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-primary ">Seen</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Not Seen</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Files",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          {(row.results == undefined ? [] : row.results).map((result) => (
            <a
              className="text-danger btn-icon waves-effect waves-light"
              onClick={() => {
                window.open(result);
              }}
            >
              <i
                className="mdi mdi-file-pdf-outline"
                style={{ fontSize: "20px" }}
              ></i>
            </a>
          ))}
        </div>
      ),
    },
    {
      name: "Actions",
      button: true,
      width: "120px",
      cell: (row) => (
        <div>
          {/* <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-info btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingUpdateAlert(true);
            }}
          >
            <i className="fe-edit"></i>
          </button> */}
          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingDeleteAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllStoreAndForward(1);
    await getPatients();
    await getDoctors();
  };
  const fetchAllStoreAndForward = async (page, searchterm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }storef/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getPatients = async () => {
    try {
      // setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };

      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/list_all/`,
        {
          headers: headers,
        }
      );
      let unsetOptions = response.data.data;
      let newOptions = [];
      for (let index = 0; index < unsetOptions.length; index++) {
        const patient = {
          value: unsetOptions[index]["pat_pid"],
          label: unsetOptions[index]["pat_name"],
        };
        newOptions.push(patient);
      }
      setOptions(newOptions);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
    }
  };
  const getDoctors = async () => {
    try {
      // setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };

      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/list_all/`,
        {
          headers: headers,
        }
      );
      let unsetOptions = response.data.data;
      let newOptions = [];
      for (let index = 0; index < unsetOptions.length; index++) {
        const doctors = {
          value: unsetOptions[index]["doc_pid"],
          label: unsetOptions[index]["doc_name"],
          org: unsetOptions[index]["org_pid"],
        };
        newOptions.push(doctors);
      }
      setDocOptions(newOptions);

      // setLoading(false);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
    }
  };

  const handlePageChange = (page) => {
    fetchAllStoreAndForward(page);
  };

  const updateStoreForward = async () => {
    setLoading(true);
    let req = {
      sf_pid: selectedRow.sf_pid,
      org_pid: getUser()["org_pid"],
      doc_pid: selectedRow.doc_pid,
      updated_note: updateNote,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}storef/update/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingUpdateAlert(false);
      successToast("Store Updated Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingUpdateAlert(false);
    }
  };

  const onClose = () => {
    setShowingViewDoctorAlert(false);
    setShowingViewPatientAlert(false);

    setShowingDeleteAlert(false);
    setShowingUpdateAlert(false);
  };

  const getPatientProfile = async (pat_pid, org_pid) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/any_org_one/${pat_pid}/${org_pid}`,
        {
          headers: headers,
        }
      );
      setPatientData(response.data.data ?? []);
      setShowingViewPatientAlert(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getTextFromEditor = (content, delta, source, editor) => {
    setUpdateNote(content);
  };
  const getTextFromEditorCreate = (content, delta, source, editor) => {
    setCreateNote(content);
  };

  const selectPatient = (value) => {
    console.log(value);
    if (value == null) {
      setPatPid("");
      return;
    }
    setPatPid(value["value"]);
  };

  const selectDoctor = (value) => {
    if (value == null) {
      setDocPid("");
      return;
    }
    console.log(value);
    setDocPid(value["value"]);
    setDocOrgPid(value["org"]);
  };

  const createSF = async () => {
    setLoading(true);
    if (files.length === 0) {
      errorToast("Please add at least one file to upload");
      setLoading(false);
      return;
    }
    if (createNote === "" || createNote === undefined) {
      errorToast("Please add a Note");
      setLoading(false);
      return;
    }
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + getUser()["token"],
    };
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("results", files[i]);
    }

    formData.append("id", selectedRow._id);
    formData.append("doc_pid", doc_pid);
    formData.append("doc_org_pid", doc_org_pid);
    formData.append("pat_pid", pat_pid);
    formData.append("org_pid", getUser()["org_pid"].toString());
    formData.append("center_pid", getUser()["user_pid"].toString());
    formData.append("report", createNote);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}storef/create/`,
        formData,
        {
          headers: headers,
        }
      );
      setLoading(false);
      successToast("Store and Forward Created Successfully");
    } catch (error) {
      console.error("Upload error:", error);
      setLoading(false);
      errorToast(error.message);
    }
  };

  const deleteSF = async () => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_MAIN}storef/delete/${selectedRow.sf_pid}/2/${selectedRow.org_pid}`,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingDeleteAlert(false);
      successToast("Store and Forward Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingDeleteAlert(false);
    }
  };

  useEffect(() => {
    changeTitle("Store and Forward | DOC-ROA");
    performTasks();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Notification />
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">
                    Create Store and Forward
                  </h4>
                  <div className="row">
                    <div className="col">
                      <div className="mb-2">
                        <h5>Select Doctor</h5>

                        <Select
                          options={docoptions}
                          isSearchable={true}
                          isClearable={true}
                          onChange={selectDoctor}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-2">
                        <h5>Select Patient</h5>

                        <Select
                          options={options}
                          isSearchable={true}
                          isClearable={true}
                          onChange={selectPatient}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <h5>Upload File(s)</h5>
                    <input
                      type="file"
                      id="example-fileinput"
                      class="form-control"
                      multiple={true}
                      onChange={(e) => {
                        setFiles(e.target.files);
                      }}
                    />
                  </div>
                  <div className="consDiv">
                    <h5>Add Note</h5>
                    <ReactQuill
                      toolbar={false}
                      theme="snow"
                      value={createNote}
                      onChange={getTextFromEditorCreate}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mt-1"
                    onClick={() => {
                      createSF();
                    }}
                  >
                    Create Store and Forward
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card" style={{ minHeight: "calc(100vh - 200px" }}>
                <div className="card-body">
                  <h4 className="header-title mb-3">Forwarded Results</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${process.env.REACT_APP_MAIN}storef/all/?&org_pid=${
                      getUser()["org_pid"]
                    }`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllStoreAndForward(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingUpdateAlert ? (
        <SweetAlert
          title="_"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <h4>Update Note</h4>

              <div className="consDiv">
                <ReactQuill
                  toolbar={false}
                  theme="snow"
                  value={updateNote}
                  onChange={getTextFromEditor}
                />
              </div>
              <button
                type="button"
                className="btn btn-dark waves-effect waves-light mt-1"
                onClick={() => {
                  updateStoreForward();
                }}
              >
                Update Referal
              </button>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewDoctorAlert ? (
        <SweetAlert
          title=" _"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <img
                  className="flex-shrink-0 me-3 rounded-circle avatar-md"
                  alt="64x64"
                  src="assets/images/users/user-2.jpg"
                />
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{doctorData.doc_name}</h4>
                  <h5 className="fs-14 my-1 fw-normal">
                    <span className="badge badge-soft-primary ">
                      {doctorData.email}
                    </span>{" "}
                  </h5>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Email</h5>
              <p> {doctorData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {doctorData.doc_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {doctorData.doc_state}</p>

              <div className="clearfix"></div>

              <div className="attached-files mt-3">
                <h5>Signature </h5>
                <img
                  src={doctorData.signature}
                  className="img-fluid img-thumbnail"
                  alt="attached-img"
                  width="80"
                />
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewPatientAlert ? (
        <SweetAlert
          title="Patient Detail"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{patientData.pat_name}</h4>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Gender</h5>
              <p> {patientData.pat_gender}</p>
              <h5 class="font-600 m-b-5">Email</h5>
              <p> {patientData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {patientData.pat_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {patientData.pat_state}</p>

              <div className="clearfix"></div>
              <h5 class="font-600 m-b-5">Patients Allergies</h5>
              <div class="button-list">
                {patientData.pat_allergies.map((allergy) => (
                  <button
                    type="button"
                    class="btn btn-outline-primary waves-effect waves-light"
                  >
                    {allergy}
                  </button>
                ))}
              </div>
              <h5 class="font-600 m-b-5 mt-2">Patients Medical Conditions</h5>
              <div class="button-list">
                {patientData.pat_medical_condition.map((condition) => (
                  <button
                    type="button"
                    class="btn btn-outline-danger waves-effect waves-light"
                  >
                    {condition}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteSF}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
