import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import {
  changeTitle,
  convertFromISODate,
  errorToast,
  getUser,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import Notification from "../../component/notification";
import LoadingScreen from "react-loading-screen";
import { ToastContainer } from "react-toastify";
import TableHead from "../../component/table-head";

export default function PharmDashboard() {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [newPerPage, setPageLimit] = useState(5);
  const [medications, setMedications] = useState([]);
  const [report, setReport] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isShowingMedicationAlert, setIsShowingMedicationAlert] =
    useState(false);
  const [isShowingReportAlert, setIsShowingReportAlert] = useState(false);
  const [isShowingUpdateAlert, setIsShowingUpdateAlert] = useState(false);
  const [isShowingDeleteAlert, setIsShowingDeleteAlert] = useState(false);

  const getDashboardCards = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}phams/dashboard/cards/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}/`,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);
      setCards(response.data.data);
    } catch (error) {}
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patients Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Doctors Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },
    {
      name: "Center Name",
      selector: (row) => row.center_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => convertFromISODate(row.pham_date),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.status == 0 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Pending</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Completed</span>{" "}
          </h5>
        ),
      sortable: true,
    },

    {
      name: "Medications",
      // width: "200px",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            onClick={() => {
              setMedications(row.medications);
              setIsShowingMedicationAlert(true);
            }}
          >
            <span class="btn-label">
              <i class="mdi mdi-eye"></i>
            </span>
            View
          </button>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      width: "150px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-icon waves-effect waves-light"
            disabled={row.status == 1 ? true : false}
            onClick={() => {
              setSelectedRow(row);
              setIsShowingUpdateAlert(true);
            }}
          >
            <i className="fe-check"></i>
          </button>

          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setIsShowingDeleteAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const columnsTest = [
    {
      name: "Test",
      selector: (row) => row.test,
      sortable: true,
    },
    {
      name: "Provisional Diagnosis",
      selector: (row) => row.pdiagnosis,
      sortable: true,
    },
    {
      name: "Instructions",
      selector: (row) => row.instructions,
      sortable: true,
    },
  ];

  const columnsPharm = [
    {
      name: "Medicine",
      selector: (row) => row.medicine,
      sortable: true,
    },
    {
      name: "Dose",
      selector: (row) => row.dose + " " + row.unit,
      sortable: true,
      width: "100px",
    },
    {
      name: "Duration",
      selector: (row) => row.duration,
      sortable: true,
      width: "120px",
    },
    {
      name: "Route",
      selector: (row) => row.route,
      sortable: true,
    },
    {
      name: "Freq.",
      selector: (row) => row.frequency,
      sortable: true,
      width: "100px",
    },
    {
      name: "Comment",
      selector: (row) => row.comments,
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              if (row) {
                setMedications((prevData) =>
                  prevData.filter((item) => item !== row)
                );
              }
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const onClose = () => {
    setIsShowingMedicationAlert(false);
    setIsShowingReportAlert(false);
    setIsShowingUpdateAlert(false);
    setIsShowingDeleteAlert(false);
  };
  const handlePageChange = (page) => {
    fetchAllPrescriptions(page);
  };
  const performTasks = async () => {
    await fetchAllPrescriptions(1);
  };
  const fetchAllPrescriptions = async (page, searchterm) => {
    console.log(getUser());
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }phams/prescriptions/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  };

  const updatePrescription = async (status) => {
    setLoading(true);
    let req = {
      _id: selectedRow._id,
      org_pid: getUser()["org_pid"],
      status: 1,
      report: report,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}phams/prescriptions/update/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);

      successToast("Prescription Updated");
      setIsShowingUpdateAlert(false);
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  };
  const deletePrescription = async () => {
    console.log(selectedRow);
    setLoading(true);
    let req = {
      _id: selectedRow._id,
      org_pid: getUser()["org_pid"],
      del_type: 2,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}phams/prescriptions/delete/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      setIsShowingDeleteAlert(false);

      successToast("Prescription Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setIsShowingDeleteAlert(false);
    }
  };

  useEffect(() => {
    changeTitle("Dashboard | DOC-ROA");
    getDashboardCards();
    performTasks();
  }, []);

  return loading ? (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#9ee5f8"
        textColor="#77d5cc"
        logoSrc="assets/images/logo-dark.png"
        text="..."
      ></LoadingScreen>
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="page-title-main">Dashboard</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Notification />
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown float-end">
                    {/* <button
                      class="btn btn-link waves-effect"
                      style={{ padding: "0px" }}
                    >
                      view
                    </button> */}
                  </div>

                  <h4 class="header-title mt-0 mb-4">Total Prescriptions</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-danger mdi mdi-clipboard-account-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1"> {cards.total_pres} </h2>
                      <p class="text-muted mb-1">Total Prescriptions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Pending Prescription</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-success mdi mdi-clipboard-alert-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.pending_pres}{" "}
                      </h2>
                      <p class="text-muted mb-1">Pending Prescription</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Completed Prescription</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-warning mdi mdi-clipboard-check-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.completed_pres}{" "}
                      </h2>
                      <p class="text-muted mb-1">Completed Prescription</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Prescriptions</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${
                      process.env.REACT_APP_MAIN
                    }phams/prescriptions/all/?&org_pid=${getUser()["org_pid"]}`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllPrescriptions(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5]}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingMedicationAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1200px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h4 class="header-title mb-4">Patients Medications</h4>

            <DataTable
              columns={columnsPharm}
              data={medications}
              progressPending={loading}
              pagination
              // paginationServer
              paginationTotalRows={totalRows}
              // onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[5]}
              //   onChangePage={handlePageChange}
              customStyles={customStyles}
              // actions={actionsMemo}
            />
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingReportAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1000px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <div class="mb-3">
              <label for="example-textarea" class="form-label">
                Lab Report
              </label>
              <textarea
                class="form-control"
                id="example-textarea"
                rows="4"
                value={report}
                disabled={true}
              ></textarea>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingUpdateAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1000px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h5 className="header-title">Report</h5>

            <div class="mb-3">
              <label for="example-textarea" class="form-label">
                Pharmacy Report
              </label>
              <textarea
                class="form-control"
                id="example-textarea"
                rows="4"
                value={report}
                onChange={(e) => {
                  setReport(e.target.value);
                }}
              ></textarea>
            </div>
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light"
              onClick={updatePrescription}
            >
              <span class="btn-label">
                <i class="mdi mdi-check-all"></i>
              </span>
              Report and Submit
            </button>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deletePrescription}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
