import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import TableHead from "../../component/table-head";

export default function CenterPrescriptions() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingUpdateAlert, setShowingUpdateAlert] = useState(false);
  const [isShowingViewDoctorAlert, setShowingViewDoctorAlert] = useState(false);
  const [isShowingViewPatientAlert, setShowingViewPatientAlert] =
    useState(false);
  const [isShowingMedicationsAlert, setShowingViewMedicationsAlert] =
    useState(false);
  const [updateNote, setUpdateNote] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [patientData, setPatientData] = useState({});

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Doctor Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },

    {
      name: "Medications",
      // width:'130px',
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn btn-warning waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingViewMedicationsAlert(true);
            }}
          >
            <span class="btn-label">
              <i class="mdi mdi-eye"></i>
            </span>
            View Medications
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      width: "130px",
      selector: (row) => convertFromISODate(row.pham_date),
      sortable: true,
    },
    {
      name: "Report",

      selector: (row) => row.pham_note,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-primary ">Seen</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Not Seen</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    // {
    //   name: 'Actions',
    //   button: true,
    //   width:'100px',
    //   cell: (row) => (
    //     <div>

    //           <button type="button" className="btn btn-danger btn-icon waves-effect waves-light" onClick={()=>{
    //               setSelectedRow(row)
    //               setShowingDeleteAlert(true)
    //               }}><i className="fe-trash"></i>
    //           </button>
    //       </div>
    //   ),
    // },
  ];

  const performTasks = async () => {
    await fetchAllPrescriptions(1);
  };
  const fetchAllPrescriptions = async (page, searchterm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }centers/prescriptions/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllPrescriptions(page);
  };

  useEffect(() => {
    changeTitle("Prescriptions | DOC-ROA");
    performTasks();
  }, []);

  const updateStoreForward = async () => {
    setLoading(true);
    let req = {
      sf_pid: selectedRow.sf_pid,
      org_pid: getUser()["org_pid"],
      doc_pid: getUser()["user_pid"],
      updated_note: updateNote,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}storef/update/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingUpdateAlert(false);
      successToast("Store Ref Updated Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingUpdateAlert(false);
    }
  };

  const onClose = () => {
    setShowingViewDoctorAlert(false);
    setShowingViewPatientAlert(false);
    setShowingViewMedicationsAlert(false);
    setShowingDeleteAlert(false);
    setShowingUpdateAlert(false);
  };

  const getDoctorProfile = async (doc_pid, org_pid) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/any_org_one/${doc_pid}/${org_pid}`,
        {
          headers: headers,
        }
      );
      setDoctorData(response.data.data ?? []);
      setShowingViewDoctorAlert(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getPatientProfile = async (pat_pid, org_pid) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/any_org_one/${pat_pid}/${org_pid}`,
        {
          headers: headers,
        }
      );
      setPatientData(response.data.data ?? []);
      setShowingViewPatientAlert(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getTextFromEditor = (content, delta, source, editor) => {
    setUpdateNote(content);
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Prescriptions</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${
                      process.env.REACT_APP_MAIN
                    }centers/prescriptions/all/?&org_pid=${
                      getUser()["org_pid"]
                    }`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllPrescriptions(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    // title="Users"
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}

                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                    // actions={actionsMemo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingMedicationsAlert ? (
        <SweetAlert
          title="_"
          style={{ minWidth: "1000px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <h4 className="mt-0 header-title">Medications</h4>

              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>Medicine</th>
                      <th>Dose</th>
                      <th>Frequency</th>
                      <th>Duration</th>
                      <th>Route</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedRow.medications.map((data) => (
                      <tr>
                        <th scope="row">{data.medicine}</th>
                        <td>{data.dose + " " + data.unit}</td>
                        <td>{data.frequency}</td>
                        <td>{data.duration}</td>
                        <td>{data.route}</td>
                        <td>{data.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingUpdateAlert ? (
        <SweetAlert
          title="_"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <h4>Update Note</h4>

              <div className="consDiv">
                <ReactQuill
                  toolbar={false}
                  theme="snow"
                  value={updateNote}
                  onChange={getTextFromEditor}
                />
              </div>
              <button
                type="button"
                className="btn btn-dark waves-effect waves-light mt-1"
                onClick={() => {
                  updateStoreForward();
                }}
              >
                Update Referal
              </button>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewDoctorAlert ? (
        <SweetAlert
          title=" _"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <img
                  className="flex-shrink-0 me-3 rounded-circle avatar-md"
                  alt="64x64"
                  src="assets/images/users/user-2.jpg"
                />
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{doctorData.doc_name}</h4>
                  <h5 className="fs-14 my-1 fw-normal">
                    <span className="badge badge-soft-primary ">
                      {doctorData.email}
                    </span>{" "}
                  </h5>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Email</h5>
              <p> {doctorData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {doctorData.doc_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {doctorData.doc_state}</p>

              <div className="clearfix"></div>

              <div className="attached-files mt-3">
                <h5>Signature </h5>
                <img
                  src={doctorData.signature}
                  className="img-fluid img-thumbnail"
                  alt="attached-img"
                  width="80"
                />
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewPatientAlert ? (
        <SweetAlert
          title="Patient Detail"
          style={{ minWidth: "400px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{patientData.pat_name}</h4>
                </div>
              </div>

              <h5 class="font-600 m-b-5">Gender</h5>
              <p> {patientData.pat_gender}</p>
              <h5 class="font-600 m-b-5">Email</h5>
              <p> {patientData.email}</p>
              <h5 class="font-600 m-b-5">Phone</h5>
              <p> {patientData.pat_phone}</p>
              <h5 class="font-600 m-b-5">State</h5>
              <p> {patientData.pat_state}</p>

              <div className="clearfix"></div>
              <h5 class="font-600 m-b-5">Patients Allergies</h5>
              <div class="button-list">
                {patientData.pat_allergies.map((allergy) => (
                  <button
                    type="button"
                    class="btn btn-outline-primary waves-effect waves-light"
                  >
                    {allergy}
                  </button>
                ))}
              </div>
              <h5 class="font-600 m-b-5 mt-2">Patients Medical Conditions</h5>
              <div class="button-list">
                {patientData.pat_medical_condition.map((condition) => (
                  <button
                    type="button"
                    class="btn btn-outline-danger waves-effect waves-light"
                  >
                    {condition}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
