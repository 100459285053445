import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import {
  changeTitle,
  convertFromISODate,
  errorToast,
  getUser,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import Notification from "../../component/notification";
import LoadingScreen from "react-loading-screen";
import { ToastContainer } from "react-toastify";
import TableHead from "../../component/table-head";

export default function LabDashboard() {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [newPerPage, setPageLimit] = useState(5);
  const [tests, setTests] = useState([]);
  const [report, setReport] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isShowingLabAlert, setIsShowingLabAlert] = useState(false);
  const [isShowingReportAlert, setIsShowingReportAlert] = useState(false);
  const [isShowingUpdateAlert, setIsShowingUpdateAlert] = useState(false);
  const [isShowingDeleteAlert, setIsShowingDeleteAlert] = useState(false);

  const getDashboardCards = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}labs/dashboard/cards/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}/`,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);
      setCards(response.data.data);
    } catch (error) {}
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patients Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Doctors Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },
    {
      name: "Center Name",
      selector: (row) => row.center_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => convertFromISODate(row.lab_date),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.status == 0 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Pending</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Completed</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Report",
      width: "200px",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            onClick={() => {
              setReport(row.report);
              setIsShowingReportAlert(true);
            }}
          >
            <span class="btn-label">
              <i class="mdi mdi-eye"></i>
            </span>
            View Report
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Tests",
      width: "200px",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            onClick={() => {
              setTests(row.tests);
              setIsShowingLabAlert(true);
            }}
          >
            <span class="btn-label">
              <i class="mdi mdi-eye"></i>
            </span>
            View Tests
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Files",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          {row.result.map((result) => (
            <a
              className="text-danger btn-icon waves-effect waves-light me-2"
              onClick={() => {
                window.open(result);
              }}
            >
              <i
                className="mdi mdi-file-pdf-outline"
                style={{ fontSize: "20px" }}
              ></i>
            </a>
          ))}
        </div>
      ),
    },
    {
      name: "Actions",
      button: true,
      width: "150px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-warning btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setReport(row.report);
              setIsShowingUpdateAlert(true);
            }}
          >
            <i className="fe-edit"></i>
          </button>

          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setIsShowingDeleteAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const columnsTest = [
    {
      name: "Test",
      selector: (row) => row.test,
      sortable: true,
    },
    {
      name: "Provisional Diagnosis",
      selector: (row) => row.pdiagnosis,
      sortable: true,
    },
    {
      name: "Instructions",
      selector: (row) => row.instructions,
      sortable: true,
    },
  ];

  const onClose = () => {
    setIsShowingLabAlert(false);
    setIsShowingReportAlert(false);
    setIsShowingUpdateAlert(false);
    setIsShowingDeleteAlert(false);
  };
  const handlePageChange = (page) => {
    fetchAllTests(page);
  };
  const performTasks = async () => {
    await fetchAllTests(1);
  };
  const fetchAllTests = async (page, searchTerm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }labs/tests/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchTerm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  };

  const uploadReport = async () => {
    setLoading(true);
    if (files.length === 0) {
      errorToast("Please add at least one file to upload");
      setLoading(false);
      return;
    }
    if (report === "" || report === undefined) {
      errorToast("Please add a report");
      setLoading(false);
      return;
    }
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + getUser()["token"],
    };
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("result", files[i]);
    }

    // formData.append("result", files[0]);
    formData.append("id", selectedRow._id);
    formData.append("org_pid", getUser()["org_pid"].toString());
    formData.append("status", 1);
    formData.append("report", report);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}labs/tests/update/`,
        formData,
        {
          headers: headers,
        }
      );
      setIsShowingUpdateAlert(false);
      setLoading(false);
      successToast("Updated Successfully");
    } catch (error) {
      console.error("Upload error:", error);
      setLoading(false);
      errorToast(error.message);
    }
  };

  const deleteTest = async () => {
    setLoading(true);
    let req = {
      _id: selectedRow._id,
      org_pid: getUser()["org_pid"],
      del_type: 2,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}labs/tests/delete/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      setIsShowingDeleteAlert(false);

      successToast("Test Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setIsShowingDeleteAlert(false);
    }
  };

  useEffect(() => {
    changeTitle("Dashboard | DOC-ROA");
    getDashboardCards();
    performTasks();
  }, []);

  return loading ? (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#9ee5f8"
        textColor="#77d5cc"
        logoSrc="assets/images/logo-dark.png"
        text="..."
      ></LoadingScreen>
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="page-title-main">Dashboard</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Notification />
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown float-end">
                    <button
                      class="btn btn-link waves-effect"
                      style={{ padding: "0px" }}
                    >
                      view
                    </button>
                  </div>

                  <h4 class="header-title mt-0 mb-4">Total Tests</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-danger mdi mdi-flask-empty"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1"> {cards.total_tests} </h2>
                      <p class="text-muted mb-1">Total Tests</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Pending Tests</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-success mdi mdi-flask-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.pending_tests}{" "}
                      </h2>
                      <p class="text-muted mb-1">Pending Tests</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Completed Tests</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-warning mdi mdi-flask-round-bottom"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.completed_tests}{" "}
                      </h2>
                      <p class="text-muted mb-1">Completed Tests</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Tests</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${
                      process.env.REACT_APP_MAIN
                    }labs/tests/all/?&org_pid=${getUser()["org_pid"]}`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllTests(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5]}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingLabAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1000px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h4 class="header-title mb-4">Patients Tests</h4>

            <DataTable
              columns={columnsTest}
              data={tests}
              progressPending={loading}
              pagination
              // paginationServer
              paginationTotalRows={totalRows}
              // onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[5]}
              //   onChangePage={handlePageChange}
              customStyles={customStyles}
              // actions={actionsMemo}
            />
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingReportAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1000px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <div class="mb-3">
              <label for="example-textarea" class="form-label">
                Lab Report
              </label>
              <textarea
                class="form-control"
                id="example-textarea"
                rows="4"
                value={report}
                disabled={true}
              ></textarea>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingUpdateAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{
            minWidth: "1000px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h5 className="header-title"> Update Report</h5>

            <div class="mb-3 mt-3">
              <label for="example-fileinput" class="form-label">
                Upload File(s)
              </label>
              <input
                type="file"
                id="example-fileinput"
                class="form-control"
                multiple={true}
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
              />
            </div>
            <div class="mb-3">
              <label for="example-textarea" class="form-label">
                Lab Report
              </label>
              <textarea
                class="form-control"
                id="example-textarea"
                rows="4"
                value={report}
                onChange={(e) => {
                  setReport(e.target.value);
                }}
              ></textarea>
            </div>
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light"
              onClick={uploadReport}
            >
              <span class="btn-label">
                <i class="mdi mdi-check-all"></i>
              </span>
              Update report
            </button>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteTest}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
