import React from "react";

const VerticalCalender = ({ onClick, onSelected, buttonIndex }) => {
  const currentDate = new Date();
  const endDate = new Date();
  endDate.setMonth(currentDate.getMonth() + 1);

  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const formatDateLabel = (date) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const day = date.getDate();
    const suffix = getNumberSuffix(day);
    const formattedDate = date.toLocaleDateString(undefined, options);

    // return `${formattedDate.slice(0, -6)}${suffix}${formattedDate.slice(-5)}`;
    return `${formattedDate}`;
  };

  const getNumberSuffix = (number) => {
    if (number >= 11 && number <= 13) {
      return "th";
    }

    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const handleClick = (date, index) => {
    // Handle click event for the date
    onClick(date, index);
  };

  return (
    <div className="row" style={{ maxHeight: "400px", overflowY: "scroll" }}>
      {dates.map((date, index) => (
        <button
          type="button"
          class={`btn ${
            buttonIndex == index ? "btn-primary" : "btn-outline-dark"
          } waves-effect waves-light d-flex `}
          key={index}
          style={{ justifyContent: "space-between", borderColor: "#ddd" }}
          onClick={() => handleClick(date, index)}
        >
          {formatDateLabel(date)}
          <span class="btn-label-right">
            <i class="mdi mdi-arrow-right"></i>
          </span>
        </button>

        // <button
        //   className="btn btn-outline-primary"
        //   key={index}
        //   onClick={() => handleClick(date)}
        // >
        //   {formatDateLabel(date)}
        // </button>
      ))}
    </div>
  );
};

export default VerticalCalender;
