import React from "react";
import moment from "moment";

const TimeSlotsComponent = ({
  startTime,
  endTime,
  duration,
  onClick,
  slotIndex,
  bookedSlots,
}) => {
  const generateTimeSlots = () => {
    try {
      const slots = [];
      const start = moment(startTime, "hh:mm A");
      const end = moment(endTime, "hh:mm A");

      while (start.isBefore(end) || start.isSame(end)) {
        const slotStartTime = start.format("hh:mm A");
        start.add(duration == 0 ? 30 : duration, "minutes");
        const slotEndTime = start.isAfter(end)
          ? end.format("hh:mm A")
          : start.format("hh:mm A");
        if (start.isSameOrBefore(end)) {
          slots.push([slotStartTime, slotEndTime]);
        }
      }

      return slots;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSlotClick = (slot, index) => {
    onClick(slot, index);
  };

  const timeSlots = generateTimeSlots();

  const isSlotBooked = (slot) => {
    // console.log(bookedSlots);
    if (bookedSlots == []) return false;
    return bookedSlots.some((bookedSlot) => {
      return (
        moment(bookedSlot.start_time, "hh:mm A").isSame(
          moment(slot[0], "hh:mm A")
        ) ||
        moment(bookedSlot.end_time, "hh:mm A").isSame(
          moment(slot[1], "hh:mm A")
        )
      );
    });
  };

  return (
    <div>
      {timeSlots.map((slot, index) => (
        <button
          className={`btn ${
            slotIndex === index
              ? "btn-primary"
              : isSlotBooked(slot)
              ? "btn-dark"
              : "btn-outline-primary"
          } `}
          style={{ margin: "5px" }}
          key={index}
          onClick={() => handleSlotClick(slot, index)}
          disabled={isSlotBooked(slot)}
        >
          {`${slot[0]} - ${slot[1]}`}
        </button>
      ))}
    </div>
  );
};

export default TimeSlotsComponent;
