import { useEffect, useState } from "react";
import {
  clearStorage,
  errorToast,
  getLoggedInRole,
  getUser,
  successToast,
} from "../functions/reusables";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [noKYC, setNoKYC] = useState(false);
  const [kYCpending, setKYCPending] = useState(false);
  const [hasNotSigned, setHasNotSigned] = useState(false);
  const [consultation, setConsultation] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [prescription, setPrescription] = useState(null);
  const [storef, setStoref] = useState(null);
  const [refers, setRefers] = useState(null);
  const [hasNotification, setHasNotification] = useState(false);
  const [test, setTest] = useState(null);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  const checkNotification = () => {
    let role = getLoggedInRole();
    if (role === "doctor") {
      checkDocProfile();
      getNotificationWith2IDS("doctors");
    } else if (role === "patient") {
      getNotificationWith2IDS("patients");
    } else if (role === "organization") {
      getKYCStatus();
      getOrgNotifications();
    } else if (role === "organization_user") {
      getOrgUserNotifications();
    } else if (role === "health_center") {
      getCenterNotifications();
    } else if (role === "laboratory") {
      getLabNotifications();
    } else if (role === "pharmacy") {
      getPhamNotifications();
    }
  };

  const getNotificationWith2IDS = async (role) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}${role}/dashboard/notify/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };
  const getOrgNotifications = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}org/dashboard/notify/${
          getUser()["user_pid"]
        }`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };
  const getOrgUserNotifications = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}org/dashboard/notify/${
          getUser()["org_pid"]
        }`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };
  const getCenterNotifications = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}centers/dashboard/notify/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };
  const getLabNotifications = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}labs/dashboard/notify/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };
  const getPhamNotifications = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}phams/dashboard/notify/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      handleData(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };

  const checkDocProfile = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}auth/details/doctor/`,
        {
          headers: headers,
        }
      );
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
      if (error.response.data.message == "No User Found") {
        setHasNotSigned(true);
        return;
      }
    }
  };
  const getKYCStatus = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}org/kyc_status/${getUser()["user_pid"]}/`,
        {
          headers: headers,
        }
      );
      if (response.data.data === 6) {
        setNoKYC(true);

        setKYCPending(false);
      } else if (response.data.data === 8) {
        setKYCPending(true);
        setNoKYC(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "token expired") {
        clearStorage();
        navigate("/");
      }
    }
  };

  const handleData = (data) => {
    let notification = 0;
    if (data.pending_consultations > 0) {
      setConsultation(data.pending_consultations);
      notification = 1;
    }
    if (data.pending_prescriptions > 0 || data.pending_pres) {
      setPrescription(data.pending_prescriptions || data.pending_pres);
      notification = 1;
    }
    if (data.pending_tests > 0) {
      setTest(data.pending_tests);
      notification = 1;
    }
    if (data.awaiting_confirmation > 0) {
      setConfirmation(data.awaiting_confirmation);
      notification = 1;
    }
    if (data.pending_storef > 0) {
      setStoref(data.pending_storef);
      notification = 1;
    }
    if (data.pending_refers > 0) {
      setRefers(data.pending_refers);
      notification = 1;
    }
    if (notification === 1) {
      setHasNotification(true);
    }
  };

  const handleFileUpload = () => {
    setIsLoadingUpload(true);
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + getUser()["token"],
    };
    const formData = new FormData();
    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   formData.append('kyc_file', file);
    // }
    formData.append("kyc_file", files[0]);
    // formData.append('org_pid',parseInt(getUser()["user_pid"]))
    // Make a request to upload the files using an AJAX library like Axios or Fetch
    // Replace the URL and HTTP method (e.g., POST) with your actual server endpoint
    axios
      .post(
        `${process.env.REACT_APP_MAIN}org/submit_kyc/?org_pid=${
          getUser()["user_pid"]
        }`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // Handle the response from the server
        successToast("Files uploaded Successfully");
        setNoKYC(false);
        setKYCPending(true);
        // setKYC(true)
        setIsLoadingUpload(false);
      })
      .catch((error) => {
        // Handle any errors that occur during the upload
        console.error("Error uploading files:", error);
        errorToast("Error uploading files:", error);
        setIsLoadingUpload(false);
      });
  };

  useEffect(() => {
    checkNotification();
  }, []);

  return (
    <div>
      {hasNotSigned ? (
        <div
          class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show"
          role="alert"
        >
          <button
            type="button"
            class="btn-close btn-close-dark"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ opacity: 1 }}
          ></button>
          <span style={{ fontWeight: "bold" }}>
            You Need Activate your Profile!{" "}
          </span>{" "}
          Go to settings and update your signature.
        </div>
      ) : (
        <div></div>
      )}

      {hasNotification ? (
        <div
          class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show"
          role="alert"
        >
          <button
            type="button"
            class="btn-close btn-close-dark"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ opacity: 1 }}
          ></button>

          <div>
            {consultation !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>
                  Consultation Alert! :{" "}
                </span>{" "}
                you have {consultation} pending consultation.
              </div>
            ) : (
              <div></div>
            )}
            {confirmation !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>
                  Confirmation Alert! :{" "}
                </span>{" "}
                you have {confirmation} awaiting confirmation.
              </div>
            ) : (
              <div></div>
            )}
            {prescription !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>
                  Prescription Alert! :{" "}
                </span>{" "}
                you have {prescription} pending prescription.
              </div>
            ) : (
              <div></div>
            )}
            {test !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>Test Alert! : </span> you
                have {test} pending test.
              </div>
            ) : (
              <div></div>
            )}
            {storef !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>
                  Store & Forward Alert! :{" "}
                </span>{" "}
                you have {storef} pending store and forward.
              </div>
            ) : (
              <div></div>
            )}
            {refers !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>Refers! : </span> you have{" "}
                {refers} pending refers.
              </div>
            ) : (
              <div></div>
            )}
            {/* {prescription !== null ? (
              <div className="mb-1">
                <span style={{ fontWeight: "bold" }}>
                  Prescription Alert! :{" "}
                </span>{" "}
                you have {prescription} pending prescription.
              </div>
            ) : (
              <div></div>
            )} */}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* {confirmation !== null ? (
        <div
          class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show"
          role="alert"
        >
          <button
            type="button"
            class="btn-close btn-close-dark"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ opacity: 1 }}
          ></button>
          <span style={{ fontWeight: "bold" }}>Confirmation Alert! : </span> you
          have {confirmation} awaiting confirmation.
        </div>
      ) : (
        <div></div>
      )}
      {prescription !== null ? (
        <div
          class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show"
          role="alert"
        >
          <button
            type="button"
            class="btn-close btn-close-dark"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ opacity: 1 }}
          ></button>
          <span style={{ fontWeight: "bold" }}>Prescription Alert! : </span> you
          have {prescription} pending prescription.
        </div>
      ) : (
        <div></div>
      )}
      {test !== null ? (
        <div
          class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show"
          role="alert"
        >
          <button
            type="button"
            class="btn-close btn-close-dark"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ opacity: 1 }}
          ></button>
          <span style={{ fontWeight: "bold" }}>Test Alert! : </span> you have{" "}
          {test} pending test.
        </div>
      ) : (
        <div></div>
      )} */}

      {noKYC ? (
        <div className="col-md-12">
          {/* <div class="my-background-image"></div>
                <div class="my-overlay"></div> */}
          <div className="card card-body text-dark">
            <h5>Upload KYC Documents</h5>
            <div className="row mt-4">
              <div className="col-md-8">
                <h3>Welcome to DOCROA! We're excited to have you here.</h3>

                <p style={{ fontSize: "16px" }}>
                  To ensure the security and compliance of our services, we
                  kindly request you to complete your account setup by uploading
                  your KYC documents. KYC (Know Your Customer) verification
                  helps us verify your identity and maintain a trusted
                  community.
                </p>
                <strong style={{ fontSize: "16px" }}>
                  NOTE: You will be allowed to explore the platform for a
                  limited period and features will be disabled until your KYC
                  documents are uploaded.
                </strong>
                <div class="mt-3">
                  <label for="formFileMultiple" class="form-label">
                    Multiple files input
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    id="formFileMultiple"
                    multiple
                    onChange={(event) => {
                      setFiles(event.target.files);
                    }}
                  />
                </div>
                <button
                  disabled={isLoadingUpload}
                  type="button"
                  class="btn btn-success waves-effect waves-light mt-3"
                  onClick={handleFileUpload}
                >
                  <span class="btn-label">
                    {isLoadingUpload ? (
                      <i class="mdi mdi-spin mdi-loading"></i>
                    ) : (
                      <i class="mdi mdi-upload"></i>
                    )}
                  </span>
                  Upload
                </button>
              </div>
              <div className="col-md-4 text-center">
                <img src="assets/images/kyc.png" style={{ height: "250px" }} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {kYCpending ? (
        <div className="col-md-12">
          {/* <div class="my-background-image"></div>
                <div class="my-overlay"></div> */}
          <div className="card card-body text-dark">
            <h5>Upload KYC Documents</h5>
            <div className="row mt-4">
              <div className="col-md-8">
                <h3>Welcome to DOCROA! We're excited to have you here.</h3>

                <p style={{ fontSize: "16px" }}>
                  To ensure the security and compliance of our services, we
                  kindly request you to complete your account setup by uploading
                  your KYC documents. KYC (Know Your Customer) verification
                  helps us verify your identity and maintain a trusted
                  community.
                </p>
                <strong style={{ fontSize: "16px" }}>
                  NOTE: You will be allowed to explore the platform for a
                  limited period and features will be disabled until your KYC
                  documents are uploaded.
                </strong>
                <div
                  class="alert alert-info alert-dismissible bg-info text-white border-0 fade show  mt-4"
                  role="alert"
                >
                  <span style={{ fontWeight: "bold" }}>
                    KYC Status Pending! :{" "}
                  </span>{" "}
                  you KYC status is being verified. You will be notified when
                  your Organization is Verified.
                </div>
              </div>
              <div className="col-md-4 text-center">
                <img src="assets/images/kyc.png" style={{ height: "250px" }} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
