import React, { useEffect, useState } from "react";
import {
  changeTitle,
  convertFromISODate,
  getUser,
} from "../../functions/reusables";
import axios from "axios";
import Notification from "../../component/notification";

export default function DoctorDashboard() {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(true);
  const [consTable, setConsTable] = useState([]);
  const [testTable, setTestTable] = useState([]);

  const getDashboardCards = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/dashboard/cards/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}/`,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);
      setCards(response.data.data);
    } catch (error) {}
  };
  const getDashboardConsultationTable = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/dashboard/cons_table/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}/`,
        {
          headers: headers,
        }
      );
      setConsTable(response.data.data ?? []);
    } catch (error) {}
  };
  const getDashboardTestTable = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/dashboard/tests_table/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}/`,
        {
          headers: headers,
        }
      );
      setTestTable(response.data.data ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    changeTitle("Dashboard | DOC-ROA");
    getDashboardCards();
    getDashboardConsultationTable();
    getDashboardTestTable();
    // localStorage.clear()
  }, []);
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="page-title-main">Dashboard</h4>
                </div>
              </div>
              <div className="row">
                <Notification />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown float-end">
                    <button
                      class="btn btn-link waves-effect"
                      style={{ padding: "0px" }}
                    >
                      view
                    </button>
                  </div>

                  <h4 class="header-title mt-0 mb-4">Consultations</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-danger mdi mdi-stethoscope"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.consultations}{" "}
                      </h2>
                      <p class="text-muted mb-1">Total Consultations</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Referals</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-success mdi mdi-account-arrow-left-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1"> {cards.refers} </h2>
                      <p class="text-muted mb-1">Total Referals</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Tests</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-info mdi mdi-test-tube"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1"> {cards.tests} </h2>
                      <p class="text-muted mb-1">Total Tests</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-4">Prescriptions</h4>

                  <div class="widget-chart-1">
                    <div class="widget-chart-box-1 float-start" dir="ltr">
                      <div
                        style={{
                          display: "inline",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <i
                          class="text-warning mdi mdi-note-text-outline"
                          style={{ fontSize: "50px" }}
                        ></i>
                      </div>
                    </div>

                    <div class="widget-detail-1 text-end">
                      <h2 class="fw-normal pt-2 mb-1">
                        {" "}
                        {cards.prescriptions}{" "}
                      </h2>
                      <p class="text-muted mb-1">Total Prescriptions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="dropdown float-end"></div>
                  <h4 className="mt-0 header-title">Consultation</h4>
                  <p className="text-muted font-14 mb-3">
                    Latest Consultations.
                  </p>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {consTable.map((data) => (
                          <tr>
                            <th scope="row">{data.pat_name}</th>
                            <td>{convertFromISODate(data.cons_date)}</td>
                            <td>{data.start_time}</td>
                            <td>{data.end_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="dropdown float-end"></div>
                  <h4 className="mt-0 header-title">Tests</h4>
                  <p className="text-muted font-14 mb-3">Last 5 Tests.</p>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="bg-info text-white">
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testTable.map((data) => (
                          <tr>
                            <th scope="row">{data.pat_name}</th>
                            <td>{convertFromISODate(data.lab_date)}</td>
                            <td>
                              {data.status == 0 ? (
                                <span class="badge bg-danger">Not Done</span>
                              ) : (
                                <span class="badge bg-success">Done</span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
