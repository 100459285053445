import React, { useEffect, useRef, useState } from "react";
import {
  base64ToFile,
  changeTitle,
  convertFromISODate,
  errorToast,
  getUser,
  infoToast,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "react-loading-screen";
import Webcam from "react-webcam";
import Notification from "../../component/notification";
import { useLocation, useNavigate } from "react-router-dom";

export default function OrganizationEditOrgUser() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isCapturing, setIsCapturing] = useState(false);
  const [hasCaptured, setHasCaptured] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [orguser_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orguser_phone, setPhone] = useState("");
  const [orguser_address, setAddress] = useState("");
  const [orguser_state, setState] = useState("");
  const [dept_pid, setDeptPid] = useState(null);
  const fileInputRef = useRef(null);

  const resetForm = () => {
    setIsCapturing(false);
    setHasCaptured(false);
    setCapturedImage(null);
    setSelectedImageFile(null);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setState("");
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    // setSe
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      console.log(reader.result);
      setCapturedImage(reader.result);
      setSelectedImageFile(reader.result);
      setHasCaptured(true);
    };
  };

  const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user",
  };

  // const getDepartmentTable = async () => {
  //     try {
  //         const headers = {
  //             "Content-Type": "application/json",
  //             "Authorization": "Bearer "+getUser()["token"],
  //           };
  //           const response = await axios.get(
  //             `${process.env.REACT_APP_MAIN}med_depts/all/`,
  //             {
  //               headers: headers,
  //             }
  //           );
  //           console.log(response.data.data)
  //           setDept(response.data.data)
  //     } catch (error) {
  //         console.log(error)
  //         setTimeout(()=>{errorToast("Error loading Departments")}, 1000);
  //     }
  // }
  const getOrgUser = async () => {
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get("orguser_pid");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const data = {
      org_pid: getUser()["org_pid"],
      orguser_pid: parseInt(search),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}org_users/one/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      setSelectedImageFile(response.data.data.photo);
      setCapturedImage(response.data.data.photo);
      setHasCaptured(response.data.data.photo == "" ? false : true);
      setName(response.data.data.orguser_name);
      setEmail(response.data.data.email);
      setPhone(response.data.data.orguser_phone);
      setAddress(response.data.data.orguser_address);
      setState(response.data.data.orguser_state);
      setLoading(false);
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  const updateOrgUser = async () => {
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get("orguser_pid");
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };

    const data = {
      org_pid: getUser()["org_pid"],
      orguser_name,
      email,
      orguser_phone,
      orguser_address,
      orguser_state,
      orguser_pid: parseInt(search),
    };

    console.log(data);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}org_users/update/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Organization User Updated Successfully");
      setLoading(false);
      // if (capturedImage == null || capturedImage == "") {
      //   successToast("Organization User Updated Successfully");
      //   // navigate("/organization-manage-organization-user");
      //   setLoading(false);
      //   return;
      // }
      // uploadPhoto(response.data["user_id"]);
    } catch (error) {
      console.log(error);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
    // uploadPhoto(1)
  };

  const uploadPhoto = async (orguser_pid) => {
    infoToast("Uploading Photo");

    const fileName = orguser_pid.toString(); // Or 'image.png' for PNG format
    const imageFile = base64ToFile(selectedImageFile, fileName);

    const formData = new FormData();
    console.log(imageFile);
    formData.append("photo", imageFile);
    formData.append("orguser_pid", orguser_pid);
    formData.append("org_pid", getUser()["org_pid"]);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}org_users/update_photo/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for multipart/form-data
            Authorization: "Bearer " + getUser()["token"],
          },
        }
      );
      successToast("Organization User Updated & Photo Uploaded Successfully");
      setLoading(false);
      resetForm();
    } catch (error) {
      console.error("Failed to upload image:", error);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    changeTitle("Edit Organization User | DOC-ROA");
    getOrgUser();
  }, []);

  return loading ? (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#9ee5f8"
        textColor="#77d5cc"
        logoSrc="/assets/images/logo-dark.png"
        text="..."
      ></LoadingScreen>
      <ToastContainer />
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div class="card-body">
                  <div className="col-12 mb-4">
                    <button
                      type="button"
                      class="btn btn-light waves-effect waves-light"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <span class="btn-label">
                        <i class="fe-arrow-left"></i>
                      </span>
                      Go Back
                    </button>
                  </div>
                  <h4 class="header-title">Edit Organization User</h4>
                  <p class="text-muted font-13">Edit Organization User</p>
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        <h5 className="mb-2 mt-4">Capture Photo</h5>
                        {isCapturing ? (
                          <Webcam
                            audio={false}
                            height={300}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                          >
                            {({ getScreenshot }) => (
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {/* <button
                                  type="button"
                                  class="btn btn-success waves-effect waves-light"
                                  onClick={() => {
                                    const imageSrc = getScreenshot();
                                    setHasCaptured(true);
                                    setIsCapturing(false);
                                    setCapturedImage(imageSrc);
                                    setSelectedImageFile(imageSrc);
                                  }}
                                >
                                  <span class="btn-label">
                                    <i class="fe-camera"></i>
                                  </span>
                                  Capture photo
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-danger waves-effect waves-light"
                                  onClick={() => {
                                    setIsCapturing(false);
                                  }}
                                >
                                  <i class="mdi mdi-close"></i>
                                </button> */}
                              </div>
                            )}
                          </Webcam>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#dedfdf",
                              width: "100%",
                              height: "300px",
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {hasCaptured ? (
                              <img
                                src={capturedImage}
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              <i
                                class="mdi mdi-face-recognition"
                                style={{
                                  textAlign: "center",
                                  fontSize: "150px",
                                  color: "white",
                                }}
                              ></i>
                            )}
                          </div>
                        )}
                        {/* {!isCapturing ? (
                          <div
                            className="d-flex mt-2"
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <button
                              type="file"
                              class="btn btn-info waves-effect waves-light btn-xs"
                              onClick={handleButtonClick}
                            >
                              Upload Photo
                            </button>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileSelect}
                            />
                            <p>or</p>
                            <button
                              type="button"
                              class={`btn btn-${
                                isCapturing ? "danger" : "dark"
                              } waves-effect waves-light btn-xs`}
                              onClick={() => {
                                setIsCapturing(isCapturing ? false : true);
                              }}
                            >
                              {isCapturing ? "Stop Capture" : "Camera Capture"}
                            </button>
                          </div>
                        ) : (
                          <div></div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mt-4">
                        <div class="row">
                          <div class="col-md-5 mb-3">
                            <label for="name" class="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              value={orguser_name}
                              placeholder="Enter Name"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>
                          <div class="col-md-4 mb-3">
                            <label for="pass" class="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              value={email}
                              id="pass"
                              placeholder="Enter Email"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-md-4">
                            <label for="phoneno" class="form-label">
                              Phone
                            </label>
                            <input
                              type="phone"
                              class="form-control"
                              value={orguser_phone}
                              id="phoneno"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                            />
                          </div>

                          <div class="mb-3 col-md-4">
                            <label for="inputState" class="form-label">
                              State
                            </label>
                            <select
                              id="inputState"
                              class="form-select"
                              value={orguser_state}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            >
                              <option value="">Select State</option>
                              <option value="Abia">Abia</option>
                              <option value="Adamawa">Adamawa</option>
                              <option value="Akwa Ibom">Akwa Ibom</option>
                              <option value="Anambra">Anambra</option>
                              <option value="Bauchi">Bauchi</option>
                              <option value="Bayelsa">Bayelsa</option>
                              <option value="Benue">Benue</option>
                              <option value="Borno">Borno</option>
                              <option value="Cross River">Cross River</option>
                              <option value="Delta">Delta</option>
                              <option value="Ebonyi">Ebonyi</option>
                              <option value="Edo">Edo</option>
                              <option value="Ekiti">Ekiti</option>
                              <option value="Enugu">Enugu</option>
                              <option value="FCT">FCT</option>
                              <option value="Gombe">Gombe</option>
                              <option value="Imo">Imo</option>
                              <option value="Jigawa">Jigawa</option>
                              <option value="Kaduna">Kaduna</option>
                              <option value="Kano">Kano</option>
                              <option value="Katsina">Katsina</option>
                              <option value="Kebbi">Kebbi</option>
                              <option value="Kogi">Kogi</option>
                              <option value="Kwara">Kwara</option>
                              <option value="Lagos">Lagos</option>
                              <option value="Nasarawa">Nasarawa</option>
                              <option value="Niger">Niger</option>
                              <option value="Ogun">Ogun</option>
                              <option value="Ondo">Ondo</option>
                              <option value="Osun">Osun</option>
                              <option value="Oyo">Oyo</option>
                              <option value="Plateau">Plateau</option>
                              <option value="Rivers">Rivers</option>
                              <option value="Sokoto">Sokoto</option>
                              <option value="Taraba">Taraba</option>
                              <option value="Yobe">Yobe</option>
                              <option value="Zamfara">Zamfara</option>
                            </select>
                          </div>
                        </div>

                        <div class="mb-3">
                          <label for="inputAddress" class="form-label">
                            Address
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            value={orguser_address}
                            placeholder="1234 Main St"
                            rows="6"
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          ></textarea>
                        </div>

                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            updateOrgUser();
                          }}
                        >
                          Update Organization User
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
