import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  errorToast,
  getUser,
  successToast,
} from "../../functions/reusables";
import LoadingScreen from "react-loading-screen";
import ImageUploader from "react-images-upload";
import SchedulerCalendar from "scheduler-calendar";
import "scheduler-calendar/dist/index.css";
import ReactDOMServer from "react-dom/server";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Notification from "../../component/notification";
import VerticalCalender from "../../component/vert-calender";
import TimeSlotsComponent from "../../component/time-slots";
import moment from "moment";

export default function DoctorBookAppointment() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [imageFiles, setPictures] = useState([]);
  const [isTimeSlotLoading, setIsTimeSlotLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [bookedSlots, setBookedSlots] = useState([]);
  const [slotComponentStartTime, setslotComponentStartTime] = useState("");
  const [slotComponentEndTime, setslotComponentEndTime] = useState("");
  const [slotStartTime, setslotStartTime] = useState("");
  const [slotEndTime, setslotEndTime] = useState("");
  const [slotIndex, setslotIndex] = useState(null);
  const [pat_pid, setPatPid] = useState("");
  const [problem, setProblem] = useState("");
  const [tab, setTab] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [scheduleData, setScheduleData] = useState({});
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const toggleDictate = (isDictating) => {
    console.log(isDictating);

    if (!isDictating) {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.stopListening();
      let newText = problem == "" ? transcript : problem + transcript;

      setProblem(newText);
      resetTranscript();
    }
  };

  const performTasks = async () => {
    setLoading(true);
    try {
      await getPatients();
      await fetchSchedule();
    } catch (error) {
      setLoading(false);
      errorToast(error?.message);
    }
  };
  const getTextFromEditor = (content, delta, source, editor) => {
    console.log(editor.getText(content));
    setProblem(content);
  };

  const getPatients = async () => {
    try {
      // setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };

      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/list_all/`,
        {
          headers: headers,
        }
      );
      let unsetOptions = response.data.data;
      let newOptions = [];
      for (let index = 0; index < unsetOptions.length; index++) {
        const patient = {
          value: unsetOptions[index]["pat_pid"],
          label: unsetOptions[index]["pat_name"],
        };
        newOptions.push(patient);
      }
      setOptions(newOptions);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
    }
  };

  const selectPatient = (value) => {
    console.log(value);
    if (value == null) {
      setPatPid("");
      return;
    }
    setPatPid(value["value"]);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setPictures(pictureFiles);
  };

  const getSchedule = async (date) => {
    try {
      setIsTimeSlotLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const data = {
        doc_pid: getUser()["user_pid"],
        org_pid: getUser()["org_pid"],
        cons_date: date,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}cons/prev_doctor/`,
        data,
        {
          headers: headers,
        }
      );

      let res = response.data.data ?? [];
      console.log();
      setIsTimeSlotLoading(false);
      setBookedSlots(res);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
      setIsTimeSlotLoading(false);
    }
  };

  const buildEvent = (appointment) => {
    let event = {
      day: appointment.cons_date.slice(0, 10),
      slots: [
        {
          from: convertFrom12To24Format(appointment.start_time),
          to: convertFrom12To24Format(appointment.start_time),
        },
      ],
      comment: appointment.pat_name,
    };
    return event;
  };

  const fetchSchedule = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/schedule/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      const resData = {
        doc_pid: response.data.data.doc_pid,
        org_pid: response.data.data.org_pid,
        availability: response.data.data.availability,
        astatus1: response.data.data.astatus1,
        astatus2: response.data.data.astatus2,
        mon1: response.data.data.mon1,
        mon2: response.data.data.mon2,
        tue1: response.data.data.tue1,
        tue2: response.data.data.tue2,
        wed1: response.data.data.wed1,
        wed2: response.data.data.wed2,
        thur1: response.data.data.thur1,
        thur2: response.data.data.thur2,
        fri1: response.data.data.fri1,
        fri2: response.data.data.fri2,
        sat1: response.data.data.sat1,
        sat2: response.data.data.sat2,
        sun1: response.data.data.sun1,
        sun2: response.data.data.sun2,
        duration: response.data.data.duration,
      };
      setScheduleData(resData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const refreshCanlendar = () => {
    setCalendarLoading(true);
    setTimeout(() => {
      setCalendarLoading(false);
      // setAvailabilitiesEdited(availabilitiesEdited)
    }, 10);
  };

  const handleFormSubmit = async (event) => {
    // event.preventDefault();

    const formData = new FormData();

    if (imageFiles.length !== 0) {
      // Append the image file to the FormData object
      for (let index = 0; index < imageFiles.length; index++) {
        formData.append("problem_image", imageFiles[index]);
      }
    } else {
      formData.append("problem_image", "");
    }

    if (pat_pid === "" || pat_pid === null) {
      errorToast("Please select a patient");
      return;
    }
    if (slotStartTime === "" || slotEndTime === "") {
      errorToast("Please select a time slot before submitting");
      return;
    }
    if (problem === "" || problem === null) {
      errorToast("Please add a problem before submitting");
      return;
    }

    // Append the form data to the FormData object

    formData.append("doc_pid", getUser()["user_pid"]);
    formData.append("org_pid", getUser()["org_pid"]);
    formData.append("pat_pid", pat_pid);
    formData.append("cons_date", selectedDay);
    formData.append("start_time", slotStartTime);
    formData.append("end_time", slotEndTime);
    formData.append("problem", problem);

    const formDataEntries = formData.entries();

    // Loop through the iterator and console.log() the contents
    for (const [key, value] of formDataEntries) {
      console.log(`${key}: ${value}`);
    }

    try {
      setLoading(true);
      // Send the POST request with Axios
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}cons/create/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for multipart/form-data
            Authorization: "Bearer " + getUser()["token"],
          },
        }
      );

      console.log("Image uploaded successfully");
      successToast("Appointment Booked Successfully");
      setTab(3);
      setLoading(false);
    } catch (error) {
      // Error
      console.error("Failed to upload image:", error);
      errorToast("Failed: ", error);
      setLoading(false);
    }
  };

  const changeTabNext = () => {
    if (tab === 2) {
      return handleFormSubmit();
    }
    setTab(tab + 1);
  };
  const changeTabPrev = () => {
    if (tab === 0) {
      return;
    }
    setTab(tab - 1);
  };

  const handleDictate = (value) => {
    console.log(value);
  };
  const handleProgress = (value) => {
    // console.log(value)
  };

  const handleCalClick = (date, index) => {
    setSelectedIndex(index);
    const selectedDate = moment(date, "dddd, Do MMMM YYYY");
    const dayOfWeek = selectedDate.format("dddd");
    const YYYYMMDD = selectedDate.format("YYYY-MM-DD");
    setSelectedDay(YYYYMMDD);

    let dayKey;
    if (dayOfWeek === "Thursday") {
      dayKey = "thur";
    } else {
      dayKey = dayOfWeek.slice(0, 3).toLowerCase();
    }

    const day1 = scheduleData[`${dayKey}1`];
    const day2 = scheduleData[`${dayKey}2`];
    setslotComponentStartTime(day1);
    setslotComponentEndTime(day2);
    setslotIndex(null);
    getSchedule(YYYYMMDD);
    console.log({ day1, day2 });
  };

  const handleSlotTimeSelect = (slot, index) => {
    console.log(slot);
    setslotIndex(index);
    setslotStartTime(slot[0]);
    setslotEndTime(slot[1]);
  };

  useEffect(() => {
    changeTitle("Book Appointment | DOC-ROA");
    performTasks();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : scheduleData.availability == 0 ? (
    <div>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <Notification />
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4 className="header-title mb-3">DOCTOR UNAVAILABLE</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card" style={{ backgroundColor: "transparent" }}>
                <div className="card-header mb-3">
                  <h4 className="header-title mb-3">BOOK AN APPOINTMENT</h4>
                  <ul className="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
                    <li className="nav-item">
                      <a
                        href="#patient-tab"
                        className={
                          tab === 1
                            ? "nav-link rounded-0 pt-2 pb-2 active"
                            : "nav-link rounded-0 pt-2 pb-2"
                        }
                        onClick={() => {
                          // setTab(1);
                        }}
                      >
                        <i className="mdi mdi-account-circle me-1"></i>
                        <span className="d-none d-sm-inline">
                          Patient Detail
                        </span>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#calendar-tab"
                        className={
                          tab === 2
                            ? "nav-link rounded-0 pt-2 pb-2 active"
                            : "nav-link rounded-0 pt-2 pb-2"
                        }
                        onClick={() => {
                          // setTab(2);
                        }}
                      >
                        <i className="mdi mdi-face-profile me-1"></i>
                        <span className="d-none d-sm-inline">
                          Appointment Date
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#finish-2"
                        className={
                          tab === 3
                            ? "nav-link rounded-0 pt-2 pb-2 active"
                            : "nav-link rounded-0 pt-2 pb-2"
                        }
                        onClick={() => {}}
                      >
                        <i className="mdi mdi-checkbox-marked-circle-outline me-1"></i>
                        <span className="d-none d-sm-inline">Finish</span>
                      </a>
                    </li>
                  </ul>
                  <div
                    id="bar"
                    className="progress mb-3"
                    style={{ height: "7px" }}
                  >
                    <div
                      className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
                      style={{
                        width:
                          tab === 1
                            ? "33%"
                            : tab === 2
                            ? "66%"
                            : tab === 3
                            ? "100%"
                            : "33%",
                      }}
                    ></div>
                  </div>
                  {tab !== 3 ? (
                    <ul className="list-inline mb-0 wizard">
                      <li className="previous list-inline-item">
                        <a
                          href="javascript: void(0);"
                          onClick={() => {
                            if (tab == 1) return;
                            changeTabPrev();
                          }}
                          className="btn btn-secondary"
                        >
                          Previous
                        </a>
                      </li>
                      {
                        <li className="next list-inline-item float-end disabled">
                          <a
                            href="javascript: void(0);"
                            onClick={() => {
                              changeTabNext();
                            }}
                            className={`btn ${
                              tab == 2 ? "btn-primary" : "btn-secondary"
                            } `}
                          >
                            {tab == 2 ? "Book Appointment" : "Next"}
                          </a>
                        </li>
                      }
                    </ul>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="">
                  {/* <form> */}
                  <div id="progressbarwizard">
                    <div className="tab-content b-0 mb-0 pt-0">
                      <div
                        className={tab === 1 ? "tab-pane active" : "tab-pane"}
                        id="patient-tab"
                      >
                        <div className="row">
                          <div className="col-xl-8">
                            <div className="mb-3 card card-body">
                              <h5>Select Patient</h5>

                              <Select
                                options={options}
                                isSearchable={true}
                                isClearable={true}
                                onChange={selectPatient}
                              />
                            </div>

                            <div className="card card-body">
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <h5>Describe Problem</h5>
                                <button
                                  type="button"
                                  class={`btn btn-${
                                    listening ? "danger" : "info"
                                  } waves-effect waves-light float-end mt-1`}
                                  onClick={() => {
                                    toggleDictate(listening);
                                  }}
                                >
                                  <span class="btn-label ">
                                    <i class="dripicons-microphone"></i>
                                  </span>
                                  {listening ? "Dictating..." : "Dictate"}
                                </button>
                              </div>
                              <ReactQuill
                                theme="snow"
                                value={problem}
                                onChange={getTextFromEditor}
                                placeholder="Describe a problem"
                              />
                            </div>
                          </div>
                          <div className="col-xl-4">
                            <div className="card card-body">
                              <h5>Upload Addition Image</h5>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose image"
                                onChange={onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                withPreview={true}
                                singleImage={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={tab === 2 ? "tab-pane active" : "tab-pane"}
                        id="calendar-tab"
                      >
                        <div className="row">
                          <div className="col-md-5">
                            <div className="card">
                              <div className="card-body">
                                {calendarLoading ? (
                                  <div></div>
                                ) : (
                                  <div>
                                    <h5>Choose a Date</h5>
                                    <VerticalCalender
                                      onClick={handleCalClick}
                                      buttonIndex={selectedIndex}
                                    />
                                  </div>
                                )}

                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div
                              className="card"
                              style={{ minHeight: "510px" }}
                            >
                              <div className="card-body">
                                <h5>Choose Time Slot</h5>
                                {isTimeSlotLoading ? (
                                  <div
                                    className="d-flex"
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "50px",
                                    }}
                                  >
                                    <i className="mdi mdi-loading mdi-spin"></i>
                                  </div>
                                ) : (
                                  <TimeSlotsComponent
                                    startTime={slotComponentStartTime}
                                    endTime={slotComponentEndTime}
                                    duration={scheduleData.duration}
                                    slotIndex={slotIndex}
                                    onClick={handleSlotTimeSelect}
                                    bookedSlots={bookedSlots}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={tab === 3 ? "tab-pane active" : "tab-pane"}
                        id="finish-2"
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="text-center">
                              <h2 className="mt-0">
                                <i className="mdi mdi-check-all"></i>
                              </h2>
                              <h3 className="mt-0">Created Successfully!</h3>

                              <p className="w-75 mb-2 mx-auto">
                                The Appointment has been created Successfully.
                                An Email will be sent to both parties to that
                                Effect.
                              </p>

                              <div className="mb-3">
                                <div className="form-check d-inline-block">
                                  <a
                                    href="javascript: void(0);"
                                    onClick={() => {
                                      setProblem("");
                                      setTab(1);
                                      setBookedSlots([]);
                                      setslotComponentEndTime("");
                                      setslotComponentStartTime("");
                                      setslotEndTime("");
                                      setslotStartTime("");
                                      setslotIndex(null);
                                    }}
                                    className="btn btn-secondary"
                                  >
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
