import { toast } from "react-toastify";

export const changeTitle = (title) => {
  window.document.title = title;
};

export const getUser = () => {
  let user = JSON.parse(localStorage.getItem("user_detail"));
  return user;
};

export const getLoggedInRole = () => {
  let userRole = localStorage.getItem("user_role");
  // let userRole = process.env.REACT_APP_ROLE;
  return userRole;
};

export const checkIfLogin = async () => {
  let user = getUser();

  // if (user !== null) {
  //   let isUser = await getUserProfile();
  if (user) {
    return true;
  } else {
    return false;
  }
  // } else {
  //   return false;
  // }
};

export const clearStorage = () => {
  localStorage.clear();
};

export const fixDropdownName = (name) => {
  let fixedString = "";
  fixedString = name;
  fixedString = fixedString.replace("_", " ");
  return fixedString.toUpperCase();
};
export const reverseFixDropdownName = (name) => {
  let fixedString = "";
  fixedString = name;
  fixedString = fixedString.replace(" ", "_");
  return fixedString.toLowerCase();
};

export const errorToast = (errorMessage) => {
  setTimeout(() => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }, 200);
};
export const errorToastStay = (errorMessage) => {
  setTimeout(() => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }, 200);
};
export const successToast = (successMessage) => {
  setTimeout(() => {
    toast.success(successMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }, 200);
};
export const infoToast = (infoMessage) => {
  setTimeout(() => {
    toast.info(infoMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }, 200);
};

export const convertFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? "AM" : "PM";
  const hours = String(+sHours % 12 || 12).padStart(2, "0");

  return `${hours}:${minutes} ${period}`;
};

export const convertFrom12To24Format = (time12) => {
  const [sHours, minutes, period] = time12
    .match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
    .slice(1);
  const PM = period === "PM";
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${("0" + hours).slice(-2)}:${minutes}`;
};

export const convertFromISODate = (isoDate) => {
  // Create a Date object from the input date
  const date = new Date(isoDate);
  // Extract the date portion (YYYY-MM-DD) from the Date object
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate; // Output: "2023-04-12"
};
export const convertToHumanReadableDate = (dateString) => {
  // Create a Date object from the input date string
  var date = new Date(dateString);

  // Define an array of month names
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract the day, month, and year from the Date object
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  // Format the date in "dd Month yyyy" format
  var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

  return formattedDate;
};

// Convert base64 image to Blob
function base64ToBlob(base64Image) {
  console.log(base64Image);
  const splitData = base64Image.split(",");
  console.log(splitData);
  const mimeType = splitData[0].match(/:(.*?);/)[1];
  const byteString = window.atob(splitData[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeType });
}

// Convert Blob to File
function blobToFile(theBlob, fileName) {
  // const b = theBlob;
  // b.lastModifiedDate = new Date();
  // b.name = fileName;
  // return theBlob;
  const file = new File([theBlob], fileName + "_image.png", {
    type: "image/png",
  });
  return file;
}

// Convert base64 image to File
export const base64ToFile = (base64Image, fileName) => {
  const blob = base64ToBlob(base64Image);
  const file = blobToFile(blob, fileName);
  return file;
};

export const getTimeFromDate = (dateString) => {
  const date = new Date(dateString);

  // Extracting the time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Creating a formatted time string
  const timeString = `${hours}:${minutes}:${seconds}`;
  return timeString;
};
