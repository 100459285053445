import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  errorToast,
  getUser,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import Switch from "react-switch";

export default function DoctorSchedule() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [availStartTime, setAvailStartTime] = useState("09:00 AM");
  const [availEndTime, setAvailEndTime] = useState("05:00 PM");

  const handleAvailabilityChange = (nextChecked) => {
    console.log(nextChecked);
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData.availability = nextChecked ? 1 : 0; // Assign the value to the availability property
      updateSchedule(newData);
      return newData;
    });
  };

  const handleDefinedChange = (nextChecked) => {
    console.log(nextChecked);
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData.astatus1 = !nextChecked ? "Open" : "";
      newData.astatus2 = !nextChecked ? "Open" : "";
      return newData;
    });
    if (nextChecked) {
      initializeDateAvailability();
    }
  };

  const handleDefinedDateChange = (date, id) => {
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData[id] = date;
      return newData;
    });
  };

  const handleDurationChange = (duration) => {
    console.log(duration);
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData.duration = duration;
      return newData;
    });
  };

  const handleDayTimeChange = (time, id) => {
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData[id] = time;
      return newData;
    });
  };

  const handleOverallStartTime = (startTime) => {
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData["mon1"] = startTime;
      newData["tue1"] = startTime;
      newData["wed1"] = startTime;
      newData["thur1"] = startTime;
      newData["fri1"] = startTime;
      newData["sat1"] = startTime;
      newData["sun1"] = startTime;
      return newData;
    });
  };
  const handleOverallEndTime = (endTime) => {
    setData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData["mon2"] = endTime;
      newData["tue2"] = endTime;
      newData["wed2"] = endTime;
      newData["thur2"] = endTime;
      newData["fri2"] = endTime;
      newData["sat2"] = endTime;
      newData["sun2"] = endTime;
      return newData;
    });
  };

  const fetchSchedule = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/schedule/${
          getUser()["user_pid"]
        }/${getUser()["org_pid"]}`,
        {
          headers: headers,
        }
      );
      const resData = {
        doc_pid: response.data.data.doc_pid,
        org_pid: response.data.data.org_pid,
        availability: response.data.data.availability,
        astatus1: response.data.data.astatus1,
        astatus2: response.data.data.astatus2,
        mon1: response.data.data.mon1,
        mon2: response.data.data.mon2,
        tue1: response.data.data.tue1,
        tue2: response.data.data.tue2,
        wed1: response.data.data.wed1,
        wed2: response.data.data.wed2,
        thur1: response.data.data.thur1,
        thur2: response.data.data.thur2,
        fri1: response.data.data.fri1,
        fri2: response.data.data.fri2,
        sat1: response.data.data.sat1,
        sat2: response.data.data.sat2,
        sun1: response.data.data.sun1,
        sun2: response.data.data.sun2,
        duration: response.data.data.duration,
      };
      setData(resData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const submitAvailability = () => {
    if (data.astatus1 === "" || data.astatus2 === "") {
      errorToast("Start Date and End Date Cannot be Empty");
      return;
    }
    updateSchedule(data);
  };

  const initializeDateAvailability = () => {
    const currentDate = new Date();
    const endDate = new Date();
    endDate.setDate(currentDate.getDate() + 7);

    const formattedStartDate = convertFromISODate(currentDate);
    const formattedEndDate = convertFromISODate(endDate);

    setData((prevData) => ({
      ...prevData,
      astatus1: formattedStartDate,
      astatus2: formattedEndDate,
    }));
  };

  const updateSchedule = async (data) => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    console.log(data);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}doctors/schedule/update/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Schedule Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    changeTitle("Schedule | DOC-ROA");
    fetchSchedule();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card card-body">
                <h4 className="header-title mt-0 mb-0">Schedule</h4>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-2">Availability</h4>
                      <label
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span>
                          {data.availability ? "AVAILABLE" : "UNAVAILABLE"}
                        </span>
                        <Switch
                          onChange={handleAvailabilityChange}
                          checked={data.availability == 1 ? true : false}
                          className="react-switch"
                          onColor="#77d5cc"
                          offColor="#ff5b5b"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="card card-body">
                    <h4 className="header-title mt-0 mb-2">
                      Date Availability
                    </h4>
                    <label className="mb-2">Availability Mode</label>
                    <label
                      className="d-flex mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <Switch
                        disabled={data.availability == 0 ? true : false}
                        onChange={handleDefinedChange}
                        checked={data.astatus1 === "Open" ? false : true}
                        className="react-switch"
                        onColor="#f9c851"
                        offColor="#77d5cc"
                        uncheckedIcon={<div></div>}
                        checkedIcon={<div></div>}
                      />
                      <span>
                        {data.astatus1 !== "Open" ? "DEFINED" : "OPEN"}
                      </span>
                    </label>
                    {data.astatus1 !== "Open" ? (
                      <div>
                        <div className="mb-1">
                          <label for="example-date" className="form-label">
                            Start Date
                          </label>
                          <input
                            className="form-control"
                            id="example-date"
                            type="date"
                            name="astatus1"
                            value={data.astatus1}
                            onChange={(e) => {
                              handleDefinedDateChange(
                                e.target.value,
                                e.target.name
                              );
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label for="example-date" className="form-label">
                            End Date
                          </label>
                          <input
                            className="form-control"
                            id="example-date"
                            type="date"
                            name="astatus2"
                            value={data.astatus2}
                            onChange={(e) => {
                              handleDefinedDateChange(
                                e.target.value,
                                e.target.name
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mt-4"
                          disabled={data.availability == 0 ? true : false}
                          onClick={submitAvailability}
                        >
                          <span className="btn-label">
                            <i className="mdi mdi-check-all"></i>
                          </span>
                          Submit Availability
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card card-body">
                    <h4 className="header-title mt-0 mb-2">
                      Appointment Settings
                    </h4>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label for="example-select" className="form-label">
                            Appointment Duration
                          </label>
                          <select
                            className="form-select"
                            id="example-select"
                            value={data.duration.toString()}
                            onChange={(e) => {
                              handleDurationChange(parseInt(e.target.value));
                            }}
                          >
                            <option value="15">15 min</option>
                            <option value="30">30 min</option>
                            <option value="60">60 min</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label for="example-time" className="form-label">
                            Available from
                          </label>
                          <input
                            className="form-control"
                            id="example-time"
                            type="time"
                            name="time"
                            value={convertFrom12To24Format(availStartTime)}
                            onChange={(e) => {
                              setAvailStartTime(
                                convertFrom24To12Format(e.target.value)
                              );
                              handleOverallStartTime(
                                convertFrom24To12Format(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label for="example-time" className="form-label">
                            Available to
                          </label>
                          <input
                            className="form-control"
                            id="example-time"
                            type="time"
                            name="time"
                            value={convertFrom12To24Format(availEndTime)}
                            onChange={(e) => {
                              setAvailEndTime(
                                convertFrom24To12Format(e.target.value)
                              );
                              handleOverallEndTime(
                                convertFrom24To12Format(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion custom-accordion"
                      id="custom-accordion-one"
                    >
                      <div className="card mb-0">
                        <div className="card-header" id="headingNine">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset d-block collapsed"
                              data-bs-toggle="collapse"
                              href="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Monday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>

                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="mon1"
                                    value={convertFrom12To24Format(data.mon1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="mon2"
                                    value={convertFrom12To24Format(data.mon2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingFive">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset d-block collapsed"
                              data-bs-toggle="collapse"
                              href="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Tuesday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="tue1"
                                    value={convertFrom12To24Format(data.tue1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="tue2"
                                    value={convertFrom12To24Format(data.tue2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingSix">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset collapsed d-block"
                              data-bs-toggle="collapse"
                              href="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Wednesday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseSix"
                          className="collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="wed1"
                                    value={convertFrom12To24Format(data.wed1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="wed2"
                                    value={convertFrom12To24Format(data.wed2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingSeven">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset collapsed d-block"
                              data-bs-toggle="collapse"
                              href="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Thursday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseSeven"
                          className="collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="thur1"
                                    value={convertFrom12To24Format(data.thur1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="thur2"
                                    value={convertFrom12To24Format(data.thur2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingSeven">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset collapsed d-block"
                              data-bs-toggle="collapse"
                              href="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Friday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseEight"
                          className="collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="fr1"
                                    value={convertFrom12To24Format(data.fri1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="fri2"
                                    value={convertFrom12To24Format(data.fri2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingSeven">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset collapsed d-block"
                              data-bs-toggle="collapse"
                              href="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Saturday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseNine"
                          className="collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="sat1"
                                    value={convertFrom12To24Format(data.sat1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="sat2"
                                    value={convertFrom12To24Format(data.sat2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-0">
                        <div className="card-header" id="headingSeven">
                          <h5 className="m-0 position-relative">
                            <a
                              className="custom-accordion-title text-reset collapsed d-block"
                              data-bs-toggle="collapse"
                              href="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Sunday
                              <i className="mdi mdi-chevron-down accordion-arrow"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseTen"
                          className="collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#custom-accordion-one"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    Start Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="sun1"
                                    value={convertFrom12To24Format(data.sun1)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mb-3">
                                  <label
                                    for="example-time"
                                    className="form-label"
                                  >
                                    End Time
                                  </label>
                                  <input
                                    className="form-control"
                                    id="example-time"
                                    type="time"
                                    name="sun2"
                                    value={convertFrom12To24Format(data.sun2)}
                                    onChange={(e) => {
                                      handleDayTimeChange(
                                        convertFrom24To12Format(e.target.value),
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-4"
                        disabled={data.availability == 0 ? true : false}
                        onClick={() => {
                          updateSchedule(data);
                        }}
                      >
                        <span className="btn-label">
                          <i className="mdi mdi-check-all"></i>
                        </span>
                        Submit Timing
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
