import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import SignatureCanvas from "react-signature-canvas";
import TagInput from "react-taggables-input";
import "react-taggables-input/dist/tags.css";

export default function DoctorMyConsultationComplete() {
  const [isShowingReferAlert, setIsShowingReferAlert] = useState(false);
  const [isShowingpharmacyAlert, setIsShowingPharmacyAlert] = useState(false);
  const [isShowingLabAlert, setIsShowingLabAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [problem, setProblem] = useState("");
  const [referalNote, setReferalNote] = useState("");
  const [pharmNote, setPharmNote] = useState("");
  const [labNote, setLabNote] = useState("");
  const [data, setData] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [medications, setMedications] = useState([]);
  const [tests, setTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [orgId, setOrgID] = useState("null");
  const [docId, setDocID] = useState(null);
  const [docName, setDocName] = useState("");
  const [pharmId, setPharmID] = useState(null);
  const [pharmName, setPharmName] = useState("");
  const [labId, setLabID] = useState(null);
  const [labName, setLabName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [hasReferal, setHasReferal] = useState(false);
  const [hasPharmacy, setHasPharmacy] = useState(false);
  const [hasLab, setHasLab] = useState(false);
  const [sigCanvas, setSigCanvas] = useState(null);

  const [testList, setTestList] = useState([]);

  const [medicineList, setMedicineList] = useState([]);
  const [medDoseUnit, setMedDoseUnit] = useState([]);
  const [medRoutes, setMedRoutes] = useState([]);
  const [medFrequency, setMedFrequency] = useState([]);
  const [medDuration, setMedDuration] = useState([]);

  const [medicineFormData, setMedicineFormData] = useState({
    dose: "",
    duration: "",
    frequency: "",
    medicine: "",
    route: "",
    unit: "",
    comments: "",
  });
  const [testFormData, setTestFormData] = useState({
    pdiagnosis: "",
    test: "",
    instructions: "",
  });

  const [end_cons_pid, setEndConsPID] = useState(null);
  const [end_pat_pid, setEndPatPID] = useState(null);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const modules = {
    toolbar: [],
  };

  const formats = [[]];

  const columns = [
    {
      button: true,
      width: "45px",
      cell: (row) => (
        <div class="form-check mb-2">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="customradio1"
            onClick={() => {
              setSelectedDocId(row.doc_pid);
              setDocName(row.doc_name);
            }}
            checked={docId == row.doc_pid ? true : false}
          />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.doc_name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.doc_phone,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.doc_gender,
      sortable: true,
    },
    {
      name: "Dept.",
      selector: (row) => row.dept_name,
      sortable: true,
    },

    // {
    //   name: "Actions",
    //   button: true,
    //   width: "100px",
    //   cell: (row) => (
    //     <div>
    //       <button
    //         type="button"
    //         className="btn btn-success btn-icon waves-effect waves-light"
    //         onClick={() => {
    //           // viewConsultation(row)
    //           // updateConsultation(row.cons_pid,convertFromISODate(row.cons_date), row.start_time, row.end_time)
    //         }}
    //       >
    //         <i className="fe-eye"></i>
    //       </button>
    //     </div>
    //   ),
    // },
  ];
  const columnsPharm = [
    {
      name: "Medicine",
      selector: (row) => row.medicine,
      sortable: true,
    },
    {
      name: "Dose",
      selector: (row) => row.dose + " " + row.unit,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.duration,
      sortable: true,
    },
    {
      name: "Route",
      selector: (row) => row.route,
      sortable: true,
    },
    {
      name: "Frequency",
      selector: (row) => row.frequency,
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              if (row) {
                setMedications((prevData) =>
                  prevData.filter((item) => item !== row)
                );
              }
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const columnsTest = [
    {
      name: "Test",
      selector: (row) => row.test,
      sortable: true,
    },
    {
      name: "Provisional Diagnosis",
      selector: (row) => row.pdiagnosis,
      sortable: true,
    },
    {
      name: "Instructions",
      selector: (row) => row.instructions,
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      width: "100px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              if (row) {
                setTests((prevData) => prevData.filter((item) => item !== row));
              }
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const setSelectedDocId = (docId) => {
    setDocID(docId);
  };

  const getTextFromEditor = (content, delta, source, editor) => {
    setProblem(content);
  };
  const getReferalTextFromEditor = (content, delta, source, editor) => {
    setReferalNote(content);
  };
  const getPharmReferalTextFromEditor = (content, delta, source, editor) => {
    setPharmNote(content);
  };
  const getLabReferalTextFromEditor = (content, delta, source, editor) => {
    setLabNote(content);
  };

  const onClose = () => {
    setIsShowingReferAlert(false);
    setOrgID("null");
    setDocID(null);
    setDocName("");
    setOrgName("");
    setHasReferal(false);
    setReferalNote("");
  };
  const onClosePharmacy = () => {
    setIsShowingPharmacyAlert(false);
  };
  const onCloseLab = () => {
    setIsShowingLabAlert(false);
  };
  const onDeletePharmacy = () => {
    setIsShowingPharmacyAlert(false);
    setPharmID("null");
    setPharmName("");
    setHasPharmacy(false);
    setMedications([]);
  };
  const onDeleteLab = () => {
    setIsShowingLabAlert(false);
    setLabID("null");
    setLabName("");
    setHasLab(false);
    setTests([]);
  };

  const handlePageChange = (page) => {
    fetchAllDoctors(page);
  };

  const fetchOrganization = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}org/list_all/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data);
    setOrganization(response.data.data);
  };

  const fetchPharmacies = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}phams/list_all/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data);
    setPharmacies(response.data.data);
  };

  const fetchLabs = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}labs/list_all/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data);
    setLabs(response.data.data);
  };

  const fetchMedicinesAll = async (_pharmID) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all_lists/${_pharmID}/${
        getUser()["org_pid"]
      }/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data.MedsList);
    setMedicineList(response.data.data.MedsList);
    setMedDoseUnit(response.data.data.DoseList);
    setMedRoutes(response.data.data.RouteList);
    setMedFrequency(response.data.data.FrequencyList);
    setMedDuration(response.data.data.DaysList);
  };
  const fetchMedicines = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all/`,
      {
        headers: headers,
      }
    );
    setMedicineList(response.data.data);
  };

  const fetchUnits = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all_dose/`,
      {
        headers: headers,
      }
    );
    let doseUnit = Object.values(response.data.data);
    setMedDoseUnit(doseUnit);
  };

  const fetchRoutes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all_route/`,
      {
        headers: headers,
      }
    );
    let routes = Object.values(response.data.data);
    setMedRoutes(routes);
  };

  const fetchFrequency = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all_frequency/`,
      {
        headers: headers,
      }
    );
    let frequency = Object.values(response.data.data);
    setMedFrequency(frequency);
  };

  const fetchDuration = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}meds_list/all_days/`,
      {
        headers: headers,
      }
    );
    let duration = Object.values(response.data.data);
    setMedDuration(duration);
  };

  const fetchTests = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}tests_list/all/`,
      {
        headers: headers,
      }
    );
    setTestList(response.data.data);
  };

  const fetchAllDoctors = async (page, _org_id) => {
    setLoadingTable(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/any_org/${_org_id}?_limit=5&_page=${page}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      console.log(response.data.data ?? []);

      setTotalRows(response.data.recordsTotal ?? 0);
      // setTotalRows(response.data.recordsTotal ?? 0);
      setLoadingTable(false);
    } catch (error) {
      console.log(error);
      setLoadingTable(false);
    }
  };

  const getUrlParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const cons_pid = searchParams.get("cons_pid");
    const org_pid = searchParams.get("org_pid");
    const user_pid = searchParams.get("user_pid");
    const pat_pid = searchParams.get("pat_pid");
    const user_role = searchParams.get("user_role");
    if (
      cons_pid == null ||
      org_pid == null ||
      user_pid == null ||
      user_role == null
    ) {
      return null;
    }
    setEndConsPID(cons_pid);
    setEndPatPID(pat_pid);
  };

  const endConsultation = async () => {
    if (sigCanvas.isEmpty()) {
      errorToast("You have to sign");
      return;
    }
    setLoading(true);
    let req = {
      cons_pid: parseInt(end_cons_pid),
      org_pid: getUser()["org_pid"],
      doc_pid: getUser()["user_pid"],
      pat_pid: parseInt(end_pat_pid),
      remark: problem,
      refer: hasReferal ? 1 : 0,
      refer_note: referalNote,
      refer_date: new Date().toISOString(),
      refer_doc_pid: parseInt(docId),
      refer_doc_org_pid: parseInt(orgId),
      pham_pid: parseInt(pharmId),
      pham_org_pid: getUser()["org_pid"],
      lab_pid: parseInt(labId),
      lab_org_pid: getUser()["org_pid"],
      lab_note: labNote,
      medications: medications,
      tests: tests,
    };
    console.log(req);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}cons/end/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      // setShowingAlert(false)
      successToast("Consultation Ended Successfully");
    } catch (error) {
      setTimeout(() => {
        errorToast(error.message);
      }, 1000);
      console.log(error);
      setLoading(false);
    }
  };

  function onChangeMedications(tags) {
    setMedications(tags);
    console.log(medications);
  }
  function onChangeTests(tags) {
    setTests(tags);
    console.log(tests);
  }

  function validateTag(tag) {
    return true;
  }

  const handlePharmMedicineChange = (value, id) => {
    setMedicineFormData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData[id] = value;
      return newData;
    });
  };
  const handleTestMedicineChange = (value, id) => {
    setTestFormData((prevData) => {
      const newData = { ...prevData }; // Create a new object by spreading the previous data
      newData[id] = value;
      return newData;
    });
  };

  useEffect(() => {
    changeTitle("Live Consultation Review | DOC-ROA");
    getUrlParams();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div
                    className="card card-body bg-danger"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <h4 className="mb-2 mt-2 text-white">
                      Live Consultation Review
                    </h4>
                  </div>
                  <div className="row consLiveEditor">
                    <div className="col-xl-7">
                      <h5>Consultation Note</h5>
                      <ReactQuill
                        theme="snow"
                        value={problem}
                        onChange={getTextFromEditor}
                        placeholder="Describe a problem"
                      />
                    </div>
                    <div class="col-xl-5">
                      <div
                        class="accordion custom-accordion"
                        id="custom-accordion-one"
                      >
                        <div class="card mb-0">
                          <div class="card-header" id="headingNine">
                            <h5 class="m-0 position-relative">
                              {hasReferal ? (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  <span className="text-primary">
                                    <i class="mdi mdi-check "></i> Referal
                                  </span>{" "}
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              ) : (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Referal
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              )}
                            </h5>
                          </div>

                          <div
                            id="collapseOne"
                            class="collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#custom-accordion-one"
                          >
                            <div class="card-body">
                              {hasReferal ? (
                                <div>
                                  <p
                                    style={{
                                      display: "inline",
                                      lineHeight: "40px",
                                    }}
                                  >
                                    Referred to {docName} in {orgName}
                                  </p>{" "}
                                  <button
                                    type="button"
                                    class="btn  btn-outline-danger waves-effect waves-light float-end"
                                    onClick={() => {
                                      onClose();
                                    }}
                                  >
                                    <i class="mdi mdi-close"></i>
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-outline-primary waves-effect waves-light"
                                  onClick={() => {
                                    setIsShowingReferAlert(true);
                                    fetchOrganization();
                                  }}
                                >
                                  <span class="btn-label">
                                    <i class="mdi mdi-plus"></i>
                                  </span>
                                  Refer to Doctor
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="card mb-0">
                          <div class="card-header" id="headingNine">
                            <h5 class="m-0 position-relative">
                              {hasPharmacy ? (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseNine"
                                  aria-expanded="false"
                                  aria-controls="collapseNine"
                                >
                                  <span className="text-primary">
                                    <i class="mdi mdi-check "></i>{" "}
                                    e-Prescription
                                  </span>{" "}
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              ) : (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseNine"
                                  aria-expanded="false"
                                  aria-controls="collapseNine"
                                >
                                  e-Prescription
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              )}
                            </h5>
                          </div>

                          <div
                            id="collapseNine"
                            class="collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#custom-accordion-one"
                          >
                            <div class="card-body">
                              {hasPharmacy ? (
                                <div>
                                  <p
                                    style={{
                                      display: "inline",
                                      lineHeight: "40px",
                                    }}
                                  >
                                    Prescription Set
                                  </p>{" "}
                                  <button
                                    type="button"
                                    class="btn  btn-outline-danger waves-effect waves-light float-end "
                                    onClick={() => {
                                      onDeletePharmacy();
                                    }}
                                  >
                                    <i class="mdi mdi-close"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn  btn-outline-primary waves-effect waves-light float-end me-1"
                                    onClick={() => {
                                      setIsShowingPharmacyAlert(true);
                                      const anchor = document.querySelector(
                                        'a[href="#v-pills-profile"]'
                                      );
                                      if (anchor) {
                                        anchor.click();
                                      }
                                    }}
                                  >
                                    <i class="mdi mdi-eye"></i>
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-outline-primary waves-effect waves-light"
                                  onClick={() => {
                                    setIsShowingPharmacyAlert(true);
                                    fetchPharmacies();
                                    // fetchMedicines();
                                    // fetchUnits();
                                    // fetchRoutes();
                                    // fetchFrequency();
                                    // fetchDuration();
                                  }}
                                >
                                  <span class="btn-label">
                                    <i class="mdi mdi-plus"></i>
                                  </span>
                                  Add Prescription
                                </button>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div class="card mb-0">
                          <div class="card-header" id="headingFive">
                            <h5 class="m-0 position-relative">
                              {hasLab ? (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseFive"
                                >
                                  <span className="text-primary">
                                    <i class="mdi mdi-check "></i> e-Laboratory
                                  </span>{" "}
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              ) : (
                                <a
                                  class="custom-accordion-title text-reset d-block collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseFive"
                                >
                                  e-Laboratory
                                  <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                </a>
                              )}
                            </h5>
                          </div>
                          <div
                            id="collapseFive"
                            class="collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#custom-accordion-one"
                          >
                            <div class="card-body">
                              {hasLab ? (
                                <div>
                                  <p
                                    style={{
                                      display: "inline",
                                      lineHeight: "40px",
                                    }}
                                  >
                                    Laboratory set
                                  </p>{" "}
                                  <button
                                    type="button"
                                    class="btn  btn-outline-danger waves-effect waves-light float-end"
                                    onClick={() => {
                                      onDeleteLab();
                                    }}
                                  >
                                    <i class="mdi mdi-close"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn  btn-outline-primary waves-effect waves-light float-end me-1"
                                    onClick={() => {
                                      setIsShowingLabAlert(true);
                                      const anchor = document.querySelector(
                                        'a[href="#v-pills-profile"]'
                                      );
                                      if (anchor) {
                                        anchor.click();
                                      }
                                    }}
                                  >
                                    <i class="mdi mdi-eye"></i>
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-outline-primary waves-effect waves-light"
                                  onClick={() => {
                                    setIsShowingLabAlert(true);
                                    fetchLabs();
                                    fetchTests();
                                  }}
                                >
                                  <span class="btn-label">
                                    <i class="mdi mdi-plus"></i>
                                  </span>
                                  Add Laboratory
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="mt-2 ">
                            Signature
                            <span
                              class="btn btn-link mdi mdi-close text-danger float-end"
                              style={{ padding: "0px" }}
                              onClick={() => {
                                sigCanvas.clear();
                              }}
                            >
                              clear
                            </span>
                          </p>
                        </div>

                        <div className="card card-body">
                          <SignatureCanvas
                            canvasProps={{ height: 200 }}
                            ref={(ref) => {
                              setSigCanvas(ref);
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light mt-2"
                          onClick={() => {
                            endConsultation();
                          }}
                        >
                          <span class="btn-label">
                            <i class="mdi mdi-check"></i>
                          </span>
                          Finish Consultation
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowingReferAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
          style={{ minWidth: "900px", justifyContent: "flex-start" }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h4 class="header-title mb-4">Refer a Doctor</h4>

            <div class="row">
              <div class="col-sm-3">
                <div
                  class="nav flex-column nav-pills nav-pills-tab"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link show mb-1 active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="false"
                  >
                    Select Doctor
                  </a>
                  <a
                    class="nav-link mb-1 "
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >
                    Write Note
                  </a>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="tab-content pt-0">
                  <div
                    class="tab-pane fade active show"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          setOrgID(e.target.value);
                          setOrgName(
                            e.target.options[e.target.selectedIndex].text
                          );
                          if (e.target.value == null) return;
                          fetchAllDoctors(1, parseInt(e.target.value));
                        }}
                      >
                        <option value={"null"} selected="">
                          Choose Organization
                        </option>
                        {organizations.map((organization) => (
                          <option value={organization["org_pid"]}>
                            {organization["org_name"]}
                          </option>
                        ))}
                      </select>
                      <label for="floatingSelect">Organization</label>
                    </div>
                    {orgId !== "null" ? (
                      <DataTable
                        // title="Users"
                        columns={columns}
                        data={data}
                        progressPending={loadingTable}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        customStyles={customStyles}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div
                    class="tab-pane fade "
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <h5>Referal Note</h5>
                    <ReactQuill
                      theme="snow"
                      value={referalNote}
                      onChange={getReferalTextFromEditor}
                      placeholder="Referal Note"
                    />
                    <button
                      type="button"
                      class="btn btn-primary waves-effect waves-light mt-4"
                      onClick={() => {
                        setHasReferal(true);
                        setIsShowingReferAlert(false);
                      }}
                    >
                      <span class="btn-label">
                        <i class="mdi mdi-check"></i>
                      </span>
                      Confirm Referal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingpharmacyAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onClosePharmacy}
          style={{
            minWidth: "1000px",
            minHeight: "700px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h4 class="header-title mb-4">ePrescription</h4>

            <div class="row">
              <div class="col-sm-3">
                <div
                  class="nav flex-column nav-pills nav-pills-tab"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link show mb-1 active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="false"
                  >
                    Add Prescription
                  </a>
                  <a
                    class="nav-link mb-1 "
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >
                    View Prescriptions
                  </a>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="tab-content pt-0">
                  <div
                    class="tab-pane fade active show"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              setPharmID(e.target.value);
                              setPharmName(
                                e.target.options[e.target.selectedIndex].text
                              );
                              fetchMedicinesAll(e.target.value);
                            }}
                          >
                            <option value={"null"} selected="">
                              Choose Pharmacy
                            </option>
                            {pharmacies.map((organization) => (
                              <option value={organization["pham_pid"]}>
                                {organization["pham_name"]}
                              </option>
                            ))}
                          </select>
                          <label for="floatingSelect">Pharmacy</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={medicineFormData.medicine}
                            onChange={(e) => {
                              handlePharmMedicineChange(
                                e.target.value,
                                "medicine"
                              );
                            }}
                          >
                            <option selected="">Select Medicine</option>
                            {medicineList.map((medicine) => (
                              <option value={medicine}>{medicine}</option>
                            ))}
                          </select>
                          <label for="floatingSelect">Medicine</label>
                        </div>
                      </div>
                      <div class="col-3 mb-2">
                        <label for="example-number" class="form-label">
                          Dose
                        </label>
                        <input
                          class="form-control"
                          id="example-number"
                          type="number"
                          name="number"
                          placeholder="e.g. 400"
                          value={medicineFormData.dose}
                          onChange={(e) => {
                            handlePharmMedicineChange(e.target.value, "dose");
                          }}
                        />
                      </div>
                      <div class="col-3 mb-2">
                        <label for="example-select" class="form-label">
                          Unit
                        </label>
                        <select
                          class="form-select"
                          id="example-select"
                          value={medicineFormData.unit}
                          onChange={(e) => {
                            handlePharmMedicineChange(e.target.value, "unit");
                          }}
                        >
                          <option>Select Unit</option>
                          {medDoseUnit.map((dose) => (
                            <option value={dose}>{dose}</option>
                          ))}
                        </select>
                      </div>
                      <div class="col-6 mb-2">
                        <label for="example-select" class="form-label">
                          Route
                        </label>
                        <select
                          class="form-select"
                          id="example-select"
                          value={medicineFormData.route}
                          onChange={(e) => {
                            handlePharmMedicineChange(e.target.value, "route");
                          }}
                        >
                          <option>Select Route</option>
                          {medRoutes.map((routes) => (
                            <option value={routes}>{routes}</option>
                          ))}
                        </select>
                      </div>
                      <div class="col-6 mb-2">
                        <label for="example-select" class="form-label">
                          Frequency
                        </label>
                        <select
                          class="form-select"
                          id="example-select"
                          value={medicineFormData.frequency}
                          onChange={(e) => {
                            handlePharmMedicineChange(
                              e.target.value,
                              "frequency"
                            );
                          }}
                        >
                          <option>Select Frequency</option>
                          {medFrequency.map((frequency) => (
                            <option value={frequency}>{frequency}</option>
                          ))}
                        </select>
                      </div>
                      <div class="col-6 mb-2">
                        <label for="example-select" class="form-label">
                          Duration
                        </label>
                        <select
                          class="form-select"
                          id="example-select"
                          value={medicineFormData.duration}
                          onChange={(e) => {
                            handlePharmMedicineChange(
                              e.target.value,
                              "duration"
                            );
                          }}
                        >
                          <option>Select Duration</option>
                          {medDuration.map((duration) => (
                            <option value={duration}>{duration}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <h5>Comment</h5>
                    <div id="modal-note">
                      <ReactQuill
                        theme="snow"
                        value={medicineFormData.comments}
                        onChange={(e) => {
                          handlePharmMedicineChange(e, "comments");
                        }}
                        placeholder="Note to Pharmacy"
                      />
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary waves-effect waves-light mt-4"
                      onClick={() => {
                        // check if empty fields
                        if (
                          Object.keys(medicineFormData).length === 0 ||
                          Object.values(medicineFormData).some(
                            (value) => value === "" || value === undefined
                          )
                        ) {
                          errorToast("Field must not be empty");
                          return;
                        }
                        // add object to medications
                        console.log(medicineFormData);
                        const newList = [...medications, medicineFormData];
                        setMedications(newList);
                        // clear form
                        const clearedData = Object.fromEntries(
                          Object.keys(medicineFormData).map((key) => [key, ""])
                        );
                        setMedicineFormData(clearedData);
                        // move to view tab
                        const anchor = document.querySelector(
                          'a[href="#v-pills-profile"]'
                        );
                        if (anchor) {
                          anchor.click();
                        }
                      }}
                    >
                      <span class="btn-label">
                        <i class="mdi mdi-plus"></i>
                      </span>
                      Add to Prescriptions List
                    </button>
                  </div>
                  <div
                    class="tab-pane fade "
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div>
                      <DataTable
                        // title="Users"
                        columns={columnsPharm}
                        data={medications}
                        progressPending={loading}
                        pagination
                        // paginationServer
                        paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}

                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        // actions={actionsMemo}
                      />
                    </div>
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light mt-8"
                          onClick={() => {
                            setHasPharmacy(true);
                            setIsShowingPharmacyAlert(false);
                          }}
                        >
                          <span class="btn-label">
                            <i class="mdi mdi-check"></i>
                          </span>
                          Submit Medication
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingLabAlert ? (
        <SweetAlert
          showCloseButton
          showConfirm={false}
          onCancel={onCloseLab}
          style={{
            minWidth: "1000px",
            minHeight: "700px",
            justifyContent: "flex-start",
          }}
        >
          <div class="card-body " style={{ textAlign: "start" }}>
            <h4 class="header-title mb-4">eLaboratory</h4>

            <div class="row">
              <div class="col-sm-3">
                <div
                  class="nav flex-column nav-pills nav-pills-tab"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    class="nav-link show mb-1 active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="false"
                  >
                    Add Test
                  </a>
                  <a
                    class="nav-link mb-1 "
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >
                    View Tests
                  </a>
                </div>
              </div>
              <div class="col-sm-9">
                <div class="tab-content pt-0">
                  <div
                    class="tab-pane fade active show"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              setLabID(e.target.value);
                              setLabName(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                          >
                            <option value={"null"} selected="">
                              Choose Laboratory
                            </option>
                            {labs.map((lab) => (
                              <option value={lab["lab_pid"]}>
                                {lab["lab_name"]}
                              </option>
                            ))}
                          </select>
                          <label for="floatingSelect">Laboratory</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={testFormData.test}
                            onChange={(e) => {
                              handleTestMedicineChange(e.target.value, "test");
                            }}
                          >
                            <option selected="">Select Test</option>
                            {testList.map((test) => (
                              <option value={test["test_name"]}>
                                {test["test_name"]}
                              </option>
                            ))}
                          </select>
                          <label for="floatingSelect">Test</label>
                        </div>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="example-number" class="form-label">
                          Provisional Diagnosis
                        </label>
                        <input
                          class="form-control"
                          id="example-number"
                          type="text"
                          name="number"
                          // placeholder="e.g. 400"
                          value={testFormData.pdiagnosis}
                          onChange={(e) => {
                            handleTestMedicineChange(
                              e.target.value,
                              "pdiagnosis"
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="example-textarea" class="form-label">
                        Instructions
                      </label>
                      <textarea
                        class="form-control"
                        id="example-textarea"
                        rows="4"
                        onChange={(e) => {
                          handleTestMedicineChange(
                            e.target.value,
                            "instructions"
                          );
                        }}
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary waves-effect waves-light mt-4"
                      onClick={() => {
                        // check if empty fields
                        if (
                          Object.keys(testFormData).length === 0 ||
                          Object.values(testFormData).some(
                            (value) => value === "" || value === undefined
                          )
                        ) {
                          errorToast("Field must not be empty");
                          return;
                        }
                        // add object to medications
                        console.log(testFormData);
                        const newList = [...tests, testFormData];
                        console.log(newList);

                        setTests(newList);
                        // clear form
                        const clearedData = Object.fromEntries(
                          Object.keys(testFormData).map((key) => [key, ""])
                        );
                        setTestFormData(clearedData);
                        // move to view tab
                        const anchor = document.querySelector(
                          'a[href="#v-pills-profile"]'
                        );
                        if (anchor) {
                          anchor.click();
                        }
                      }}
                    >
                      <span class="btn-label">
                        <i class="mdi mdi-plus"></i>
                      </span>
                      Add to Test List
                    </button>
                  </div>
                  <div
                    class="tab-pane fade "
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div>
                      <DataTable
                        // title="Users"
                        columns={columnsTest}
                        data={tests}
                        progressPending={loading}
                        pagination
                        // paginationServer
                        paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}

                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        // actions={actionsMemo}
                      />
                    </div>
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light mt-8"
                          onClick={() => {
                            setHasLab(true);
                            setIsShowingLabAlert(false);
                          }}
                        >
                          <span class="btn-label">
                            <i class="mdi mdi-check"></i>
                          </span>
                          Submit Test
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
