import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  clearStorage,
  fixDropdownName,
  getLoggedInRole,
  getUser,
} from "../functions/reusables";

export default function Sidebar() {
  const navigate = useNavigate();
  let doctorMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },

    ...(getUser().org_type === 2
      ? [
          {
            name: "Join Room",
            link: `https://live.docroa.health/?room_id=${
              getUser()["room_id"]
            }&ward_name=${getUser()["user_name"]}`,
            sub: [],
            icon: "fe-play",
          },
        ]
      : [
          {
            name: "Consultation",
            icon: "mdi mdi-stethoscope",
            sub: [
              {
                name: "Book an Appointment",
                link: `/${getLoggedInRole()}-book-appointment`,
              },
              {
                name: "My Consultations",
                link: `/${getLoggedInRole()}-my-consultation`,
              },
            ],
          },
        ]),
    {
      name: "Vitals",
      icon: "mdi mdi-triangle-wave",
      link: `/${getLoggedInRole()}-view-vitals`,
      sub: [],
    },
    {
      name: "Referals",
      link: `/${getLoggedInRole()}-referals`,
      sub: [],
      icon: "fe-repeat",
    },
    {
      name: "Results",
      icon: "fe-clipboard",
      sub: [
        {
          name: "Store and Forward",
          link: `/${getLoggedInRole()}-forwarded-result`,
        },
        { name: "Test Results", link: `/${getLoggedInRole()}-test-result` },
      ],
    },
    {
      name: "Prescription",
      link: `/${getLoggedInRole()}-prescription`,
      sub: [],
      icon: "fe-edit",
    },
    {
      name: "Schedule",
      link: `/${getLoggedInRole()}-schedule`,
      sub: [],
      icon: "far fa-calendar-alt",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
  ];

  let organizationMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Doctor",
      icon: "mdi mdi-stethoscope",
      sub: [
        { name: "Create Doctor", link: `/${getLoggedInRole()}-create-doctor` },
        {
          name: "Manage Doctors",
          link: `/${getLoggedInRole()}-manage-doctors`,
        },
      ],
    },
    {
      name: "Patient",
      icon: "mdi mdi-face-mask-outline",
      sub: [
        {
          name: "Create Patient",
          link: `/${getLoggedInRole()}-create-patient`,
        },
        {
          name: "Manage Patients",
          link: `/${getLoggedInRole()}-manage-patients`,
        },
      ],
    },
    {
      name: "Organization User",
      icon: "mdi mdi-account-group-outline",
      sub: [
        {
          name: "Create Organization User",
          link: `/${getLoggedInRole()}-create-organization-user`,
        },
        {
          name: "Manage Organization User",
          link: `/${getLoggedInRole()}-manage-organization-user`,
        },
      ],
    },
    ...(getUser().user_type === undefined
      ? [
          {
            name: "Ward",
            icon: "mdi mdi-hospital-box-outline",
            sub: [
              {
                name: "Create Ward",
                link: `/${getLoggedInRole()}-create-ward`,
              },
              {
                name: "Manage Ward",
                link: `/${getLoggedInRole()}-manage-ward`,
              },
              {
                name: "Create Block",
                link: `/${getLoggedInRole()}-create-block`,
              },
            ],
          },
        ]
      : []),
    {
      name: "Health Center",
      icon: "mdi mdi-hospital-box",
      sub: [
        {
          name: "Create Health Center",
          link: `/${getLoggedInRole()}-create-health-center`,
        },
        {
          name: "Manage Health Center",
          link: `/${getLoggedInRole()}-manage-health-center`,
        },
      ],
    },
    {
      name: "Laboratory",
      icon: "mdi mdi-beaker-check-outline",
      sub: [
        { name: "Create Laboratory", link: `/${getLoggedInRole()}-create-lab` },
        { name: "Manage Laboratory", link: `/${getLoggedInRole()}-manage-lab` },
      ],
    },
    {
      name: "Pharmacy",
      icon: "mdi mdi-pill",
      sub: [
        {
          name: "Create Pharmacy",
          link: `/${getLoggedInRole()}-create-pharmacy`,
        },
        {
          name: "Manage Pharmacy",
          link: `/${getLoggedInRole()}-manage-pharmacy`,
        },
      ],
    },
    {
      name: "Vitals",
      icon: "mdi mdi-triangle-wave",
      link: `/${getLoggedInRole()}-view-vitals`,
      sub: [],
    },
    {
      name: "Specialty",
      link: `/${getLoggedInRole()}-specialty`,
      sub: [],
      icon: " mdi mdi-window-open",
    },
    {
      name: "Consultation",
      icon: "mdi mdi-stethoscope",
      sub: [
        { name: "Consultations", link: `/${getLoggedInRole()}-consultation` },
      ],
    },
    {
      name: "Results",
      icon: "fe-clipboard",
      sub: [
        {
          name: "Store and Forward",
          link: `/${getLoggedInRole()}-forwarded-result`,
        },
        { name: "Test Results", link: `/${getLoggedInRole()}-test-result` },
      ],
    },
    {
      name: "Prescription",
      link: `/${getLoggedInRole()}-prescription`,
      sub: [],
      icon: "fe-edit",
    },
    {
      name: "Audit Logs",
      link: `/${getLoggedInRole()}-audit`,
      sub: [],
      icon: "mdi mdi-layers-triple-outline",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
  ];
  let organizationUserMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Doctor",
      icon: "mdi mdi-stethoscope",
      sub: [
        { name: "Create Doctor", link: `/${getLoggedInRole()}-create-doctor` },
        {
          name: "Manage Doctors",
          link: `/${getLoggedInRole()}-manage-doctors`,
        },
      ],
    },
    {
      name: "Patient",
      icon: "mdi mdi-face-mask-outline",
      sub: [
        {
          name: "Create Patient",
          link: `/${getLoggedInRole()}-create-patient`,
        },
        {
          name: "Manage Patients",
          link: `/${getLoggedInRole()}-manage-patients`,
        },
      ],
    },
    {
      name: "Health Center",
      icon: "mdi mdi-hospital-box",
      sub: [
        {
          name: "Create Health Center",
          link: `/${getLoggedInRole()}-create-health-center`,
        },
        {
          name: "Manage Health Center",
          link: `/${getLoggedInRole()}-manage-health-center`,
        },
      ],
    },
    ...(getUser().org_type === 2
      ? [
          {
            name: "Ward",
            icon: "mdi mdi-hospital-box-outline",
            sub: [
              {
                name: "Create Ward",
                link: `/${getLoggedInRole()}-create-ward`,
              },
              {
                name: "Manage Ward",
                link: `/${getLoggedInRole()}-manage-ward`,
              },
              {
                name: "Create Block",
                link: `/${getLoggedInRole()}-create-block`,
              },
            ],
          },
        ]
      : []),
    {
      name: "Laboratory",
      icon: "mdi mdi-beaker-check-outline",
      sub: [
        { name: "Create Laboratory", link: `/${getLoggedInRole()}-create-lab` },
        { name: "Manage Laboratory", link: `/${getLoggedInRole()}-manage-lab` },
      ],
    },
    {
      name: "Pharmacy",
      icon: "mdi mdi-pill",
      sub: [
        {
          name: "Create Pharmacy",
          link: `/${getLoggedInRole()}-create-pharmacy`,
        },
        {
          name: "Manage Pharmacy",
          link: `/${getLoggedInRole()}-manage-pharmacy`,
        },
      ],
    },
    {
      name: "Vitals",
      icon: "mdi mdi-triangle-wave",
      link: `/${getLoggedInRole()}-view-vitals`,
      sub: [],
    },
    {
      name: "Specialty",
      link: `/${getLoggedInRole()}-specialty`,
      sub: [],
      icon: " mdi mdi-window-open",
    },
    {
      name: "Consultation",
      icon: "mdi mdi-stethoscope",
      sub: [
        { name: "Consultations", link: `/${getLoggedInRole()}-consultation` },
      ],
    },
    {
      name: "Results",
      icon: "fe-clipboard",
      sub: [
        {
          name: "Store and Forward",
          link: `/${getLoggedInRole()}-forwarded-result`,
        },
        { name: "Test Results", link: `/${getLoggedInRole()}-test-result` },
      ],
    },
    {
      name: "Prescription",
      link: `/${getLoggedInRole()}-prescription`,
      sub: [],
      icon: "fe-edit",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
  ];
  let centerMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Consultation",
      icon: "mdi mdi-stethoscope",
      sub: [
        {
          name: "Book an Appointment",
          link: `/${getLoggedInRole()}-book-appointment`,
        },
        { name: "Consultations", link: `/${getLoggedInRole()}-consultation` },
      ],
    },
    {
      name: "Vitals",
      icon: "mdi mdi-triangle-wave",
      sub: [
        {
          name: "Add Vitals",
          link: `/${getLoggedInRole()}-add-vitals`,
        },
        { name: "Manage Vitals", link: `/${getLoggedInRole()}-manage-vitals` },
      ],
    },
    {
      name: "Results",
      icon: "fe-clipboard",
      sub: [
        {
          name: "Store and Forward",
          link: `/${getLoggedInRole()}-forwarded-result`,
        },
        { name: "Test Results", link: `/${getLoggedInRole()}-test-result` },
      ],
    },
    {
      name: "Prescription",
      link: `/${getLoggedInRole()}-prescription`,
      sub: [],
      icon: "fe-edit",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
    // { name: "Settings", link: `/${getLoggedInRole()}-settings`, sub: [], icon:"fe-settings" },
  ];
  let patientMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Consultation",
      icon: "mdi mdi-stethoscope",
      sub: [
        { name: "Consultations", link: `/${getLoggedInRole()}-consultation` },
      ],
    },
    {
      name: "Vitals",
      icon: "mdi mdi-triangle-wave",
      link: `/${getLoggedInRole()}-view-vitals`,
      sub: [],
    },
    {
      name: "Results",
      icon: "fe-clipboard",
      sub: [
        //   { name: "Store and Forward", link: `/${getLoggedInRole()}-forwarded-result` },
        { name: "Test Results", link: `/${getLoggedInRole()}-test-result` },
      ],
    },
    {
      name: "Prescription",
      link: `/${getLoggedInRole()}-prescription`,
      sub: [],
      icon: "fe-edit",
    },
    // { name: "Settings", link: `/${getLoggedInRole()}-settings`, sub: [], icon:"fe-settings" },
  ];

  let labMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Test List",
      link: `/${getLoggedInRole()}-test-list`,
      sub: [],
      icon: "mdi mdi-flask-outline",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
    // {
    //   name: "Order Management",
    //   sub: [
    //     { name: "VAT Collections", link: "/vat-collections" },
    //   ],
    //   icon:"ri-newspaper-line",
    // },
  ];
  let pharmacyMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Add Medicine",
      link: `/${getLoggedInRole()}-add-medicine`,
      sub: [],
      icon: "mdi mdi-pill",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
  ];
  let wardMenus = [
    {
      name: "Dashboard",
      link: `/${getLoggedInRole()}-dashboard`,
      sub: [],
      icon: "mdi mdi-view-dashboard-outline",
    },
    {
      name: "Settings",
      link: `/${getLoggedInRole()}-settings`,
      sub: [],
      icon: "fe-settings",
    },
  ];

  const returnMenu = (role) => {
    let menu = [];
    if (role == "pharmacy") {
      menu = pharmacyMenus;
    } else if (role == "doctor") {
      menu = doctorMenus;
    } else if (role == "organization") {
      menu = organizationMenus;
    } else if (role == "organization_user") {
      menu = organizationUserMenus;
    } else if (role == "health_center") {
      menu = centerMenus;
    } else if (role == "patient") {
      menu = patientMenus;
    } else if (role == "laboratory") {
      menu = labMenus;
    } else if (role == "ward") {
      menu = wardMenus;
    }
    // else if(role == "firs-user"){
    //   menu = firsUserMenus;
    // }
    // else if(role == "operator-administrator"){
    //   menu = operatorMenus;
    // }
    // else if(role == "merchant-administrator"){
    //   menu = merchantMenus;
    // }
    // setMenuVisible(true)

    return menu;
  };

  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  const toggleSelectedMenu = (menu) => {
    if (selectedMenu != menu) {
      setSelectedMenu(menu);
    } else {
      setSelectedMenu("");
    }
  };

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [canResize, setCanResize] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    console.log(getUser());
    const handleResize = () => {
      setCanResize(window.innerWidth < 991);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Check window width on initial mount

    // Clean up the listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="wrapper">
      <div className="navbar-custom">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <li className="dropdown notification-list topbar-dropdown">
            <a
              className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src="assets/images/favicon.png"
                alt="user-image"
                className="rounded-circle"
              />
              <span className="pro-user-name ms-1">
                {getUser().user_name} <i className="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome !</h6>
              </div>

              <Link
                to={`/${getLoggedInRole()}-settings`}
                className="dropdown-item notify-item"
              >
                <i className="fe-user"></i>
                <span>My Account</span>
              </Link>

              <div className="dropdown-divider"></div>

              <button
                className="dropdown-item notify-item"
                onClick={() => {
                  clearStorage();
                  navigate("/");
                }}
              >
                <i className="fe-log-out"></i>
                <span>Logout</span>
              </button>
            </div>
          </li>
        </ul>

        <div className="logo-box">
          <a href="#" className="logo logo-light text-center">
            <span className="logo-sm">
              <img src="assets/images/favicon.png" alt="" height="50" />
            </span>
            <span className="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="50" />
            </span>
          </a>
          <a href="#" className="logo logo-dark text-center">
            <span className="logo-sm">
              <img src="assets/images/favicon.png" alt="" height="50" />
            </span>
            <span className="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="50" />
            </span>
          </a>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
          <li>
            <button
              className="button-menu-mobile disable-btn waves-effect"
              onClick={handleToggleMenu}
            >
              <i className="fe-menu"></i>
            </button>
          </li>

          <li>
            <h4 className="page-title-main" style={{ lineHeight: "70px" }}>
              {fixDropdownName(getLoggedInRole()).toUpperCase()}
            </h4>
          </li>
        </ul>

        <div className="clearfix"></div>
      </div>

      {/* <!-- ========== Left Sidebar Start ========== --> */}
      <div
        className={`left-side-menu ${
          isMenuVisible && canResize ? "visible" : ""
        }`}
      >
        <div className="h-100" data-simplebar>
          <div className="user-box text-center">
            <img
              src={
                getUser()["user_photo"] !== ""
                  ? getUser()["user_photo"]
                  : "assets/images/favicon.png"
              }
              // src="assets/images/favicon.png"
              alt="user-img"
              title="Mat Helme"
              className="rounded-circle img-thumbnail avatar-md"
            />
            <div className="dropdown">
              <a
                href="#"
                className="user-name dropdown-toggle h5 mt-2 mb-1 d-block"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {getUser().user_name}
              </a>
            </div>

            <p className="text-muted left-user-info">{getLoggedInRole()}</p>
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
              height: "1px",
              backgroundColor: "#f5f5f5",
            }}
          ></div>
          <div id="sidebar-menu">
            <ul id="side-menu">
              <li className="menu-title">Navigation</li>
              {returnMenu(getLoggedInRole()).map((menu) => (
                <li
                  key={menu.name}
                  style={{
                    backgroundColor:
                      menu.name == selectedMenu ? "azure" : "white",
                  }}
                >
                  {menu.sub.length == 0 ? (
                    <Link
                      to={menu.link}
                      onClick={() => {
                        setSelectedMenu(menu.name);
                        setSelectedSubMenu("");
                        setIsMenuVisible(false);
                      }}
                      target={menu.name === "Join Room" ? "_blank" : "_self"}
                    >
                      <i className={menu.icon}></i>
                      <span>{menu.name}</span>
                    </Link>
                  ) : (
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      className={selectedMenu == menu.name ? "collapsed" : ""}
                      aria-expanded={
                        selectedMenu == menu.name ? "true" : "false"
                      }
                      onClick={() => {
                        toggleSelectedMenu(menu.name);
                      }}
                    >
                      <i className={menu.icon}></i>
                      <span>{menu.name}</span>
                      <span className="menu-arrow"></span>
                    </a>
                  )}
                  {menu.sub.length !== 0 ? (
                    <div
                      id="email"
                      className={
                        selectedMenu == menu.name ? "collapse show" : "collapse"
                      }
                    >
                      <ul className="nav-second-level">
                        {menu.sub.map((submenu) => (
                          <li
                            className={
                              selectedSubMenu == submenu.name ? "active" : ""
                            }
                            onClick={() => {
                              setSelectedSubMenu(submenu.name);
                            }}
                          >
                            <Link
                              to={submenu.link}
                              onClick={() => {
                                setIsMenuVisible(false);
                              }}
                            >
                              {submenu.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </li>
              ))}

              {/* <li>
                                <a href="#email" data-bs-toggle="collapse">
                                    <i className="mdi mdi-email-outline"></i>
                                    <span> Email </span>
                                    <span className="menu-arrow"></span>
                                </a>
                                <div className="collapse" id="email">
                                    <ul className="nav-second-level">
                                        <li>
                                            <a href="email-inbox.html">Inbox</a>
                                        </li>
                                        <li>
                                            <a href="email-templates.html">Email Templates</a>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
            </ul>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
}
