import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import { useNavigate } from "react-router-dom";

export default function PharmAddMedicine() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [addeddata, setAddedData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [removeSearchTerm, setRemoveSearchTerm] = useState("");

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.med_pid,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.med_name,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "80px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-icon waves-effect waves-light"
            style={{ marginRight: "5px" }}
            onClick={() => {
              handleDrugSelect(row);
              // setSelectedRow(row);
              // setUpdateTest(row.test_name);
              // setShowingUpdateAlert(true);
            }}
          >
            <i className="fe-plus"></i>
          </button>
        </div>
      ),
    },
  ];
  const columnsAdded = [
    {
      name: "ID",
      selector: (row) => row.med_pid,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.med_name,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "80px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light"
            style={{ marginRight: "5px" }}
            onClick={() => {
              handleRemoveDrug(row);
              // setSelectedRow(row);
              // setUpdateTest(row.test_name);
              // setShowingUpdateAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllMedicine();
    await fetchAllPharmMedicine();
  };

  const fetchAllMedicine = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}meds_list/all/`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchAllPharmMedicine = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}phams/meds/all/`,
        {
          headers: headers,
        }
      );
      setAddedData((prevSelectedDrugs) => {
        const newSelectedDrugs = response.data.data ?? [];
        return newSelectedDrugs;
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddAlldrugs = () => {
    if (!data.length == 0) {
      setAddedData((prevSelectedDrugs) => {
        const newSelectedDrugs = [...data];
        return newSelectedDrugs;
      });
    } else {
      errorToast("Cannot add empty list");
    }
  };
  const handleDeleteAlldrugs = () => {
    if (!addeddata.length == 0) {
      setAddedData((prevSelectedDrugs) => {
        const newSelectedDrugs = [];
        return newSelectedDrugs;
      });
    } else {
      errorToast("Cannot Remove empty list");
    }
  };
  const handleDrugSelect = (drug) => {
    for (let index = 0; index < addeddata.length; index++) {
      const id = addeddata[index]["med_pid"];
      if (drug["med_pid"] == id) {
        errorToast("Already Added Drug");
        return;
      }
    }
    if (!addeddata.includes(drug)) {
      setAddedData((prevSelectedDrugs) => {
        if (addeddata.includes(drug)) {
          errorToast("Already Added Drug");
          return;
        }
        const newSelectedDrugs = [...prevSelectedDrugs];
        newSelectedDrugs.unshift(drug);
        return newSelectedDrugs;
      });
    } else {
      errorToast("Already Added Drug");
    }
  };

  const handleRemoveDrug = (drug) => {
    setAddedData((prevSelectedDrugs) =>
      prevSelectedDrugs.filter((d) => d !== drug)
    );
  };

  const handlePageChange = () => {
    fetchAllMedicine();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchChangeRemove = (event) => {
    setRemoveSearchTerm(event.target.value);
  };

  const filteredDrugs = data.filter((drug) =>
    drug["med_name"].toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredDrugsRemove = addeddata.filter((drug) =>
    drug["med_name"].toLowerCase().includes(removeSearchTerm.toLowerCase())
  );

  const handleSubmitMedicines = async () => {
    setLoading(true);
    const medPids = addeddata.map((item) => item.med_pid);
    let req = {
      med_pids: medPids,
    };
    console.log(req);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}phams/meds/update/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      successToast("Medicines Added Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    changeTitle("Add Medicine | DOC-ROA");
    performTasks();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="card"
                    style={{ minHeight: "calc(100vh - 170px)" }}
                  >
                    <div className="card-body">
                      <h4 className="header-title mb-3">Add Medicine</h4>
                      <div
                        className="d-flex mb-2"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div class="input-group" style={{ maxWidth: "250px" }}>
                          <span class="input-group-text" id="basic-addon1">
                            <i class="mdi mdi-magnify me-1"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search drugs.."
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light"
                          onClick={handleAddAlldrugs}
                        >
                          <i class="mdi mdi-plus me-1"></i> Add All
                        </button>
                      </div>
                      <DataTable
                        columns={columns}
                        data={filteredDrugs}
                        progressPending={loading}
                        pagination
                        paginationTotalRows={totalRows}
                        // onChangePage={handlePageChange}
                        paginationRowsPerPageOptions={[10]}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div
                    className="card"
                    style={{ minHeight: "calc(100vh - 170px)" }}
                  >
                    <div className="card-body">
                      <h4 className="header-title mb-3">Pharmacy Medicines</h4>
                      <div
                        className="d-flex mb-2"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div class="input-group" style={{ maxWidth: "250px" }}>
                          <span class="input-group-text" id="basic-addon1">
                            <i class="mdi mdi-magnify me-1"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search drugs.."
                            value={removeSearchTerm}
                            onChange={handleSearchChangeRemove}
                          />
                        </div>
                        <button
                          type="button"
                          class="btn btn-danger waves-effect waves-light"
                          onClick={handleDeleteAlldrugs}
                        >
                          <i class="mdi mdi-trash me-1"></i> Remove All
                        </button>
                      </div>
                      <DataTable
                        columns={columnsAdded}
                        data={filteredDrugsRemove}
                        progressPending={loading}
                        pagination
                        paginationTotalRows={totalRows}
                        // onChangePage={handlePageChange}
                        paginationRowsPerPageOptions={[10]}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">Submit Medicines</h4>
                      <div
                        className="mb-2"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p>
                          Note: by clicking the button below, you will submit
                          the list of drugs to your phramacys itenary.
                        </p>
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light"
                          onClick={handleSubmitMedicines}
                        >
                          <i class="mdi mdi-trash me-1"></i> Submit Medicines
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {isShowingUpdateAlert ? (
        <SweetAlert
          title="Update Test Name!"
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="mb-3">
            <label for="example-date" className="form-label">
              Test
            </label>
            <input
              className="form-control"
              id="example-date"
              type="text"
              value={updateTestText}
              name="date"
              onChange={(e) => {
                setUpdateTest(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-dark waves-effect waves-light"
            onClick={() => {
              updateTest();
            }}
          >
            Update Test Name
          </button>
        </SweetAlert>
      ) : (
        <div></div>
      )} */}

      <ToastContainer />
    </div>
  );
}
