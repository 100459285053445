import ImageViewer from 'awesome-image-viewer';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from 'react-data-table-component';
import LoadingScreen from 'react-loading-screen'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import { changeTitle, convertFrom12To24Format, convertFrom24To12Format, convertFromISODate, convertToHumanReadableDate, errorToast, getLoggedInRole, getUser, returnParsedHtml, successToast } from '../../functions/reusables';
import Notification from '../../component/notification';
import { useLocation } from 'react-router-dom';

export default function OrganizationSpecialtyDoctors() {
  const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isShowingUpdateAlert, setShowingUpdateAlert] = useState(false);
    const [specialty, setSpecialtyName] = useState("")
    const [updateSpecialtyText, setUpdateSpecialty] = useState("")
    const [selectedRow, setSelectedRow] = useState({})
    const [deptname, setDeptName] = useState("")

  

    const customStyles = {
        header: {
          style: {
            minHeight: "56px",
          },
        },
        headRow: {
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#dce1e6",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderLeftColor: "#dce1e6",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#dce1e6",
          },
        },
        headCells: {
          style: {
            "&:not(:last-of-type)": {
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: "#dce1e6",
            },
          },
        },
        cells: {
          style: {
            "&:not(:last-of-type)": {
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: "#dce1e6",
            },
          },
        },
        rows: {
          style: {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#dce1e6",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderLeftColor: "#dce1e6",
          },
        },
      };
    
      const columns = [
          {
            name: "Name",
            selector: (row) =>row.user_name,
            sortable: true,
          },
          // {
          //   name: 'Actions',
          //   button: true,
          //   width:'110px',
          //   cell: (row) => (
          //     <div>
          //           <button  type="button"  className="btn btn-info btn-icon waves-effect waves-light" style={{marginRight:'5px'}} onClick={()=>{
          //               // setSelectedRow(row)
          //               // setUpdateSpecialty(row.dept_name)
          //               // setShowingUpdateAlert(true)
          //               }}><i className="fe-edit"></i>
          //           </button>

          //       </div>
          //   ),
          // },
      ];



    const performTasks = async() => {
        await fetchAllMedDeptDoctors(1)
    }
    const fetchAllMedDeptDoctors = async(page) => {
      const searchParams = new URLSearchParams(location.search);
      const deptPID = searchParams.get('dept_pid');
        try {
            const headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+getUser()["token"],
            };
            const response = await axios.get(
            `${process.env.REACT_APP_MAIN}med_depts/docs_in_dept/${deptPID}/`,
            {
                headers: headers,
            }
            );
            setData(response.data.data ?? []);
            setTotalRows(response.data.recordsTotal ?? 0);

            setLoading(false);
       } catch (error) {
            console.log(error)
            setLoading(false);
       }
    }

    const handlePageChange = (page) => {
        fetchAllMedDeptDoctors(page);
    };

      useEffect(() => {
        changeTitle("View Doctors in Specialty| DOC-ROA")
        const searchParams = new URLSearchParams(location.search);
        const deptName = searchParams.get('name');
        setDeptName(deptName)
        performTasks() 
    }, []);

   

    const updateSpecialty = async () => {
        setLoading(true)
        let req = {
            "dept_pid": selectedRow.dept_pid,
            "dept_name": updateSpecialtyText,
        }
        console.log(req)
        try {
            const headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+getUser()["token"],
            };
            const response = await axios.patch(
            `${process.env.REACT_APP_MAIN}med_depts/update/`,
            req,
            {
                headers: headers,
            }
            );
            console.log(response.data)
            setLoading(false)
            setShowingUpdateAlert(false)
            successToast("Specialty Edited Successfully")

       } catch (error) {
            console.log(error)
            setShowingUpdateAlert(false)
            errorToast(error.message)
            setLoading(false)
       }
    }
  


    const onClose = () => {
        setShowingUpdateAlert(false)
    }

  return (
    loading ? <LoadingScreen
    loading={loading}
    bgColor='#ffffff'
    spinnerColor='#9ee5f8'
    textColor='#77d5cc'
    logoSrc='assets/images/logo-dark.png'
    text='...'
  
    ></LoadingScreen>:
    <div className="content-page">
        <div className="content">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                    <div className='row'>
                            <Notification/>
                        </div>
                        <div className="card">
                          
                            <div className="card-body">
                            <div className='col-12 mb-4'>
                              <button type="button" class="btn btn-light waves-effect waves-light" onClick={()=>{
                                window.history.back();
                              }}>
                                                        <span class="btn-label"><i class="fe-arrow-left"></i></span>Go Back
                                                    </button>
                              </div>
                              <div className='row'>
                                <div className='col-md-4'>
                                
                                <h4 className="header-title mb-3">{deptname}</h4>
                                  <div class="card bg-primary">
                                    <div class="card-body">
                                        
                                    
                                        <h4 class="header-title mt-0 mb-4">Doctors</h4>
    
                                        <div class="widget-chart-1">
                                            <div class="widget-chart-box-1 float-start" dir="ltr">
                                                <div style={{display:"inline",width:"70px",height:"70px"}}><i class="text-white mdi mdi-doctor" style={{fontSize:'50px'}}></i></div>
                                            </div>
    
                                            <div class="widget-detail-1 text-end">
                                                <h2 class="fw-normal pt-2 mb-1" style={{fontSize:'60px', color:'white'}}> {totalRows} </h2>
                                                <p class="text-white mb-1">Total Doctors</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    
                                </div>
                                <div className='col-md-8'>
                                  <h4 className="header-title mb-3">All Doctors in Specialty</h4>
                                  <DataTable
                                  // title="Users"
                                  columns={columns}
                                  data={data}
                                  progressPending={loading}
                                  pagination
                                  // paginationServer
                                  paginationTotalRows={totalRows}
                                  // onChangeRowsPerPage={handlePerRowsChange}
                                  
                                  onChangePage={handlePageChange}
                                  customStyles={customStyles}
                                  // actions={actionsMemo}
                                  />
                                </div>
                              </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
       
        { isShowingUpdateAlert ? <SweetAlert title="Update Specialty!" showCloseButton showConfirm={false}  onCancel={onClose}>
          <div className="mb-3">
              <label for="example-date" className="form-label">Specialty</label>
              <input className="form-control" id="example-date" type="text" value={updateSpecialtyText} name="date" onChange={(e)=>{
                  setUpdateSpecialty(e.target.value)
              }}/>
          </div>
          <button type="button" className="btn btn-dark waves-effect waves-light" onClick={()=>{
              updateSpecialty()
          }}>Update Specialty</button>
        </SweetAlert>: <div></div>}
       
        
        <ToastContainer />
    </div>
  )
}
