import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import TableHead from "../../component/table-head";

export default function DoctorMyConsultation() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingAlert, setShowingAlert] = useState(false);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingViewAlert, setShowingViewAlert] = useState(false);
  const [isShowingAcceptAlert, setShowingAcceptAlert] = useState(false);
  const [isShowingImages, setShowingImages] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [selectedConsPid, setSelectedConsPid] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [problemImages, setProblemImages] = useState([]);
  // const [selectedImage, setProblemImages] = useState([])

  const modules = {
    toolbar: [],
  };

  const formats = [[]];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => convertFromISODate(row.cons_date),
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => row.start_time,
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => row.end_time,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.cons_app_status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Pending</span>{" "}
          </h5>
        ) : row.cons_app_status == 2 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-danger ">Resheduled</span>{" "}
          </h5>
        ) : row.cons_app_status == 3 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-success ">Confirmed</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-primary ">Completed</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "280px",
      cell: (row) => (
        <div>
          <button
            type="button"
            disabled={
              row.cons_app_status !== 3 || row.cons_app_status == 4
                ? true
                : false
            }
            className="btn btn-info btn-icon waves-effect waves-light me-1"
            onClick={() => {
              startLiveConsultation(row.cons_pid, row.pat_pid);
            }}
          >
            <i className="fe-play"></i>
          </button>
          <button
            type="button"
            disabled={
              row.cons_app_status == 3 || row.cons_app_status == 4
                ? true
                : false
            }
            className="btn btn-primary btn-icon waves-effect waves-light me-1"
            onClick={() => {
              showAcceptConsultation(row);
            }}
          >
            <i className="fe-check"></i>
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon waves-effect waves-light me-1"
            onClick={() => {
              viewConsultation(row);
            }}
          >
            <i className="fe-eye"></i>
          </button>
          <button
            type="button"
            className="btn btn-warning btn-icon waves-effect waves-light me-1"
            disabled={
              row.cons_app_status == 3 || row.cons_app_status == 4
                ? true
                : false
            }
            onClick={() => {
              showConsultation(
                row.cons_pid,
                convertFromISODate(row.cons_date),
                convertFrom12To24Format(row.start_time),
                convertFrom12To24Format(row.end_time)
              );
              // updateConsultation(row.cons_pid,convertFromISODate(row.cons_date), row.start_time, row.end_time)
            }}
          >
            <i className="fe-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon waves-effect waves-light me-1"
            onClick={() => {
              showDeleteConsultation(row.cons_pid);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const startLiveConsultation = (cons_pid, pat_pid) => {
    window.location.href = `${
      process.env.REACT_APP_LIVE
    }?cons_pid=${cons_pid}&org_pid=${getUser()["org_pid"]}&user_pid=${
      getUser()["user_pid"]
    }&user_role=${getUser()["user_role"]}&pat_pid=${pat_pid}`;
    // window.location.href = `https://docroa-live-cons-f4l32.ondigitalocean.app/?cons_pid=${cons_pid}&org_pid=${getUser()['org_pid']}&user_pid=${getUser()['user_pid']}&user_role=${getUser()['user_role']}`;
    // window.open(`https://docroa-live-cons-f4l32.ondigitalocean.app/?cons_pid=${cons_pid}&org_pid=${getUser()['org_pid']}&user_pid=${getUser()['user_pid']}&user_role=${getUser()['user_role']}`)
  };

  const performTasks = async () => {
    await fetchAllConsultations(1);
  };
  const fetchAllConsultations = async (page, searchterm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }cons/all_cons_doc/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchterm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllConsultations(page);
  };
  const buildImagesList = (data) => {
    let imagesList = [];
    if (!data.problem_image) {
      setProblemImages([]);
      return;
    }
    for (let index = 0; index < data.problem_image.length; index++) {
      const element = data.problem_image[index];
      imagesList.push({ mainUrl: element });
    }
    setProblemImages(imagesList);
    console.log(data);
    console.log(problemImages);
  };

  useEffect(() => {
    changeTitle("My Consultations | DOC-ROA");
    performTasks();
  }, []);

  const updateConsultation = async () => {
    setLoading(true);
    let req = {
      cons_pid: selectedConsPid,
      org_pid: getUser()["org_pid"],
      cons_date: selectedDay,
      start_time: convertFrom24To12Format(selectedStartTime),
      end_time: convertFrom24To12Format(selectedEndTime),
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}cons/update/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingAlert(false);
      successToast("Consultation Updated Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingAlert(false);
    }
  };

  const acceptConsultation = async () => {
    setLoading(true);
    let req = {
      cons_pid: parseInt(selectedConsPid),
      org_pid: getUser()["org_pid"],
    };
    console.log(req);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}cons/accept/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      setShowingAcceptAlert(false);
      successToast("Consultation Confirmed Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingAcceptAlert(false);
    }
  };
  const deleteConsultation = async () => {
    setLoading(true);
    let req = {
      cons_pid: selectedConsPid,
      org_pid: getUser()["org_pid"],
      del_type: 2,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}cons/delete/`,
        req,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingDeleteAlert(false);
      successToast("Consultation Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingDeleteAlert(false);
    }
  };

  const showConsultation = (cons_pid, cons_date, start_time, end_time) => {
    setSelectedConsPid(cons_pid);
    setSelectedDay(cons_date);
    setSelectedStartTime(start_time);
    setSelectedEndTime(end_time);
    setShowingAlert(true);
  };

  const showAcceptConsultation = (row) => {
    setSelectedRow(row);
    setSelectedConsPid(row.cons_pid);
    setShowingAcceptAlert(true);
  };
  const viewConsultation = (row) => {
    setSelectedRow(row);
    setShowingViewAlert(true);
    buildImagesList(row);
  };
  const showDeleteConsultation = (cons_pid) => {
    setSelectedConsPid(cons_pid);
    setShowingDeleteAlert(true);
  };
  const onClose = () => {
    setShowingAlert(false);
    setShowingDeleteAlert(false);
    setShowingViewAlert(false);
    setShowingAcceptAlert(false);
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">My Consultations</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${
                      process.env.REACT_APP_MAIN
                    }cons/all_cons_doc/?&org_pid=${getUser()["org_pid"]}`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllConsultations(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[10]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowingAlert ? (
        <SweetAlert
          title="Update Consultation!"
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="mb-3">
            <label for="example-date" className="form-label">
              Date
            </label>
            <input
              className="form-control"
              id="example-date"
              type="date"
              value={selectedDay}
              name="date"
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedDay(e.target.value);
              }}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <label for="example-time" className="form-label">
                  Start Time
                </label>
                <input
                  className="form-control"
                  id="example-time"
                  type="time"
                  value={selectedStartTime}
                  name="time"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedStartTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label for="example-time" className="form-label">
                  End Time
                </label>
                <input
                  className="form-control"
                  id="example-time"
                  type="time"
                  value={selectedEndTime}
                  name="time"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedEndTime(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-dark waves-effect waves-light"
            onClick={() => {
              updateConsultation();
            }}
          >
            Update Consultation
          </button>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingViewAlert ? (
        <SweetAlert
          title="View Consultation!"
          style={{ minWidth: "700px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body task-detail">
              <div className="dropdown float-end">
                <a
                  href="#"
                  className="dropdown-toggle arrow-none card-drop"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </a>
              </div>
              <div className="d-flex mb-3">
                <img
                  className="flex-shrink-0 me-3 rounded-circle avatar-md"
                  alt="64x64"
                  src="assets/images/favicon.png"
                />
                <div className="flex-grow-1">
                  <h4 className="media-heading mt-0">{selectedRow.pat_name}</h4>
                  {selectedRow.cons_app_status == 1 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-info ">Pending</span>{" "}
                    </h5>
                  ) : selectedRow.cons_app_status == 2 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-danger ">
                        Resheduled
                      </span>{" "}
                    </h5>
                  ) : selectedRow.cons_app_status == 3 ? (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-success ">
                        Confirmed
                      </span>{" "}
                    </h5>
                  ) : (
                    <h5 className="fs-14 my-1 fw-normal">
                      <span className="badge badge-soft-primary ">
                        Completed
                      </span>{" "}
                    </h5>
                  )}
                </div>
              </div>

              <h4>Patients Problem</h4>

              <div className="consDiv">
                <ReactQuill
                  toolbar={false}
                  theme="snow"
                  readOnly
                  modules={modules}
                  value={selectedRow.problem}
                />
              </div>

              <div className="row task-dates mb-0 mt-2">
                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">Consultation Date</h5>
                  <p>
                    {convertToHumanReadableDate(
                      convertFromISODate(selectedRow.cons_date)
                    )}
                  </p>
                </div>

                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">Start Time</h5>
                  <p className="text-muted">{selectedRow.start_time}</p>
                </div>
                <div className="col-lg-4">
                  <h5 className="font-600 m-b-5">End Time</h5>
                  <p className="text-muted">{selectedRow.end_time}</p>
                </div>
              </div>
              <div className="clearfix"></div>

              <div className="attached-files mt-3">
                <h5>Attached Files </h5>
                <ul className="list-inline files-list">
                  {selectedRow.problem_image == null
                    ? []
                    : selectedRow.problem_image.map((img, i) => (
                        <li className="list-inline-item file-box">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              new ImageViewer({
                                images: problemImages,
                                currentSelected: i,
                              });
                            }}
                          >
                            <img
                              src={img}
                              className="img-fluid img-thumbnail"
                              alt="attached-img"
                              width="80"
                            />
                          </div>
                          <p className="font-13 mb-1 text-muted">
                            <small>
                              {img.substring(img.lastIndexOf("/") + 1)}
                            </small>
                          </p>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingAcceptAlert ? (
        <SweetAlert
          success
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, Confirm it!"
          confirmBtnBsStyle="primary"
          title="Are you sure?"
          onConfirm={acceptConsultation}
          focusCancelBtn
        >
          You will confirm this consultation!
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteConsultation}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
