import React from "react";
import { useState } from "react";
import axios from "axios";
import { getUser } from "../functions/reusables";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default function TableHead({
  isServerSide,
  onSearch,
  link,
  isDownloading,
  tableData,
  fields,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  function searchList(searchTerm, list, fields) {
    if (!searchTerm || searchTerm.trim() === "") {
      return list; // Return the original list if the search term is empty
    }
    const filteredList = list.filter((item) =>
      fields.some((field) =>
        item[field].toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    return filteredList;
  }

  const handleSearch = () => {
    let filteredList = [];
    if (!isServerSide) {
      filteredList = searchList(searchTerm, tableData, fields);
      onSearch(filteredList);
    } else {
      onSearch(filteredList, searchTerm);
    }
  };

  const clearSearch = () => {
    const filteredList = searchList("", tableData, fields);
    onSearch(filteredList, "");
  };

  const downloadFileServerSide = async (downloadType, _searchTerm) => {
    isDownloading(true);
    console.log(`${link}&ext_type=${downloadType}&q=${_searchTerm}`);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${link}&ext_type=${downloadType}&q=${_searchTerm}`,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      getFile(response.data.message);
    } catch (error) {
      console.log(error);
      isDownloading(false);
    }
  };

  const getFile = async (filename) => {
    console.log(
      `${process.env.REACT_APP_MAIN}download/get_down/?file_name=${filename}`
    );
    try {
      const link = document.createElement("a");

      link.href = `${process.env.REACT_APP_MAIN}download/get_down/?file_name=${filename}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      isDownloading(false);
    } catch (error) {
      console.log(error);
      isDownloading(false);
    }
  };

  const downloadAsCSV = () => {
    console.log("downloading client csv");
    const fileType = "text/csv;charset=utf-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "tableData.csv");
  };

  const downloadAsExcel = () => {
    console.log("downloading client excel");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "tableData.xlsx");
  };
  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="button-list">
            <button
              type="button"
              className="btn btn-dark waves-effect waves-light"
              onClick={() => {
                console.log("hello");
                console.log(isServerSide);
                if (isServerSide) {
                  downloadFileServerSide("excel", searchTerm);
                } else {
                  downloadAsExcel();
                }
              }}
            >
              <i className="mdi mdi-file-excel me-1"></i> Excel
            </button>

            <button
              type="button"
              className="btn btn-dark waves-effect waves-light"
              onClick={() => {
                if (isServerSide) {
                  downloadFileServerSide("csv", searchTerm);
                } else {
                  downloadAsCSV();
                }
              }}
            >
              <i className="mdi mdi-file-document me-1"></i> CSV
            </button>
          </div>
          <div
            class="input-group"
            style={{ maxWidth: "380px", position: "relative" }}
          >
            <input
              type="text"
              class="form-control"
              placeholder="Search table.."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <span>
              {searchTerm.trim().length > 0 ? (
                <button
                  className="btn"
                  style={{ position: "absolute", right: "75px", zIndex: "999" }}
                  onClick={() => {
                    setSearchTerm("");
                    clearSearch();
                  }}
                >
                  X
                </button>
              ) : (
                <div></div>
              )}
            </span>
            <button
              class="btn input-group-text btn-dark waves-effect waves-light"
              type="button"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
