import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import Select from "react-select";
import TableHead from "../../component/table-head";

export default function CenterManageVitals() {
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingViewAlert, setShowingViewAlert] = useState(false);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingShareAlert, setShowingShareAlert] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [docoptions, setDocOptions] = useState([]);
  const [doc_pid, setDocPid] = useState("");
  const [docName, setDocName] = useState("");
  // const [selectedImage, setProblemImages] = useState([])

  const modules = {
    toolbar: [],
  };

  const formats = [[]];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Patients Name",
      selector: (row) => row.pat_name,
      sortable: true,
    },
    {
      name: "Pulse",
      selector: (row) => row.pulse,
      sortable: true,
    },
    {
      name: "Pulse Type",
      selector: (row) => row.pulse_type,
      sortable: true,
    },
    {
      name: "Temperature",
      selector: (row) => row.temperature,
      sortable: true,
    },
    {
      name: "Weight",
      selector: (row) => row.weight,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => convertFromISODate(row.vitals_date),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.cons_app_status == 1 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-info ">Pending</span>{" "}
          </h5>
        ) : row.cons_app_status == 2 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-danger ">Resheduled</span>{" "}
          </h5>
        ) : row.cons_app_status == 3 ? (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-success ">Confirmed</span>{" "}
          </h5>
        ) : (
          <h5 className="fs-14 my-1 fw-normal">
            <span className="badge badge-soft-dark ">Completed</span>{" "}
          </h5>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "170px",
      cell: (row) => (
        <div>
          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-info btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingViewAlert(true);
            }}
          >
            <i className="fe-eye"></i>
          </button>
          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-primary btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingShareAlert(true);
            }}
          >
            <i className="fe-share"></i>
          </button>
          <button
            type="button"
            style={{ marginLeft: "5px" }}
            className="btn btn-danger btn-icon waves-effect waves-light"
            onClick={() => {
              setSelectedRow(row);
              setShowingDeleteAlert(true);
            }}
          >
            <i className="fe-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllVitals(1);
    await getDoctors();
  };
  const fetchAllVitals = async (page, searchTerm) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_MAIN
        }vitals/all/?_limit=${newPerPage}&_page=${page}&org_pid=${
          getUser()["org_pid"]
        }&q=${searchTerm ?? ""}`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllVitals(page);
  };

  const shareVitals = async () => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}vitals/share/${selectedRow.vt_pid}/${doc_pid}/${selectedRow.org_pid}`,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingShareAlert(false);
      successToast("Vital Shared with " + docName + " Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingShareAlert(false);
    }
  };
  const deleteVitals = async () => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_MAIN}vitals/delete/${selectedRow.vt_pid}/2/${selectedRow.org_pid}`,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      setLoading(false);
      setShowingDeleteAlert(false);
      successToast("Vital Deleted Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
      setShowingDeleteAlert(false);
    }
  };

  const onClose = () => {
    setShowingViewAlert(false);
    setShowingDeleteAlert(false);
    setShowingShareAlert(false);
  };

  const selectDoctor = (value) => {
    setDocPid(value["value"]);
    setDocName(value["label"]);
  };

  const getDoctors = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}doctors/list_all/`,
        {
          headers: headers,
        }
      );
      let unsetOptions = response.data.data;
      let newOptions = [];
      for (let index = 0; index < unsetOptions.length; index++) {
        const doctors = {
          value: unsetOptions[index]["doc_pid"],
          label: unsetOptions[index]["doc_name"],
        };
        newOptions.push(doctors);
      }
      setDocOptions(newOptions);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
    }
  };

  useEffect(() => {
    changeTitle("Manage Vitals | DOC-ROA");
    performTasks();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Manage Vitals</h4>
                  <TableHead
                    tableData={data}
                    isServerSide={true}
                    isDownloading={(isLoading) => setLoading(isLoading)}
                    link={`${process.env.REACT_APP_MAIN}vitals/all/?org_pid=${
                      getUser()["org_pid"]
                    }`}
                    onSearch={(newList, _searchTerm) => {
                      if (_searchTerm != undefined) {
                        fetchAllVitals(1, _searchTerm);
                      } else {
                        setData(newList);
                      }
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10]}
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingViewAlert ? (
        <SweetAlert
          title="View Vitals!"
          style={{ minWidth: "700px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body">
              <div className="card-body">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="bp" class="form-label">
                      Patient Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.pat_name}
                      id="bp"
                      placeholder="Enter Patients BP E.g 78/90"
                      disabled
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="bp" class="form-label">
                      BP
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.bp}
                      id="bp"
                      placeholder="Enter Patients BP E.g 78/90"
                      disabled
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="bpPos" class="form-label">
                      BP Position
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.bp_position}
                      id="bpPos"
                      placeholder="Enter BP Position E.g SITTING"
                      disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-md-4">
                    <label for="pulsr" class="form-label">
                      Pulse
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Patients Pulse"
                      value={selectedRow.pulse}
                      id="pulsr"
                      disabled
                    />
                  </div>

                  <div class="mb-3 col-md-4">
                    <label for="pulsetype" class="form-label">
                      Pulse Type
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.pulse_type}
                      placeholder="Enter Pulse Type E.g RADIAL"
                      id="pulsetype"
                      disabled
                    />
                  </div>

                  <div class="mb-3 col-md-4">
                    <label for="temperature" class="form-label">
                      Temperature (°C)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.temperature}
                      id="temperature"
                      placeholder="Enter Patients Temperature"
                      disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-md-4">
                    <label for="weight" class="form-label">
                      Weight (KG)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Patients Weight"
                      value={selectedRow.weight}
                      id="weight"
                      disabled
                    />
                  </div>

                  <div class="mb-3 col-md-4">
                    <label for="height" class="form-label">
                      Height (CM)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.height}
                      placeholder="Enter Patients Height"
                      id="height"
                      disabled
                    />
                  </div>

                  <div class="mb-3 col-md-4">
                    <label for="spo2" class="form-label">
                      SPO2
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={selectedRow.spo2}
                      id="spo2"
                      placeholder="Enter Patients SPO2"
                      disabled
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label for="inputAddress" class="form-label">
                    Remark
                  </label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    value={selectedRow.remark}
                    placeholder="Enter Remark"
                    disabled
                    rows="6"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}
      {isShowingShareAlert ? (
        <SweetAlert
          title="Share Vitals!"
          style={{ minWidth: "700px", justifyContent: "flex-start" }}
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="card" style={{ textAlign: "start" }}>
            <div className="card-body">
              <div className="card-body">
                <div class="row">
                  <div class="mb-3 col-md-12">
                    <label for="pulsr" class="form-label">
                      Select Doctor
                    </label>
                    <Select
                      options={docoptions}
                      isSearchable={true}
                      isClearable={true}
                      onChange={selectDoctor}
                    />
                    <button
                      class="btn btn-primary waves-effect waves-light mt-3"
                      onClick={() => {
                        shareVitals();
                      }}
                    >
                      Share Vitals
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      {isShowingDeleteAlert ? (
        <SweetAlert
          warning
          showCancel
          onCancel={onClose}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteVitals}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
