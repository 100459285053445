import ImageViewer from "awesome-image-viewer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import LoadingScreen from "react-loading-screen";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import {
  changeTitle,
  convertFrom12To24Format,
  convertFrom24To12Format,
  convertFromISODate,
  convertToHumanReadableDate,
  errorToast,
  getLoggedInRole,
  getUser,
  returnParsedHtml,
  successToast,
} from "../../functions/reusables";
import Notification from "../../component/notification";
import { useNavigate } from "react-router-dom";

export default function TestList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newPerPage, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isShowingDeleteAlert, setShowingDeleteAlert] = useState(false);
  const [isShowingUpdateAlert, setShowingUpdateAlert] = useState(false);
  const [test, setTestName] = useState("");
  const [updateTestText, setUpdateTest] = useState("");
  const [selectedRow, setSelectedRow] = useState({});

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dce1e6",
        },
      },
    },
    rows: {
      style: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dce1e6",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dce1e6",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.test_name,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      width: "110px",
      cell: (row) => (
        <div>
          <button
            type="button"
            className="btn btn-info btn-icon waves-effect waves-light"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setSelectedRow(row);
              setUpdateTest(row.test_name);
              setShowingUpdateAlert(true);
            }}
          >
            <i className="fe-edit"></i>
          </button>
        </div>
      ),
    },
  ];

  const performTasks = async () => {
    await fetchAllTest(1);
  };
  const fetchAllTest = async (page) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}tests_list/all/`,
        {
          headers: headers,
        }
      );
      setData(response.data.data ?? []);
      setTotalRows(response.data.recordsTotal ?? 0);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchAllTest(page);
  };

  useEffect(() => {
    changeTitle("Add Test to List| DOC-ROA");
    performTasks();
  }, []);

  const addTest = async () => {
    setLoading(true);
    let req = {
      test_name: test,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}tests_list/create/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      successToast("Test Added to List Successfully");
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  };
  const updateTest = async () => {
    setLoading(true);
    let req = {
      test_pid: selectedRow.test_pid,
      test_name: updateTestText,
    };
    console.log(req);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}tests_list/update/`,
        req,
        {
          headers: headers,
        }
      );
      setLoading(false);
      setShowingUpdateAlert(false);
      successToast("Test Edited Successfully");
    } catch (error) {
      console.log(error);
      setShowingUpdateAlert(false);
      errorToast(error.message);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowingUpdateAlert(false);
  };

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="header-title mb-3">Add Test Name</h4>
                      <label for="name" class="form-label">
                        Name of Test
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Enter Test Name"
                        value={test}
                        onChange={(e) => {
                          setTestName(e.target.value);
                        }}
                      />
                      <button
                        class="btn btn-primary waves-effect waves-light mt-2"
                        onClick={() => {
                          addTest();
                        }}
                      >
                        Add Test to List
                      </button>
                    </div>
                    <div className="col-md-8">
                      <h4 className="header-title mb-3">All Tests</h4>
                      <DataTable
                        // title="Users"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        // paginationServer
                        paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}

                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        // actions={actionsMemo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowingUpdateAlert ? (
        <SweetAlert
          title="Update Test Name!"
          showCloseButton
          showConfirm={false}
          onCancel={onClose}
        >
          <div className="mb-3">
            <label for="example-date" className="form-label">
              Test
            </label>
            <input
              className="form-control"
              id="example-date"
              type="text"
              value={updateTestText}
              name="date"
              onChange={(e) => {
                setUpdateTest(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-dark waves-effect waves-light"
            onClick={() => {
              updateTest();
            }}
          >
            Update Test Name
          </button>
        </SweetAlert>
      ) : (
        <div></div>
      )}

      <ToastContainer />
    </div>
  );
}
