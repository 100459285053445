import React, { useEffect, useRef, useState } from "react";
import {
  base64ToFile,
  changeTitle,
  convertFromISODate,
  errorToast,
  errorToastStay,
  getUser,
  infoToast,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "react-loading-screen";
import Webcam from "react-webcam";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";
import Notification from "../../component/notification";

export default function CenterAddVitals() {
  const [pat_pid, setPatPid] = useState("");
  const [loading, setLoading] = useState(false);
  const [bp, setBP] = useState("");
  const [bpPosition, setBPPosition] = useState("");
  const [pulse, setPulse] = useState("");
  const [pulseType, setPulseType] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [spo2, setSPO] = useState("");
  const [remark, setRemark] = useState("");
  const [options, setOptions] = useState([]);

  const resetForm = () => {
    setPatPid("");
    setBP("");
    setBPPosition("");
    setPulse("");
    setPulseType("");
    setTemperature("");
    setWeight("");
    setHeight("");
    setSPO("");
    setRemark("");
  };

  const addVitals = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };

    const data = {
      org_pid: getUser()["org_pid"],
      pat_pid: pat_pid,
      center_pid: getUser()["user_pid"],
      remark: remark,
      bp: bp,
      bp_position: bpPosition,
      pulse: pulse,
      pulse_type: pulseType,
      weight: weight + " KG",
      temperature: temperature + " °C",
      height: height + " CM",
      spo2: spo2,
    };

    console.log(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}vitals/create/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Vitals added Successfully");
      setLoading(false);
      resetForm();
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  const getPatients = async () => {
    try {
      // setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };

      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}patients/list_all/`,
        {
          headers: headers,
        }
      );
      let unsetOptions = response.data.data;
      let newOptions = [];
      for (let index = 0; index < unsetOptions.length; index++) {
        const patient = {
          value: unsetOptions[index]["pat_pid"],
          label: unsetOptions[index]["pat_name"],
        };
        newOptions.push(patient);
      }
      console.log(newOptions);
      setOptions(newOptions);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // errorToast(error?.message)
    }
  };

  const selectPatient = (value) => {
    console.log(value);
    if (value == null) {
      setPatPid("");
      return;
    }
    setPatPid(value["value"]);
  };

  useEffect(() => {
    changeTitle("Add Vitals | DOC-ROA");
    getPatients();
  }, []);

  return loading ? (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#9ee5f8"
        textColor="#77d5cc"
        logoSrc="assets/images/logo-dark.png"
        text="..."
      ></LoadingScreen>
      <ToastContainer />
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="row">
                <Notification />
              </div>
              <div className="card" style={{ backgroundColor: "transparent" }}>
                <div
                  className="card-header"
                  style={{ backgroundColor: "white" }}
                >
                  <h4 class="header-title">Add Vitals</h4>
                  <p class="text-muted font-13">Add Patients Vitals</p>
                </div>

                <div class="mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card ">
                        <div className="card-header">
                          <h5 className="header-title ">Fill Vitals Form</h5>
                        </div>
                        <div className="card-body">
                          <div class="row">
                            <div class="col-md-4 mb-3">
                              <div className="">
                                <label for="pass" class="form-label">
                                  Select Patient
                                </label>

                                <Select
                                  options={options}
                                  isSearchable={true}
                                  isClearable={true}
                                  onChange={selectPatient}
                                />
                              </div>
                            </div>
                            <div class="col-md-4 mb-3">
                              <label for="bp" class="form-label">
                                BP
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={bp}
                                id="bp"
                                placeholder="Enter Patients BP E.g 78/90"
                                onChange={(e) => {
                                  setBP(e.target.value);
                                }}
                              />
                            </div>
                            <div class="col-md-4 mb-3">
                              <label for="bpPos" class="form-label">
                                BP Position
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={bpPosition}
                                id="bpPos"
                                placeholder="Enter BP Position E.g SITTING"
                                onChange={(e) => {
                                  setBPPosition(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="mb-3 col-md-4">
                              <label for="pulsr" class="form-label">
                                Pulse
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Patients Pulse"
                                value={pulse}
                                id="pulsr"
                                onChange={(e) => {
                                  setPulse(e.target.value);
                                }}
                              />
                            </div>

                            <div class="mb-3 col-md-4">
                              <label for="pulsetype" class="form-label">
                                Pulse Type
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={pulseType}
                                placeholder="Enter Pulse Type E.g RADIAL"
                                id="pulsetype"
                                onChange={(e) => {
                                  setPulseType(e.target.value);
                                }}
                              />
                            </div>

                            <div class="mb-3 col-md-4">
                              <label for="temperature" class="form-label">
                                Temperature (°C)
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={temperature}
                                id="temperature"
                                placeholder="Enter Patients Temperature"
                                onChange={(e) => {
                                  setTemperature(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="mb-3 col-md-4">
                              <label for="weight" class="form-label">
                                Weight (KG)
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Patients Weight"
                                value={weight}
                                id="weight"
                                onChange={(e) => {
                                  setWeight(e.target.value);
                                }}
                              />
                            </div>

                            <div class="mb-3 col-md-4">
                              <label for="height" class="form-label">
                                Height (CM)
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={height}
                                placeholder="Enter Patients Height"
                                id="height"
                                onChange={(e) => {
                                  setHeight(e.target.value);
                                }}
                              />
                            </div>

                            <div class="mb-3 col-md-4">
                              <label for="spo2" class="form-label">
                                SPO2
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={spo2}
                                id="spo2"
                                placeholder="Enter Patients SPO2"
                                onChange={(e) => {
                                  setSPO(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div class="mb-3">
                            <label for="inputAddress" class="form-label">
                              Remark
                            </label>
                            <textarea
                              type="text"
                              class="form-control"
                              id="inputAddress"
                              value={remark}
                              placeholder="Enter Remark"
                              rows="6"
                              onChange={(e) => {
                                setRemark(e.target.value);
                              }}
                            ></textarea>
                          </div>

                          <button
                            class="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              addVitals();
                            }}
                          >
                            Add Vitals
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
