import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  changeTitle,
  checkIfLogin,
  fixDropdownName,
  getLoggedInRole,
  reverseFixDropdownName,
} from "../functions/reusables";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "react-loading-screen";

export default function Login() {
  const location = useLocation();

  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgRoleText, setOrgRoleText] = useState("");
  const [orgRole, setOrgRole] = useState(0);
  const [orgId, setOrgID] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [shouldShowOrg, setShouldShowOrg] = useState(true);

  const fetchRoles = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}roles/all/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data);
    setRoles(Object.entries(response.data.data));
    setOrgDetails(Object.entries(response.data.data));
  };

  const setOrgDetails = (rolesList) => {
    console.log(rolesList);

    for (let index = 0; index < rolesList.length; index++) {
      const searchParams = new URLSearchParams(location.search);
      const search = searchParams.get("user");
      const org_name = searchParams.get("org_name");
      console.log(search);

      if (search == null) {
        console.log("No User selected from Homepage");
        window.location.href = process.env.REACT_APP_HOMEPAGE;
        return;
      }

      if (search == "organization") {
        console.log(org_name);
        setShouldShowOrg(false);
        setOrgName(org_name);
      }
      const roleKey = rolesList[index][0];
      const roleValue = rolesList[index][1];
      console.log(search); // Output: 'react'
      if (search == roleKey) {
        setOrgRoleText(roleKey);
        setOrgRole(roleValue);
        console.log("Found ", roleKey);
        console.log("Found ", roleValue);
        return;
      }
    }
    console.log("Did not find a user");
  };

  const fetchOrganization = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${process.env.REACT_APP_MAIN}org/list_all/`,
      {
        headers: headers,
      }
    );
    console.log(response.data.data);
    setOrganization(response.data.data);
  };

  const loginUser = async () => {
    setLoading(true);
    setErrorMessage("");
    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      email: email,
      password: password,
      org_pid: parseInt(orgId),
      org_role: parseInt(orgRole),
    };

    console.log(data);
    let roleUrl = orgRoleText;
    if (orgRoleText === "health_center") {
      roleUrl = "center";
    }
    if (orgRoleText === "organization_user") {
      roleUrl = "org_user";
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}auth/login/${roleUrl}/`,
        data,
        {
          headers: headers,
        }
      );
      //  let isCorrectRole = checkIfCorrectRole(response.data)
      //  if(isCorrectRole){
      //   localStorage.setItem("user_detail", JSON.stringify(response.data));
      //   setLoading(false);
      //   navigate("/dashboard");
      //  }else{
      //   setErrorMessage("Wrong Role. Not permitted to Log In.")
      //   setLoading(false);
      //  }
      console.log(response.data);
      localStorage.setItem("user_detail", JSON.stringify(response.data));
      localStorage.setItem("user_role", orgRoleText);
      console.log(orgRoleText);
      navigate(`/${getLoggedInRole()}-dashboard`);
    } catch (error) {
      console.log(error.response?.data?.message);
      setErrorMessage(error.response.data?.message);
      setLoading(false);
    }
  };

  const checkIfUserIsLoggedIn = async () => {
    let isLogin = await checkIfLogin();
    if (isLogin) {
      navigate(`/${getLoggedInRole()}-dashboard`);
    } else {
      navigate("/");
      // setPageLoading(false);
    }
  };

  const performLoginTasks = async () => {
    changeTitle("Login | DOC-ROA");
    await checkIfUserIsLoggedIn();
    await fetchRoles();
    await fetchOrganization();
    setLoading(false);
  };

  useEffect(() => {
    performLoginTasks();
  }, []);

  return loading ? (
    <LoadingScreen
      loading={loading}
      bgColor="#ffffff"
      spinnerColor="#9ee5f8"
      textColor="#77d5cc"
      logoSrc="assets/images/logo-dark.png"
      text="..."
    ></LoadingScreen>
  ) : (
    <div
      className=" authentication-bg authentication-bg-pattern"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="account-pages"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <div className="text-center">
                <a href="index.html">
                  <img
                    src="assets/images/logo-dark.png"
                    alt=""
                    height="70"
                    className="mx-auto"
                  />
                </a>
                <p className="text-muted mt-2 mb-4">Docroa Admin Dashboard</p>
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mb-4">
                    <h4 className="text-uppercase mt-0">
                      {orgName == "" ? fixDropdownName(orgRoleText) : orgName}{" "}
                      Sign In
                    </h4>
                  </div>

                  <div>
                    {/* <div className="form-floating mb-3">
                                        <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e)=>{
                                          setOrgRoleText(reverseFixDropdownName(e.target.options[e.target.selectedIndex].text))
                                          setOrgRole(e.target.value)
                                        }}>
                                            <option selected="">Choose a user</option>
                                            {
                                              roles.map((role)=>(
                                                <option value={role[1]}>{fixDropdownName(role[0])}</option>
                                              ))
                                            }
                                        </select>
                                        <label for="floatingSelect">User Role</label>
                                    </div> */}
                    {shouldShowOrg ? (
                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            setOrgID(e.target.value);
                          }}
                        >
                          <option selected="">Choose Organization</option>
                          {organizations.map((organization) => (
                            <option value={organization["org_pid"]}>
                              {organization["org_name"]}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Organization</label>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div className="mb-3">
                      <label for="emailaddress" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="emailaddress"
                        required=""
                        placeholder="Enter your email"
                        onChange={(e) => {
                          setErrorMessage("");
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <label for="password" className="form-label">
                        Password
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        required=""
                        id="password"
                        placeholder="Enter your password"
                        onChange={(e) => {
                          setErrorMessage("");
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="checkbox-signin"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="checkbox-signin"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    {errorMessage != "" ? (
                      <div class="card text-white bg-danger">
                        <div
                          class="card-body"
                          style={{ padding: "10px 10px", textAlign: "center" }}
                        >
                          <p>{errorMessage}</p>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="mb-3 d-grid text-center">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={loginUser}
                      >
                        {" "}
                        Log In{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
