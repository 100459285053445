import React, { useEffect, useRef, useState } from "react";
import {
  base64ToFile,
  changeTitle,
  convertFromISODate,
  errorToast,
  errorToastStay,
  getUser,
  infoToast,
  successToast,
} from "../../functions/reusables";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "react-loading-screen";
import Webcam from "react-webcam";
import SignatureCanvas from "react-signature-canvas";

export default function LabSettings() {
  const [loading, setLoading] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [hasCaptured, setHasCaptured] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [lab_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [lab_phone, setPhone] = useState("");
  const [lab_address, setAddress] = useState("");
  const [lab_gender, setGender] = useState("");
  const [lab_state, setState] = useState("");
  const [dept_pid, setDeptPid] = useState(null);
  const [dept, setDept] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const fileInputRef = useRef(null);
  const [sigCanvas, setSigCanvas] = useState(null);

  const resetForm = () => {
    setIsCapturing(false);
    setHasCaptured(false);
    setCapturedImage(null);
    setSelectedImageFile(null);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setGender("");
    setState("");
    setDeptPid(null);
    setDept([]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    // setSe
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      console.log(reader.result);
      setCapturedImage(reader.result);
      setSelectedImageFile(reader.result);
      setHasCaptured(true);
    };
  };

  const videoConstraints = {
    width: 200,
    height: 250,
    facingMode: "user",
  };

  const getDepartmentTable = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}med_depts/all/`,
        {
          headers: headers,
        }
      );
      console.log(response.data.data);
      setDept(response.data.data);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        errorToast("Error loading Departments");
      }, 1000);
    }
  };
  const getProfile = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUser()["token"],
      };
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN}auth/details/laboratory/`,
        {
          headers: headers,
        }
      );
      let data = response.data.data;
      setHasCaptured(true);
      setCapturedImage(data.photo);
      setName(data.lab_name);
      setEmail(data.email);
      setPhone(data.lab_phone);
      setAddress(data.lab_address);
      setState(data.lab_state);
    } catch (error) {
      console.log(error);
      if (error.response.data.message == "No User Found") {
        errorToastStay("Update your Signature to Activate your Account!");
        return;
      }
      errorToast(error.response.data.message);
    }
  };

  const updateProfile = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };

    const data = {
      org_pid: getUser()["org_pid"],
      lab_name,
      email,
      lab_phone,
      lab_address,
      // lab_gender,
      lab_state,
      // dept_pid
    };

    console.log(data);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}auth/update_details/laboratory/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Profile Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
    // uploadPhoto(1)
  };

  const uploadSignature = async () => {
    setLoading(true);
    let base64Signature = sigCanvas.toDataURL();
    console.log(base64Signature);
    const fileName = "signature-" + getUser()["user_pid"].toString(); // Or 'image.png' for PNG format
    const imageFile = base64ToFile(base64Signature, fileName);

    const formData = new FormData();
    console.log(imageFile);
    formData.append("signature", imageFile);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}labs/update_signature/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for multipart/form-data
            Authorization: "Bearer " + getUser()["token"],
          },
        }
      );
      successToast("Signature Updated Successfully");
      getProfile();
      setLoading(false);
    } catch (error) {
      console.error("Failed to upload Signature:", error);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  const uploadPhoto = async () => {
    setLoading(true);
    infoToast("Uploading Photo");

    const fileName = getUser()["user_pid"].toString(); // Or 'image.png' for PNG format
    const imageFile = base64ToFile(selectedImageFile, fileName);

    const formData = new FormData();
    console.log(imageFile);
    formData.append("photo", imageFile);
    formData.append("lab_pid", getUser()["user_pid"]);
    formData.append("org_pid", getUser()["org_pid"]);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}labs/update_photo/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for multipart/form-data
            Authorization: "Bearer " + getUser()["token"],
          },
        }
      );
      successToast("Photo Uploaded Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Failed to upload image:", error);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  const updatePassword = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUser()["token"],
    };

    const data = {
      org_pid: getUser()["org_pid"],
      new_password: password,
    };

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_MAIN}auth/change_password/laboratory/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response);
      successToast("Password Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.log(error.response?.data?.message);
      errorToast(error.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    changeTitle("Edit Profile | DOC-ROA");
    // getDepartmentTable();
    getProfile();
  }, []);

  return loading ? (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#9ee5f8"
        textColor="#77d5cc"
        logoSrc="assets/images/logo-dark.png"
        text="..."
      ></LoadingScreen>
      <ToastContainer />
    </div>
  ) : (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card" style={{ backgroundColor: "transparent" }}>
                <div
                  className="card-header"
                  style={{ backgroundColor: "white" }}
                >
                  <h4 class="header-title">Edit Profile</h4>
                  <p class="text-muted font-13">
                    Update your profile, photo and password
                  </p>
                </div>
                <div class="mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="header-title ">Update Photo</h5>
                        </div>
                        <div className="card-body ">
                          {isCapturing ? (
                            <Webcam
                              audio={false}
                              height={250}
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                            >
                              {({ getScreenshot }) => (
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    class="btn btn-success waves-effect waves-light"
                                    onClick={() => {
                                      const imageSrc = getScreenshot();
                                      setHasCaptured(true);
                                      setIsCapturing(false);
                                      setCapturedImage(imageSrc);
                                      setSelectedImageFile(imageSrc);
                                    }}
                                  >
                                    <span class="btn-label">
                                      <i class="fe-camera"></i>
                                    </span>
                                    Capture photo
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-danger waves-effect waves-light"
                                    onClick={() => {
                                      setIsCapturing(false);
                                    }}
                                  >
                                    <i class="mdi mdi-close"></i>
                                  </button>
                                </div>
                              )}
                            </Webcam>
                          ) : (
                            <div
                              style={{
                                backgroundColor: "#dedfdf",
                                width: "100%",
                                height: "250px",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {hasCaptured && capturedImage !== "" ? (
                                <img
                                  src={capturedImage}
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                  }}
                                />
                              ) : (
                                <i
                                  class="mdi mdi-face-recognition"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "150px",
                                    color: "white",
                                  }}
                                ></i>
                              )}
                            </div>
                          )}
                          {!isCapturing ? (
                            <div
                              className="d-flex mt-2"
                              style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <button
                                type="file"
                                class="btn btn-secondary waves-effect waves-light btn-xs"
                                onClick={handleButtonClick}
                              >
                                Choose Photo
                              </button>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileSelect}
                              />
                              <p>or</p>
                              <button
                                type="button"
                                class={`btn btn-${
                                  isCapturing ? "danger" : "dark"
                                } waves-effect waves-light btn-xs`}
                                onClick={() => {
                                  setIsCapturing(isCapturing ? false : true);
                                }}
                              >
                                {isCapturing
                                  ? "Stop Capture"
                                  : "Camera Capture"}
                              </button>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            uploadPhoto();
                          }}
                        >
                          Update Photo
                        </button>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="header-title">
                            Update Signature
                            <span
                              class="btn btn-link mdi mdi-close text-danger float-end"
                              style={{ padding: "0px" }}
                              onClick={() => {
                                sigCanvas.clear();
                              }}
                            >
                              clear
                            </span>
                          </h5>
                        </div>

                        <div className="card-body">
                          <SignatureCanvas
                            canvasProps={{ className: "sigCanvas1" }}
                            ref={(ref) => {
                              setSigCanvas(ref);
                            }}
                          />
                        </div>
                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            uploadSignature();
                          }}
                        >
                          Update Signature
                        </button>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card ">
                        <div className="card-header">
                          <h5 className="header-title ">Update Profile</h5>
                        </div>
                        <div className="card-body">
                          <div class="row">
                            <div class="col-md-6 mb-3">
                              <label for="name" class="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                value={lab_name}
                                placeholder="Enter Doctors Name"
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="pass" class="form-label">
                                Email
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                value={email}
                                id="pass"
                                placeholder="Enter Doctors Email"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="mb-3 col-md-4">
                              <label for="phoneno" class="form-label">
                                Phone
                              </label>
                              <input
                                type="phone"
                                class="form-control"
                                value={lab_phone}
                                id="phoneno"
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                }}
                              />
                            </div>

                            <div class="mb-3 col-md-4">
                              <label for="inputState" class="form-label">
                                State
                              </label>
                              <select
                                id="inputState"
                                class="form-select"
                                value={lab_state}
                                onChange={(e) => {
                                  setState(e.target.value);
                                }}
                              >
                                <option value="">Select State</option>
                                <option value="Abia">Abia</option>
                                <option value="Adamawa">Adamawa</option>
                                <option value="Akwa Ibom">Akwa Ibom</option>
                                <option value="Anambra">Anambra</option>
                                <option value="Bauchi">Bauchi</option>
                                <option value="Bayelsa">Bayelsa</option>
                                <option value="Benue">Benue</option>
                                <option value="Borno">Borno</option>
                                <option value="Cross River">Cross River</option>
                                <option value="Delta">Delta</option>
                                <option value="Ebonyi">Ebonyi</option>
                                <option value="Edo">Edo</option>
                                <option value="Ekiti">Ekiti</option>
                                <option value="Enugu">Enugu</option>
                                <option value="FCT">FCT</option>
                                <option value="Gombe">Gombe</option>
                                <option value="Imo">Imo</option>
                                <option value="Jigawa">Jigawa</option>
                                <option value="Kaduna">Kaduna</option>
                                <option value="Kano">Kano</option>
                                <option value="Katsina">Katsina</option>
                                <option value="Kebbi">Kebbi</option>
                                <option value="Kogi">Kogi</option>
                                <option value="Kwara">Kwara</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Nasarawa">Nasarawa</option>
                                <option value="Niger">Niger</option>
                                <option value="Ogun">Ogun</option>
                                <option value="Ondo">Ondo</option>
                                <option value="Osun">Osun</option>
                                <option value="Oyo">Oyo</option>
                                <option value="Plateau">Plateau</option>
                                <option value="Rivers">Rivers</option>
                                <option value="Sokoto">Sokoto</option>
                                <option value="Taraba">Taraba</option>
                                <option value="Yobe">Yobe</option>
                                <option value="Zamfara">Zamfara</option>
                              </select>
                            </div>
                          </div>

                          <div class="mb-3">
                            <label for="inputAddress" class="form-label">
                              Address
                            </label>
                            <textarea
                              type="text"
                              class="form-control"
                              id="inputAddress"
                              value={lab_address}
                              placeholder="1234 Main St"
                              rows="6"
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            ></textarea>
                          </div>

                          <button
                            class="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              updateProfile();
                            }}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card bg-secondary mt-4">
                            <h5 className="mb-2 card-title card-header bg-dark text-white">
                              Change Password
                            </h5>
                            <div className="card-body">
                              <div class="col-md-12 mb-3">
                                <label
                                  for="name"
                                  class="form-label"
                                  className="text-white"
                                >
                                  Password
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="name"
                                  value={password}
                                  placeholder="Enter Password"
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                  }}
                                />
                              </div>
                              <div class="col-md-12 mb-3">
                                <label
                                  for="name"
                                  class="form-label"
                                  className="text-white"
                                >
                                  Confirm Password
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="name"
                                  value={confirmpassword}
                                  placeholder="Confirm Password"
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                  }}
                                />
                              </div>
                              <button
                                class="btn btn-primary waves-effect waves-light"
                                onClick={() => {
                                  // registerDoctor()
                                  if (confirmpassword !== password) {
                                    errorToast("Passwords do not match");
                                    return;
                                  }
                                  if (
                                    confirmpassword === "" ||
                                    password === ""
                                  ) {
                                    errorToast("Passwords cannot be empty");
                                    return;
                                  }
                                  updatePassword();
                                }}
                              >
                                Update Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <script>document.write(new Date().getFullYear())</script> &copy;
              DOC-ROA 2023 <a href="#"> by GENTION GLOBAL RESOURCES</a>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}
