import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { SidebarLayout } from "./component/header-side";
import DoctorBookAppointment from "./pages/Doctor/DoctorBookAppointment";
import DoctorDashboard from "./pages/Doctor/DoctorDashboard";
import DoctorMyConsultation from "./pages/Doctor/DoctorMyConsultation";
import Login from "./pages/Login";
import DoctorMyConsultationComplete from "./pages/Doctor/DoctorMyConsultationComplete";
import DoctorReferal from "./pages/Doctor/DoctorReferal";
import DoctorStoreForward from "./pages/Doctor/DoctorStoreForward";
import DoctorTestResults from "./pages/Doctor/DoctorTestResults";
import DoctorPrescriptions from "./pages/Doctor/DoctorPrescriptions";
import OrganizationDashboard from "./pages/Organization/OrganizationDashboard";
import OrganizationCreateDoctor from "./pages/Organization/OrganizationCreateDoctor";
import OrganizationManageDoctors from "./pages/Organization/OrganizationManageDoctors";
import OrganizationCreatePatient from "./pages/Organization/OrganizationCreatePatient";
import OrganizationManagePatients from "./pages/Organization/OrganizationManagePatients";
import OrganizationSpecialty from "./pages/Organization/OrganizationSpecialty";
import OrganizationConsultations from "./pages/Organization/OrganizationConsultations";
import OrganizationStoreForward from "./pages/Organization/OrganizationStoreForward";
import OrganizationTestResults from "./pages/Organization/OrganizationTestResults";
import OrganizationPrescriptions from "./pages/Organization/OrganizationPrescriptions";
import PatientDashboard from "./pages/Patient/PatientDashboard";
import PatientConsultations from "./pages/Patient/PatientConsultations";
import PatientStoreForward from "./pages/Patient/PatientStoreForward";
import PatientTestResults from "./pages/Patient/PatientTestResults";
import DoctorSettings from "./pages/Doctor/DoctorSettings";
import OrganizationCreateClinic from "./pages/Organization/OrganizationCreateClinic";
import OrganizationManageClinic from "./pages/Organization/OrganizationManageClinic";
import OrganizationEditClinic from "./pages/Organization/OrganizationEditClinic";
import CenterDashboard from "./pages/Clinic/CenterDashboard";
import CenterConsultations from "./pages/Clinic/CenterConsultations";
import CenterPatientTestResults from "./pages/Clinic/CenterTestResults";
import CenterStoreForward from "./pages/Clinic/CenterStoreForward";
import CenterPrescriptions from "./pages/Clinic/CenterPrescriptions";
import OrganizationUpdateDoctor from "./pages/Organization/OrganizationUpdateDoctor";
import OrganizationUpdatePatient from "./pages/Organization/OrganizationUpdatePatient";
import OrganizationSpecialtyDoctors from "./pages/Organization/OrganizationSpecialtyDoctors";
import OrganizationCreateLab from "./pages/Organization/OrganizationCreateLab";
import OrganizationManageLab from "./pages/Organization/OrganizationManageLab";
import OrganizationEditLab from "./pages/Organization/OrganizationEditLab";
import OrganizationCreatePharmacy from "./pages/Organization/OrganizationCreatePharmacy";
import OrganizationManagePharmacys from "./pages/Organization/OrganizationManagePharmacy";
import OrganizationEditPharmacy from "./pages/Organization/OrganizationEditPharmacy";
import DoctorSchedule from "./pages/Doctor/DoctorSchedule";
import CenterBookAppointment from "./pages/Clinic/CenterBookAppointment";
import CenterSettings from "./pages/Clinic/CenterSettings";
import LabDashboard from "./pages/Laboratory/LabDashboard";
import TestList from "./pages/Laboratory/LabTestList";
import LabSettings from "./pages/Laboratory/LabSettings";
import PharmDashboard from "./pages/Pharmacy/PharmDashboard";
import PharmSettings from "./pages/Pharmacy/PharmSettings";
import OrganizationCreateOrgUser from "./pages/Organization/OrganizationCreateOrgUser";
import OrganizationManageOrgUser from "./pages/Organization/OrganizationManageOrgUser";
import OrganizationEditOrgUser from "./pages/Organization/OrganizationEditOrgUser";
import OrganizationUserSettings from "./pages/Organization/OrganizationUserSettings";
import OrganizationAudit from "./pages/Organization/OrganizationAudit";
import OrganizationSettings from "./pages/Organization/OrganizationSettings";
import PharmAddMedicine from "./pages/Pharmacy/PharmAddMedicine";
import CenterAddVitals from "./pages/Clinic/CenterAddVitals";
import CenterManageVitals from "./pages/Clinic/CenterManageVitals";
import ViewVitals from "./pages/General/ViewVitals";
import PatientPrescriptions from "./pages/Patient/PatientPrescriptions";
import OrganizationCreateWard from "./pages/Organization/OrganizationCreateWard";
import OrganizationManageWard from "./pages/Organization/OrganizationManageWard";
import OrganizationEditWard from "./pages/Organization/OrganizationEditWard";
import OrganizationBlockList from "./pages/Organization/OrganizationBlockList";
import WardConsultations from "./pages/Ward/WardConsultations";
import WardSettings from "./pages/Ward/WardSettings";

export default function RouterPage() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<SidebarLayout />}>
            {/* DOCTOR ROUTES START*/}
            <Route
              path="/doctor-dashboard"
              exact
              element={<DoctorDashboard />}
            />
            <Route
              path="/doctor-book-appointment"
              exact
              element={<DoctorBookAppointment />}
            />
            <Route
              path="/doctor-my-consultation"
              exact
              element={<DoctorMyConsultation />}
            />
            <Route
              path="/doctor-my-consultation-completed"
              exact
              element={<DoctorMyConsultationComplete />}
            />
            <Route path="/doctor-view-vitals" exact element={<ViewVitals />} />
            <Route path="/doctor-referals" exact element={<DoctorReferal />} />
            <Route
              path="/doctor-forwarded-result"
              exact
              element={<DoctorStoreForward />}
            />
            <Route
              path="/doctor-test-result"
              exact
              element={<DoctorTestResults />}
            />
            <Route
              path="/doctor-prescription"
              exact
              element={<DoctorPrescriptions />}
            />
            <Route path="/doctor-schedule" exact element={<DoctorSchedule />} />
            <Route path="/doctor-settings" exact element={<DoctorSettings />} />

            {/* DOCTOR ROUTES END*/}

            {/* ORGANIZATION ROUTES START*/}

            <Route
              path="/organization-dashboard"
              exact
              element={<OrganizationDashboard />}
            />
            <Route
              path="/organization-create-doctor"
              exact
              element={<OrganizationCreateDoctor />}
            />
            <Route
              path="/organization-update-doctor"
              exact
              element={<OrganizationUpdateDoctor />}
            />
            <Route
              path="/organization-manage-doctors"
              exact
              element={<OrganizationManageDoctors />}
            />
            <Route
              path="/organization-create-patient"
              exact
              element={<OrganizationCreatePatient />}
            />
            <Route
              path="/organization-update-patient"
              exact
              element={<OrganizationUpdatePatient />}
            />
            <Route
              path="/organization-manage-patients"
              exact
              element={<OrganizationManagePatients />}
            />
            <Route
              path="/organization-create-health-center"
              exact
              element={<OrganizationCreateClinic />}
            />
            <Route
              path="/organization-edit-health-center"
              exact
              element={<OrganizationEditClinic />}
            />
            <Route
              path="/organization-manage-health-center"
              exact
              element={<OrganizationManageClinic />}
            />
            <Route
              path="/organization-create-ward"
              exact
              element={<OrganizationCreateWard />}
            />
            <Route
              path="/organization-edit-ward"
              exact
              element={<OrganizationEditWard />}
            />
            <Route
              path="/organization-manage-ward"
              exact
              element={<OrganizationManageWard />}
            />
            <Route
              path="/organization-create-block"
              exact
              element={<OrganizationBlockList />}
            />
            <Route
              path="/organization-create-organization-user"
              exact
              element={<OrganizationCreateOrgUser />}
            />
            <Route
              path="/organization-manage-organization-user"
              exact
              element={<OrganizationManageOrgUser />}
            />
            <Route
              path="/organization-update-organization-user"
              exact
              element={<OrganizationEditOrgUser />}
            />
            <Route
              path="/organization-create-lab"
              exact
              element={<OrganizationCreateLab />}
            />
            <Route
              path="/organization-manage-lab"
              exact
              element={<OrganizationManageLab />}
            />
            <Route
              path="/organization-update-lab"
              exact
              element={<OrganizationEditLab />}
            />
            <Route
              path="/organization-create-pharmacy"
              exact
              element={<OrganizationCreatePharmacy />}
            />
            <Route
              path="/organization-manage-pharmacy"
              exact
              element={<OrganizationManagePharmacys />}
            />
            <Route
              path="/organization-update-pharmacy"
              exact
              element={<OrganizationEditPharmacy />}
            />
            <Route
              path="/organization-view-vitals"
              exact
              element={<ViewVitals />}
            />
            <Route
              path="/organization-specialty"
              exact
              element={<OrganizationSpecialty />}
            />
            <Route
              path="/organization-specialty-doctors"
              exact
              element={<OrganizationSpecialtyDoctors />}
            />
            <Route
              path="/organization-consultation"
              exact
              element={<OrganizationConsultations />}
            />
            <Route
              path="/organization-forwarded-result"
              exact
              element={<OrganizationStoreForward />}
            />
            <Route
              path="/organization-test-result"
              exact
              element={<OrganizationTestResults />}
            />
            <Route
              path="/organization-prescription"
              exact
              element={<OrganizationPrescriptions />}
            />
            <Route
              path="/organization-audit"
              exact
              element={<OrganizationAudit />}
            />
            <Route
              path="/organization-settings"
              exact
              element={<OrganizationSettings />}
            />

            {/* ORGANIZATION ROUTES END*/}
            {/* ORGANIZATION USER ROUTES START*/}

            <Route
              path="/organization_user-dashboard"
              exact
              element={<OrganizationDashboard />}
            />
            <Route
              path="/organization_user-create-doctor"
              exact
              element={<OrganizationCreateDoctor />}
            />
            <Route
              path="/organization_user-update-doctor"
              exact
              element={<OrganizationUpdateDoctor />}
            />
            <Route
              path="/organization_user-manage-doctors"
              exact
              element={<OrganizationManageDoctors />}
            />
            <Route
              path="/organization_user-create-patient"
              exact
              element={<OrganizationCreatePatient />}
            />
            <Route
              path="/organization_user-update-patient"
              exact
              element={<OrganizationUpdatePatient />}
            />
            <Route
              path="/organization_user-manage-patients"
              exact
              element={<OrganizationManagePatients />}
            />
            <Route
              path="/organization_user-create-health-center"
              exact
              element={<OrganizationCreateClinic />}
            />
            <Route
              path="/organization_user-edit-health-center"
              exact
              element={<OrganizationEditClinic />}
            />
            <Route
              path="/organization_user-manage-health-center"
              exact
              element={<OrganizationManageClinic />}
            />
            <Route
              path="/organization_user-create-lab"
              exact
              element={<OrganizationCreateLab />}
            />
            <Route
              path="/organization_user-manage-lab"
              exact
              element={<OrganizationManageLab />}
            />
            <Route
              path="/organization_user-update-lab"
              exact
              element={<OrganizationEditLab />}
            />
            <Route
              path="/organization_user-create-pharmacy"
              exact
              element={<OrganizationCreatePharmacy />}
            />
            <Route
              path="/organization_user-manage-pharmacy"
              exact
              element={<OrganizationManagePharmacys />}
            />
            <Route
              path="/organization_user-update-pharmacy"
              exact
              element={<OrganizationEditPharmacy />}
            />
            <Route
              path="/organization_user-view-vitals"
              exact
              element={<ViewVitals />}
            />
            <Route
              path="/organization_user-specialty"
              exact
              element={<OrganizationSpecialty />}
            />
            <Route
              path="/organization_user-specialty-doctors"
              exact
              element={<OrganizationSpecialtyDoctors />}
            />
            <Route
              path="/organization_user-consultation"
              exact
              element={<OrganizationConsultations />}
            />
            <Route
              path="/organization_user-forwarded-result"
              exact
              element={<OrganizationStoreForward />}
            />
            <Route
              path="/organization_user-test-result"
              exact
              element={<OrganizationTestResults />}
            />
            <Route
              path="/organization_user-prescription"
              exact
              element={<OrganizationPrescriptions />}
            />
            <Route
              path="/organization_user-settings"
              exact
              element={<OrganizationUserSettings />}
            />
            {/* ORGANIZATION USER ROUTES END*/}

            {/* CENTER ROUTES START*/}
            <Route
              path="/health_center-dashboard"
              exact
              element={<CenterDashboard />}
            />
            <Route
              path="/health_center-consultation"
              exact
              element={<CenterConsultations />}
            />
            <Route
              path="/health_center-add-vitals"
              exact
              element={<CenterAddVitals />}
            />
            <Route
              path="/health_center-manage-vitals"
              exact
              element={<CenterManageVitals />}
            />
            <Route
              path="/health_center-book-appointment"
              exact
              element={<CenterBookAppointment />}
            />
            <Route
              path="/health_center-forwarded-result"
              exact
              element={<CenterStoreForward />}
            />
            <Route
              path="/health_center-test-result"
              exact
              element={<CenterPatientTestResults />}
            />
            <Route
              path="/health_center-prescription"
              exact
              element={<CenterPrescriptions />}
            />
            <Route
              path="/health_center-settings"
              exact
              element={<CenterSettings />}
            />
            {/* CENTER ROUTES ENDS*/}

            {/* LABORATORY ROUTES START*/}
            <Route
              path="/laboratory-dashboard"
              exact
              element={<LabDashboard />}
            />

            <Route path="/laboratory-test-list" exact element={<TestList />} />
            <Route
              path="/laboratory-settings"
              exact
              element={<LabSettings />}
            />

            {/* LABORATORY ROUTES ENDS*/}
            {/* PHARMACY ROUTES START*/}

            <Route
              path="/pharmacy-dashboard"
              exact
              element={<PharmDashboard />}
            />

            <Route
              path="/pharmacy-add-medicine"
              exact
              element={<PharmAddMedicine />}
            />
            <Route
              path="/pharmacy-settings"
              exact
              element={<PharmSettings />}
            />

            {/* PHARMACY  ROUTES ENDS*/}
            {/* PATIENT ROUTES START*/}
            <Route
              path="/patient-dashboard"
              exact
              element={<PatientDashboard />}
            />
            <Route
              path="/patient-consultation"
              exact
              element={<PatientConsultations />}
            />
            <Route path="/patient-view-vitals" exact element={<ViewVitals />} />
            <Route
              path="/patient-forwarded-result"
              exact
              element={<PatientStoreForward />}
            />
            <Route
              path="/patient-test-result"
              exact
              element={<PatientTestResults />}
            />
            <Route
              path="/patient-prescription"
              exact
              element={<PatientPrescriptions />}
            />
            {/* WARD ROUTES START*/}
            <Route
              path="/ward-dashboard"
              exact
              element={<WardConsultations />}
            />
            <Route path="/ward-settings" exact element={<WardSettings />} />
          </Route>
          <Route path="/" exact element={<Login />} />
          {/* PATIENT ROUTES END*/}
        </Routes>
      </Router>
    </div>
  );
}
